// authService.js

import { useSelector } from "react-redux";
import { store } from "../redux/rootReducer";
import { formatString } from "./dataformatter/Rawformatter";

export const isAuthenticated = () => {
    try {
        // Assume you have a secure method to check if the user is authenticated
        const authToken = localStorage.getItem("authToken");
        return !!authToken; // Using !! to convert to boolean
    } catch (error) {
        console.error("Error checking authentication:", error);
        return false;
    }
};

export const hasPermissions = (requiredPermissions) => {
    const userRoles = store.getState()?.currentUser?.userRoles;
    try {
        if (!requiredPermissions) {
            return true;
        } else {
            const userPermissions = userRoles?.data?.Permissions || [];
            return requiredPermissions.some((permission) => userPermissions.includes(permission));
        }
    } catch (error) {
        console.error("Error checking permissions:", error);
        return false;
    }
};

export const HasPermission = ({ children, component, id }) => {
    // Get the current permissions from the Redux store
    const permissions = useSelector((state) => state?.permission);

    // Function to check if the user has permission
    const hasPermission = (component, id) => {
        const currentApplicationIds = permissions.currentApplicationIds || {};
        return currentApplicationIds[component].includes(id);
    };

    return hasPermission(component, id) ? children : null;
};

export const PermittedItems = ({ data = [], component = "", dataKey = "", permission  }) => {
    try {
        let permissions 

        if (permission) {
            permissions = permission
        } 
        const currentPagePermission = permissions.pagePermission || {};   
        console.log(permission);
        
        if( window.location.pathname === '/admin/users') return data;

        // Filter data based on the specified dataKey (e.g., tabs, cards, tables)             
        const loginUrl = '/';
        
        if (window.location.pathname === loginUrl) {
            return data;
        }
        if (currentPagePermission) {
            return data.filter((item) =>  currentPagePermission?.[component]?.some((i) => formatString(i || "") === (formatString(item?.[dataKey] || ""))));
        } 
} catch (error) {
        console.error("Error in permittedItems:", error);
        return [];
    }
};

// export const permittedItems = ({ data = [], component = "", dataKey = "", type = "tabs" }) => {
//     try {
//         const permissions = useSelector((state) => state?.permission);
//         console.log(permissions?.currentApplicationIds);
//         console.log(component, dataKey, type);
//         const currentApplicationIds = permissions?.currentApplicationIds || {};

//         // Check if the current component has permission data for the given type (tabs, buttons, cards, etc.)
//         const componentPermissions = currentApplicationIds[component]?.find((perm) => perm?.name === type) || {};
        
//         // Filter data based on the specified dataKey (e.g., tabs, cards, tables)
//         return data.filter((item) => componentPermissions?.[type]?.includes(item?.[dataKey] || ""));
//     } catch (error) {
//         console.error("Error in permittedItems:", error);
//         return data;
//     }
// };
 