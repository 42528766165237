import {
  Box,
  Modal,
  styled,
} from "@mui/material";
import React from "react";
import { BlurredBackdrop, BlurredHeader } from "../ModalPopUp";

const CustomModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: "absolute";
  top: 0;
`;

function FilterModal({
  open = false,
  handleClose = () => {},
  children,
}) {
  return (
    // <CustomModal
    //   open={open}
    //   onClose={handleClose}
    //   disablePortal={true}
    //   BackdropProps={{
    //     style: { backgroundColor: "white", opacity: 0 }, // Change the background color here
    //   }}
    // >
    //   <Box
    //     sx={{
    //       width: '40%',
    //       height: '60%',
    //       padding: "28px",
    //       borderRadius: "32px",
    //       border: "1px solid",
    //       borderColor: "borderColor",
    //       top: "30%",
    //       right: "10%",
    //       position: "absolute",
    //       backgroundColor: "background.popup",
    //       // opacity: 0.9,
    //       "&:focus-visible": {
    //         outline: "none",
    //       },
    //       overflow: "auto",
    //     }}
    //   >
    //     {React.cloneElement(children)}
    //   </Box>
    // </CustomModal>
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={BlurredBackdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    {/* <Fade in={open}> */}
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "90%",
        background:"transparent",
        borderRadius: 4,
        border: "none",
        overflowY: "hidden",
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 4,
          borderRadius: 4,
          p: 4,
          width:'100%',
          height:'100%',
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  </Modal>
  );
}

export default FilterModal;
