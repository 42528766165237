import { createTheme } from "@mui/material/styles";
import fonts from "./fonts";
import { darkColors, lightColors } from "./colors";
import { Box, Stack } from "@mui/material";
import { ReactComponent as DropdownOptionIcon } from "../.././assests/dropDownIcon.svg";


const lightTheme = createTheme({
	palette: {
		mode: "light",
		common: {
			black: lightColors.black.full,
			white: lightColors.white.full,
		},
		primary: {
			main: lightColors.primary.blue,
			light: lightColors.white.full,
			dark: lightColors.black.full,
			contrastText: lightColors.black.full,
		},
		secondary: {
			main: lightColors.secondary.orange,
		},
		background: {
			primary: lightColors.white.full,
			secondary: lightColors.black.light,
			light:lightColors.white.light40,
			shade:lightColors.black.light,
			popup:darkColors.black.full
		},
		borderColor: lightColors.black.light10,
		text: {
			primary: lightColors.black.full,
			secondary: lightColors.black.light40,
			colortext: lightColors.primary.blue,
			white: lightColors.white.full,
			black: lightColors.black.full,
			textviolet:lightColors.secondary.darkviolet
		},
		button: {
			primary: lightColors.primary.blue100,
			disabled: lightColors.black.light60,
		},
		icon: {
			light: lightColors.white.full,
			dark: lightColors.black.full,
		},
	},
	typography: {
		fontFamily: fonts.families.inter,
		h1: {
			fontSize: fonts.sizes[64].regular.fontSize,
			fontWeight: fonts.sizes[64].regular.fontWeight,
			lineHeight: fonts.sizes[64].regular.lineHeight,
			textAlign: fonts.sizes[64].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		h2: {
			fontSize: fonts.sizes[48].semibold.fontSize,
			fontWeight: fonts.sizes[48].semibold.fontWeight,
			lineHeight: fonts.sizes[48].semibold.lineHeight,
			textAlign: fonts.sizes[48].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		h3: {
			fontSize: fonts.sizes[24].semibold.fontSize,
			fontWeight: fonts.sizes[24].semibold.fontWeight,
			lineHeight: fonts.sizes[24].semibold.lineHeight,
			textAlign: fonts.sizes[24].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		h4: {
			fontSize: fonts.sizes[18].semibold.fontSize,
			fontWeight: fonts.sizes[18].semibold.fontWeight,
			lineHeight: fonts.sizes[18].semibold.lineHeight,
			textAlign: fonts.sizes[18].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		h5: {
			fontSize: fonts.sizes[14].semibold.fontSize,
			fontWeight: fonts.sizes[14].semibold.fontWeight,
			lineHeight: fonts.sizes[14].semibold.lineHeight,
			textAlign: fonts.sizes[14].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		h6: {
			fontSize: fonts.sizes[12].semibold.fontSize,
			fontWeight: fonts.sizes[12].semibold.fontWeight,
			lineHeight: fonts.sizes[12].semibold.lineHeight,
			textAlign: fonts.sizes[12].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		body1: {
			fontSize: fonts.sizes[18].regular.fontSize,
			fontWeight: fonts.sizes[18].regular.fontWeight,
			lineHeight: fonts.sizes[18].regular.lineHeight,
			textAlign: fonts.sizes[18].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		body2: {
			fontSize: fonts.sizes[14].regular.fontSize,
			fontWeight: fonts.sizes[14].regular.fontWeight,
			lineHeight: fonts.sizes[14].regular.lineHeight,
			textAlign: fonts.sizes[14].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		body3: {
			fontSize: fonts.sizes[12].regular.fontSize,
			fontWeight: fonts.sizes[12].regular.fontWeight,
			lineHeight: fonts.sizes[12].regular.lineHeight,
			textAlign: fonts.sizes[12].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		subtitle1: {
			fontSize: fonts.sizes[18].semibold.fontSize,
			fontWeight: fonts.sizes[18].semibold.fontWeight,
			lineHeight: fonts.sizes[18].semibold.lineHeight,
			textAlign: fonts.sizes[18].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
		subtitle2: {
			fontSize: fonts.sizes[14].semibold.fontSize,
			fontWeight: fonts.sizes[14].semibold.fontWeight,
			lineHeight: fonts.sizes[14].semibold.lineHeight,
			textAlign: fonts.sizes[14].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: lightColors.black.full,
		},
	},
	// components: {
	// 	MuiSelect: {
	// 	  defaultProps: {
	// 		renderOption: (props, option, { selected }) => (
	// 		  <li {...props}>
	// 			<Stack direction="row" alignItems="center" justifyContent='space-between' width='100%'>
	// 			  <Box
	// 				component={DropdownOptionIcon}
	// 				sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
	// 			  />
	// 			  <Box
	// 				sx={{
	// 				  flexGrow: 1,
	// 				  "& span": {
	// 					color: "#586069",
	// 				  },
	// 				}}
	// 			  >
	// 				{option.name}
	// 			  </Box>
	// 			  <Box
	// 				component={DropdownOptionIcon}
	// 				sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
	// 			  />
	// 			</Stack>
	// 		  </li>
	// 		),
	// 	  },
	// 	},
	//   },
});

export default lightTheme;
