import {config} from '../../configure/drformulaconfig/Formulas'

export const convertPercentageToNumeric = (percentage)=> {
	return parseFloat(percentage) / 100;
};

export async function GetDurationInWeeks({
	startDate,
	endDate,
}) {
	// Implement logic to calculate duration in weeks
	const startDateConverted = new Date(startDate);
	const endDateConverted = new Date(endDate);

	if (startDateConverted > endDateConverted) {
		throw new Error("Start date must be before end date.");
	}

	let networkDays = 0;

	while (startDateConverted <= endDateConverted) {
		const dayOfWeek = startDateConverted.getDay();

		if (dayOfWeek !== 0 && dayOfWeek !== 6) {
			networkDays++;
		}

		startDateConverted.setDate(startDateConverted.getDate() + 1);
	}

	// const CalculateDuration = config.CalculateDuration;
	const CalculateDuration = config.CalculateDuration;

	if (!CalculateDuration) {
		throw new Error(
			`CalculateDuration formulas not found in the configuration.`
		);
	}

	const calculateDuration = eval(
		CalculateDuration.replace(/NetworkDays/g, String(networkDays))
	);
	return calculateDuration;
}

// HoursOnShore
export const GetHoursOnShore = async (
	startDate,
	endDate
)=> {
	const calculateDuration= await GetDurationInWeeks({
		startDate: startDate,
		endDate: endDate,
	});
	const HoursOnShore= config.HoursOnShore;

	if (!HoursOnShore) {
		throw new Error(`HoursOnShore formulas not found in the configuration.`);
	}

	const hoursOnShore = eval(
		HoursOnShore.replace(/CalculateDuration/g, calculateDuration.toString())
	);
	return hoursOnShore;
};

export const GetHoursOffShore = async (
	startDate,
	endDate
) => {
	try {
		const calculateDuration = await GetDurationInWeeks({
			startDate: startDate,
			endDate: endDate,
		});

		const HoursOffShore= config.HoursOffShore;

		if (!HoursOffShore) {
			throw new Error(`HoursOffShore formulas not found in the configuration.`);
		}

		// Replace CalculateDuration with the actual duration value
		const hoursOffShore= eval(
			HoursOffShore.replace(/CalculateDuration/g, calculateDuration.toString())
		);
		return hoursOffShore;
	} catch (error) {
		console.error("Error in GetHoursOffShore:", error.message);
		throw error; // Re-throw the error for the caller to handle
	}
};

// ActualRevenueOnShore
export const GetActualRevenueOnShore = async (
	ActualRate,
	startDate,
	endDate,
	ProjectUtilization,
	NumberOfResources
)=> {
	const HoursOnShore = await GetHoursOnShore(startDate, endDate);
	const ActualRevenueOnShore = config.ActualRevenueOnShore;
	const convertProjectUtilizationPercentageNumeric = convertPercentageToNumeric;


	if (!ActualRevenueOnShore) {
		throw new Error(
			`ActualRevenueOnShore formulas not found in the configuration.`
		);
	}

	const actualRevenueOnShore = eval(
        ActualRevenueOnShore.replace(/ActualRateOnShore/g, ActualRate.toString())
            .replace(/HoursOnShore/g, HoursOnShore.toString())
            .replace(
                /ProjectUtilizationPercentageNumeric/g,
                convertProjectUtilizationPercentageNumeric(
                    ProjectUtilization
                ).toString()
            )
            .replace(/NumberOfResources/g, NumberOfResources.toString())
    );
    return actualRevenueOnShore;
    };
    
    export const GetActualRevenueOffShore = async (
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization, // You should replace the type with the appropriate one
        NumberOfResources
    ) => {
        const HoursOffShore = await GetHoursOffShore(startDate, endDate);
        const ActualRevenueOffShore = config.ActualRevenueOffShore;
        const convertProjectUtilizationPercentageNumeric = convertPercentageToNumeric;
    
        if (!ActualRevenueOffShore) {
            throw new Error(
                `ActualRevenueOnShore formulas not found in the configuration.`
            );
        }
    
        const actualRevenueOffShore = eval(
            ActualRevenueOffShore.replace(/ActualRateOffShore/g, ActualRate.toString())
                .replace(/HoursOffShore/g, HoursOffShore.toString())
                .replace(
                    /ProjectUtilizationPercentageNumeric/g,
                    convertProjectUtilizationPercentageNumeric(
                        ProjectUtilization
                    ).toString()
                )
                .replace(/NumberOfResources/g, NumberOfResources.toString())
        );
        return actualRevenueOffShore;
    };
    
    // ProposedHoursOnShore
    export const getProposedHoursOnShore = async (
        startDate,
        endDate,
        projectUtilization,
        numberOfResources
    ) => {
        const HoursOnShore = await GetHoursOnShore(startDate, endDate);
        const ProposedHoursOnShore = config.ProposedHoursOnShore;
        const convertProjectUtilizationPercentageNumeric =
            convertPercentageToNumeric(projectUtilization);
    
        if (!ProposedHoursOnShore) {
            throw new Error(
                `ProposedHoursOnShore formulas not found in the configuration.`
            );
        }
    
        const proposedHoursOnShore = eval(
            ProposedHoursOnShore.replace(/HoursOnShore/g, HoursOnShore.toString())
                .replace(
                    /ProjectUtilizationPercentageNumeric/g,
                    convertProjectUtilizationPercentageNumeric.toString()
                )
                .replace(/NumberOfResources/g, numberOfResources.toString())
        );
    
        console.log("proposedHoursOnShore", proposedHoursOnShore);
    
        return proposedHoursOnShore;
    };
    
    // ProposedHoursOffShore
    export const getProposedHoursOffShore = async (
        startDate,
        endDate,
        projectUtilization,
        numberOfResources
    ) => {
        const HoursOffShore = await GetHoursOffShore(startDate, endDate);
        const ProposedHoursOffShore = config.ProposedHoursOffShore;
        const convertProjectUtilizationPercentageNumeric =
            convertPercentageToNumeric(projectUtilization);
    
        if (!ProposedHoursOffShore) {
            throw new Error(
                `ProposedHoursOffShore formulas not found in the configuration.`
            );
        }
    
        const proposedHoursOffShore = eval(
            ProposedHoursOffShore.replace(/HoursOffShore/g, HoursOffShore.toString())
                .replace(
                    /ProjectUtilizationPercentageNumeric/g,
                    convertProjectUtilizationPercentageNumeric.toString()
                )
                .replace(/NumberOfResources/g, numberOfResources.toString())
        );
    
        console.log("proposedHoursOffShore", proposedHoursOffShore);
    
        return proposedHoursOffShore;
    };
    
    // ActualRateOnShore
    export const getActualRateOnShore = async (
        FixedPriceRevenue,
        HoursOnShore
    ) => {
        const actualRateOnShoreExpr = config.ActualRateOnShore;
    
        if (!actualRateOnShoreExpr) {
            throw new Error(
                `ActualRateOnShore formulas not found in the configuration.`
            );
        }
        const actualRateOnShore = eval(
            actualRateOnShoreExpr
                .replace(/FixedPriceRevenueOnShore/g, FixedPriceRevenue.toString())
                .replace(/HoursOnShore/g, HoursOnShore.toString())
        );
    
        return actualRateOnShore;
    };
    
    // ActualRateOffShore
    export const getActualRateOffShore = async (
        FixedPriceRevenue,
        HoursOffShore
    ) => {
        const actualRateOffShoreExpr = config.ActualRateOffShore;
    
        if (!actualRateOffShoreExpr) {
            throw new Error(
                `ActualRateOffShore formulas not found in the configuration.`
            );
        }
        const actualRateOffShore = eval(
            actualRateOffShoreExpr
                .replace(/FixedPriceRevenueOffShore/g, FixedPriceRevenue.toString())
                .replace(/HoursOffShore/g, HoursOffShore.toString())
        );
    
        return actualRateOffShore;
    };
    
// WavicleCostOnShore
export const getWavicleCostOnShore = async (
    startDate,
    endDate,
    HourlyCost,
    CostUtilization,
    NumberOfResources
) => {
    const HoursOnShore = await GetHoursOnShore(startDate, endDate);
    const WavicleCostOnShore = config.WavicleCostOnShore;
    const convertCostUtilizationPercentageNumeric =
        convertPercentageToNumeric;

    if (!WavicleCostOnShore) {
        throw new Error(
            `WavicleCostOnShore formulas not found in the configuration.`
        );
    }

    const wavicleCostOnShore = eval(
        WavicleCostOnShore
            .replace(/HoursOnShore/g, HoursOnShore.toString())
            .replace(/HourlyCost/g, HourlyCost.toString())
            .replace(
                /CostUtilizationPercentageNumeric/g,
                convertCostUtilizationPercentageNumeric(CostUtilization).toString()
            )
            .replace(/NumberOfResources/g, NumberOfResources.toString())
    );

    return wavicleCostOnShore;
};

// WavicleCostOffShore
export const getWavicleCostOffShore = async (
    startDate,
    endDate,
    HourlyCost,
    CostUtilization,
    NumberOfResources
) => {
    const HoursOffShore = await GetHoursOffShore(startDate, endDate);
    const WavicleCostOffShore = config.WavicleCostOffShore;
    const convertCostUtilizationPercentageNumeric =
        convertPercentageToNumeric;

    if (!WavicleCostOffShore) {
        throw new Error(
            `WavicleCostOffShore formulas not found in the configuration.`
        );
    }

    const wavicleCostOffShore = eval(
        WavicleCostOffShore
            .replace(/HoursOffShore/g, HoursOffShore.toString())
            .replace(/HourlyCost/g, HourlyCost.toString())
            .replace(
                /CostUtilizationPercentageNumeric/g,
                convertCostUtilizationPercentageNumeric(CostUtilization).toString()
            )
            .replace(/NumberOfResources/g, NumberOfResources.toString())
    );

    return wavicleCostOffShore;
};

// ClientStandardPriceOnShore
export const getClientStandardPriceOnShore = async (
    standardRate,
    startDate,
    endDate,
    projectUtilization,
    numberOfResources
) => {
    const HoursOnShore = await GetHoursOnShore(startDate, endDate);
    const ClientStandardPriceOnShore = config.ClientStandardPriceOnShore;
    const convertProjectUtilizationPercentageNumeric =
        convertPercentageToNumeric;

    if (!ClientStandardPriceOnShore) {
        throw new Error(
            `ClientStandardPriceOnShore formulas not found in the configuration.`
        );
    }

    const clientStandardPriceOnShore = eval(
        ClientStandardPriceOnShore
            .replace(/HoursOnShore/g, HoursOnShore.toString())
            .replace(/StandardRate/g, standardRate.toString())
            .replace(
                /ProjectUtilizationPercentageNumeric/g,
                convertProjectUtilizationPercentageNumeric(
                    projectUtilization
                ).toString()
            )
            .replace(/NumberOfResources/g, numberOfResources.toString())
    );

    return clientStandardPriceOnShore;
};

// ClientStandardPriceOffShore
export const getClientStandardPriceOffShore = async (
    standardRate,
    startDate,
    endDate,
    projectUtilization,
    numberOfResources
) => {
    const HoursOffShore = await GetHoursOffShore(startDate, endDate);
    const ClientStandardPriceOffShore =
        config.ClientStandardPriceOffShore;
    const convertProjectUtilizationPercentageNumeric =
        convertPercentageToNumeric;

    if (!ClientStandardPriceOffShore) {
        throw new Error(
            `ClientStandardPriceOffShore formulas not found in the configuration.`
        );
    }

    const clientStandardPriceOffShore = eval(
        ClientStandardPriceOffShore
            .replace(/HoursOffShore/g, HoursOffShore.toString())
            .replace(/StandardRate/g, standardRate.toString())
            .replace(
                /ProjectUtilizationPercentageNumeric/g,
                convertProjectUtilizationPercentageNumeric(
                    projectUtilization
                ).toString()
            )
            .replace(/NumberOfResources/g, numberOfResources.toString())
    );
    return clientStandardPriceOffShore;
};

/// WavicleDiscountOnShore
export const getWavicleDiscountOnShore = async (
    ActualRevenueOnShore,
    WavicleDiscountPercentage
) => {
    const WavicleDiscountOnShore = config.WavicleDiscountOnShore;
    const convertWavicleDiscountPercentageNumeric =
        convertPercentageToNumeric;

    if (!WavicleDiscountOnShore) {
        throw new Error(
            `WavicleDiscountOnShore formulas not found in the configuration.`
        );
    }

    const wavicleDiscountOnShore = eval(
        WavicleDiscountOnShore
            .replace(/ActualRevenueOnShore/g, ActualRevenueOnShore.toString())
            .replace(
                /WavicleDiscountPercentageNumeric/g,
                convertWavicleDiscountPercentageNumeric(
                    WavicleDiscountPercentage
                ).toString()
            )
    );
    console.log("Convert wavicleDiscountOnShore", wavicleDiscountOnShore);

    return wavicleDiscountOnShore;
};

// WavicleDiscountOffShore
export const getWavicleDiscountOffShore = async (
    ActualRevenueOffShore,
    WavicleDiscountPercentage
) => {
    console.log(ActualRevenueOffShore,
    WavicleDiscountPercentage)
    const WavicleDiscountOffShore = config.WavicleDiscountOffShore;
    const convertWavicleDiscountPercentageNumeric =
        convertPercentageToNumeric;

        console.log(WavicleDiscountOffShore)

    if (!WavicleDiscountOffShore) {
        throw new Error(
            `WavicleDiscountOffShore formulas not found in the configuration.`
        );
    }
    console.log(WavicleDiscountOffShore
        .replace(/ActualRevenueOffShore/g, ActualRevenueOffShore.toString())
        .replace(
            /WavicleDiscountPercentageNumeric/g,
            convertWavicleDiscountPercentageNumeric(
                WavicleDiscountPercentage
            ).toString()
        ))

    const wavicleDiscountOffShore = eval(
        WavicleDiscountOffShore
            .replace(/ActualRevenueOffShore/g, ActualRevenueOffShore.toString())
            .replace(
                /WavicleDiscountPercentageNumeric/g,
                convertWavicleDiscountPercentageNumeric(
                    WavicleDiscountPercentage
                ).toString()
            )
    );
    return wavicleDiscountOffShore;
};

// PLCostOnShore
export const getPLCostOnShore = async (
    ActualRate,
    startDate,
    endDate,
    ProjectUtilization,
    NumberOfResources,
    PLUtilization
) => {
    const ActualRevenueOnShore = await GetActualRevenueOnShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );

    const PLCostOnShore = config.PLCostOnShore;
    const convertPLUtilizationPercentageNumeric =
        convertPercentageToNumeric;

    if (!PLCostOnShore) {
        throw new Error(`PLCostOnShore formulas not found in the configuration.`);
    }

    const pLCostOnShore = eval(
        PLCostOnShore
            .replace(/ActualRevenueOnShore/g, ActualRevenueOnShore.toString())
            .replace(
                /PLUtilizationPercentageNumeric/g,
                convertPLUtilizationPercentageNumeric(PLUtilization).toString()
            )
    );
    return pLCostOnShore;
};

// PLCostOffShore
export const getPLCostOffShore = async (
    ActualRate,
    startDate,
    endDate,
    ProjectUtilization,
    NumberOfResources,
    PLUtilization
) => {
    const ActualRevenueOffShore = await GetActualRevenueOffShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );
    const PLCostOffShore = config.PLCostOffShore;
    const convertPLUtilizationPercentageNumeric =
        convertPercentageToNumeric(PLUtilization);

    if (!PLCostOffShore) {
        throw new Error(`PLCostOffShore formulas not found in the configuration.`);
    }

    const pLCostOffShore = eval(
        PLCostOffShore
            .replace(/ActualRevenueOffShore/g, ActualRevenueOffShore.toString())
            .replace(
                /PLUtilizationPercentageNumeric/g,
                convertPLUtilizationPercentageNumeric.toString()
            )
    );
    return pLCostOffShore;
};

// CLCostOnShore
export const getCLCostOnShore = async (
    ActualRate,
    startDate,
    endDate,
    ProjectUtilization,
    NumberOfResources,
    CLUtilization
) => {
    const ActualRevenueOnShore = await GetActualRevenueOnShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );
    const CLCostOnShore = config.CLCostOnShore;
    const convertCLUtilizationPercentageNumeric =
        convertPercentageToNumeric(CLUtilization);
    if (!CLCostOnShore) {
        throw new Error(`CLCostOnShore formulas not found in the configuration.`);
    }

    const cLCostOnShore = eval(
        CLCostOnShore
            .replace(/ActualRevenueOnShore/g, ActualRevenueOnShore.toString())
            .replace(
                /CLUtilizationPercentageNumeric/g,
                convertCLUtilizationPercentageNumeric.toString()
            )
    );
    return cLCostOnShore;
};

// CLCostOffShore
export const getCLCostOffShore = async (
    ActualRate,
    startDate,
    endDate,
    ProjectUtilization,
    NumberOfResources,
    CLUtilization
) => {
    const ActualRevenueOffShore = await GetActualRevenueOffShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );
    const CLCostOffShore = config.CLCostOffShore;
    const convertCLUtilizationPercentageNumeric =
        convertPercentageToNumeric(CLUtilization);

    if (!CLCostOffShore) {
        throw new Error(`CLCostOffShore formulas not found in the configuration.`);
    }

    const cLCostOffShore = eval(
        CLCostOffShore
            .replace(/ActualRevenueOffShore/g, ActualRevenueOffShore.toString())
            .replace(
                /CLUtilizationPercentageNumeric/g,
                convertCLUtilizationPercentageNumeric.toString()
            )
    );
    return cLCostOffShore;
};

export const getRevenueAfterDeductionsOnShore = async (
    ActualRate,
    startDate,
    endDate,
    ProjectUtilization,
    NumberOfResources,
    CLUtilization,
    PLUtilization,
    WavicleDiscountPercentage
) => {

    console.log('called')
    const FixedPriceRevenueOnShore = await GetActualRevenueOnShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );
    const ActualRevenueOnShore = await GetActualRevenueOnShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );
    const WavicleDiscountOnShore = await getWavicleDiscountOnShore(
        ActualRevenueOnShore,
        WavicleDiscountPercentage
    );
    const PLCostOnShore = await getPLCostOnShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources,
        PLUtilization
    );
    const CLCostOnShore = await getCLCostOnShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources,
        CLUtilization
    );

    const RevenueAfterDeductionsOnShore = config.RevenueAfterDeductionsOnShore;

    if (!RevenueAfterDeductionsOnShore) {
        throw new Error(
            `RevenueAfterDeductionsOnShore formulas not found in the configuration.`
        );
    }

    console.log(RevenueAfterDeductionsOnShore
        .replace(/FixedPriceRevenueOnshore/g, FixedPriceRevenueOnShore.toString())
        .replace(/WavicleDiscountOnShore/g, WavicleDiscountOnShore.toString())
        .replace(/PLCostOnShore/g, PLCostOnShore.toString())
        .replace(/CLCostOnShore/g, CLCostOnShore.toString()))

    const revenueAfterDeductionsOnShore = eval(
        RevenueAfterDeductionsOnShore
            .replace(/FixedPriceRevenueOnshore/g, FixedPriceRevenueOnShore.toString())
            .replace(/WavicleDiscountOnShore/g, WavicleDiscountOnShore.toString())
            .replace(/PLCostOnShore/g, PLCostOnShore.toString())
            .replace(/CLCostOnShore/g, CLCostOnShore.toString())
    );
    return revenueAfterDeductionsOnShore;
};

//RevenueAfterDeductionsOffShore
export const getRevenueAfterDeductionsOffShore = async (
    ActualRate,
    startDate,
    endDate,
    ProjectUtilization,
    NumberOfResources,
    CLUtilization,
    PLUtilization,
    WavicleDiscountPercentage
) => {
    const FixedPriceRevenueOffShore = await GetActualRevenueOffShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );
    const ActualRevenueOffShore = await GetActualRevenueOffShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources
    );
    const WavicleDiscountOffShore = await getWavicleDiscountOffShore(
        ActualRevenueOffShore,
        WavicleDiscountPercentage
    );
    const PLCostOffShore = await getPLCostOffShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources,
        PLUtilization
    );
    const CLCostOffShore = await getCLCostOffShore(
        ActualRate,
        startDate,
        endDate,
        ProjectUtilization,
        NumberOfResources,
        CLUtilization
    );

    const RevenueAfterDeductionsOffShore = config.RevenueAfterDeductionsOffShore;

    if (!RevenueAfterDeductionsOffShore) {
        throw new Error(
            `RevenueAfterDeductionsOffShore formulas not found in the configuration.`
        );
    }

    const revenueAfterDeductionsOffShore = eval(
        RevenueAfterDeductionsOffShore
            .replace(/FixedPriceRevenueOffshore/g, FixedPriceRevenueOffShore.toString())
            .replace(/WavicleDiscountOffShore/g, WavicleDiscountOffShore.toString())
            .replace(/PLCostOffShore/g, PLCostOffShore.toString())
            .replace(/CLCostOffShore/g, CLCostOffShore.toString())
    );
    return revenueAfterDeductionsOffShore;
};

export const getAirfareAmountCal = async (
    RolesRevAfterDedAndWavicleCost,
    TravelPercentageApplied
) => {
    const AirfareAmountFormula = config.AirfareAmount;

    const convertTravelPercentageAppliedNumeric = convertPercentageToNumeric(
        TravelPercentageApplied
    );

    if (!AirfareAmountFormula) {
        throw new Error(`AirfareAmount formula not found in the configuration.`);
    }

    let TotalRevenueAfterDeductions = 0;
    let TotalWavicleCost = 0;

    RolesRevAfterDedAndWavicleCost.forEach((items) => {
        TotalRevenueAfterDeductions += items.RevenueAfterDeduction;
        TotalWavicleCost += items.WavicleCost;
    });

    const airfareAmount = eval(
        AirfareAmountFormula
            .replace(
                /FinalGrossMarginCost/g,
                (TotalRevenueAfterDeductions - TotalWavicleCost).toString()
            )
            .replace(
                /TravelPercentageApplied/g,
                convertTravelPercentageAppliedNumeric.toString()
            )
    );

    return airfareAmount;
};

//Hotel
export const getHotelsAmount = async (
    AirfareAmount
) => {
    const HotelsAmountFormula = config.HotelsAmount;

    if (!HotelsAmountFormula) {
        throw new Error(`HotelsAmount formula not found in the configuration.`);
    }

    const hotelsAmount = eval(
        HotelsAmountFormula.replace(/AirfareAmount/g, AirfareAmount.toString())
    );

    return hotelsAmount;
};

//Ground Transportation
export const getGroundTransportationAmount = async (
    AirfareAmount
) => {
    const GroundTransportationAmountFormula =
        config.GroundTransportationAmount;

    if (!GroundTransportationAmountFormula) {
        throw new Error(
            `GroundTransportationAmount formula not found in the configuration.`
        );
    }

    const groundTransportationAmount = eval(
        GroundTransportationAmountFormula.replace(
            /AirfareAmount/g,
            AirfareAmount.toString()
        )
    );

    return groundTransportationAmount;
};

//Meals
export const getMealsAmount = async (
    AirfareAmount
) => {
    const MealsAmountFormula = config.MealsAmount;

    if (!MealsAmountFormula) {
        throw new Error(`MealsAmount formulas not found in the configuration.`);
    }

    const mealsAmount = eval(
        MealsAmountFormula.replace(/AirfareAmount/g, AirfareAmount.toString())
    );
    return mealsAmount;
};

//Other
export const getOtherAmount = async (
    AirfareAmount
) => {
    const OtherAmountFormula = config.OtherAmount;

    if (!OtherAmountFormula) {
        throw new Error(`OtherAmount formulas not found in the configuration.`);
    }

    const otherAmount = eval(
        OtherAmountFormula.replace(/AirfareAmount/g, AirfareAmount.toString())
    );
    return otherAmount;
};

export const getTotalTravelExpensesAmount = async (
    AirfareAmount,
    GroundTransportationAmount,
    HotelsAmount,
    MealsAmount,
    OtherAmount
) => {
    const TotalTravelExpensesAmountFormula = config.TotalTravelExpensesAmount;

    if (!TotalTravelExpensesAmountFormula) {
        throw new Error(
            `TotalTravelExpensesAmount formula not found in the configuration.`
        );
    }

    const totalTravelExpensesAmount = eval(
        TotalTravelExpensesAmountFormula
            .replace(/AirfareAmount/g, AirfareAmount.toString())
            .replace(/HotelsAmount/g, HotelsAmount.toString())
            .replace(
                /GroundTransportationAmount/g,
                GroundTransportationAmount.toString()
            )
            .replace(/MealsAmount/g, MealsAmount.toString())
            .replace(/OtherAmount/g, OtherAmount.toString())
    );

    return totalTravelExpensesAmount;
};

// 2-In-A-Box (EM) Utilization % more than 1
export const getEM2InABoxUtilizationPercentage = async (
    RolesJobRoleResourcesCostUtilization
) => {
    const EM2InABoxUtilizationPercentageFormula = config.EM2InABoxUtilizationPerccentage;

    if (!EM2InABoxUtilizationPercentageFormula) {
        throw new Error(
            `EM2InABoxUtilizationPercentageFormula not found in the configuration.`
        );
    }

    let emCostUtilizationPercentageSum = 0;

    RolesJobRoleResourcesCostUtilization.forEach((resource) => {
        if (
            // Role : Engagement Manager
            resource.RoleId === 1 &&
            parseInt(resource.Resources) > 1
        ) {
            emCostUtilizationPercentageSum += resource.CostUtilization;
        }
    });

    return emCostUtilizationPercentageSum;
};

// 2-In-A-Box (DL) Utilization % more than 1
export const getDL2InABoxUtilizationPercentage = async (
    RolesJobRoleResourcesCostUtilization
) => {
    const DL2InABoxUtilizationPercentageFormula = config.DL2InABoxUtilizationPercentage;

    if (!DL2InABoxUtilizationPercentageFormula) {
        throw new Error(
            `DL2InABoxUtilizationPercentageFormula not found in the configuration.`
        );
    }

    let dlCostUtilizationPercentageSum = 0;

    RolesJobRoleResourcesCostUtilization.forEach((resource) => {
        if (
            // Delivery manager
            resource.RoleId === 1 &&
            parseInt(resource.Resources) > 1
        ) {
            dlCostUtilizationPercentageSum += resource.CostUtilization;
        }
    });

    return dlCostUtilizationPercentageSum;
};

// 2-In-A-Box (EM) Cost
export const getEM2InABoxCost = async (
    RolesJobRoleResourcesWavicleCost
) => {
    const EM2InABoxCostFormula = config.EM2InABoxCost;

    if (!EM2InABoxCostFormula) {
        throw new Error(`EM2InABoxCostFormula not found in the configuration.`);
    }

    let emWavicleCostSum = 0;

    RolesJobRoleResourcesWavicleCost.forEach((resource) => {
        // Role : Engagement Manager
        if (Number(resource.RoleId) === 51 && parseInt(resource.Resources) > 1) {
            emWavicleCostSum += resource.WavicleCost;
        }
    });

    return emWavicleCostSum;
};

// 2-In-A-Box (DL) Cost
export const getDL2InABoxCost = async (
    RolesJobRoleResourcesWavicleCost
) => {
    const DL2InABoxCostFormula = config.DL2InABoxCost;

    if (!DL2InABoxCostFormula) {
        throw new Error(`DL2InABoxCostFormula not found in the configuration.`);
    }

    let dlWavicleCostSum = 0;

    RolesJobRoleResourcesWavicleCost.forEach((resource) => {
        // Role: Delivery Manager
        if (Number(resource.RoleId) === 37 && parseInt(resource.Resources) > 1) {
            dlWavicleCostSum += resource.WavicleCost;
        }
    });

    return dlWavicleCostSum;
};

// Staffing Ratios & Margin - Onshore
export const getStaffingRatiosAndMarginOnShorePercentage = async (
    RolesLocationAndResources
) => {
    const StaffingRatiosAndMarginOnshorePercentage =
        config.StaffingRatiosAndMarginOnshorePercentage;

    if (!StaffingRatiosAndMarginOnshorePercentage) {
        throw new Error(
            `StaffingRatiosAndMarginOnshorePercentage formulas not found in the configuration.`
        );
    }

    let NumberOfResourcesOnShore = 0;
    let TotalNumberOfResources = 0;

    RolesLocationAndResources.forEach((items) => {
        if (items.Location === "OnShore") {
            NumberOfResourcesOnShore += Number(items.Resources);
        }
        if (items.Location === "OnShore" || items.Location === "OffShore") {
            TotalNumberOfResources += Number(items.Resources);
        }
    });

    const staffingRatiosAndMarginOnshorePercentage = eval(
        StaffingRatiosAndMarginOnshorePercentage.replace(
            /NumberOfResourcesOnshore/g,
            NumberOfResourcesOnShore.toString()
        ).replace(/TotalNumberOfResources/g, TotalNumberOfResources.toString())
    );

    return staffingRatiosAndMarginOnshorePercentage;
};

// Staffing Ratios & Margin - Offshore
export const getStaffingRatiosAndMarginOffshorePercentage = async (
    RolesLocationAndResources
) => {
    const StaffingRatiosAndMarginOffshorePercentage =
        config.StaffingRatiosAndMarginOffshorePercentage;

    if (!StaffingRatiosAndMarginOffshorePercentage) {
        throw new Error(
            `StaffingRatiosAndMarginOffshorePercentage formulas not found in the configuration.`
        );
    }

    let NumberOfResourcesOffshore = 0;
    let TotalNumberOfResources = 0;

    RolesLocationAndResources.forEach((items) => {
        if (items.Location === "OffShore") {
            NumberOfResourcesOffshore += Number(items.Resources);
        }
        if (items.Location === "OnShore" || items.Location === "OffShore") {
            TotalNumberOfResources += Number(items.Resources);
        }
    });

    const staffingRatiosAndMarginOffshorePercentage = eval(
        StaffingRatiosAndMarginOffshorePercentage.replace(
            /NumberOfResourcesOffshore/g,
            NumberOfResourcesOffshore.toString()
        ).replace(/TotalNumberOfResources/g, TotalNumberOfResources.toString())
    );

    return staffingRatiosAndMarginOffshorePercentage;
};


// Function to calculate Competency Lead Cost
export const getCompetencyLeadCost = async (
    TotalCLCost
) => {
    const CompetencyLeadCost = config.CompetencyLeadCost;

    if (!CompetencyLeadCost) {
        throw new Error(
            `CompetencyLeadCost formulas not found in the configuration.`
        );
    }

    const clCostSum = TotalCLCost.reduce(
        (sum, value) => Number(sum) + Number(value),
        0
    );
    const competencyLeadCost = eval(
        CompetencyLeadCost.replace(/TotalCLCost/g, clCostSum.toString())
    );
    return competencyLeadCost;
};

// Function to calculate Deal Revenue and GM Before Deductions
export const calculateDealRevenueAndGMBeforeDeductionsCost = async (
    RolesFixedPriceRevenue
) => {
    const DealRevenueAndGMBeforeDeductionsCost =
        config.DealRevenueAndGMBeforeDeductionsCost;

    if (!DealRevenueAndGMBeforeDeductionsCost) {
        throw new Error(
            `DealRevenueAndGMBeforeDeductionsCost formulas not found in the configuration.`
        );
    }

    let fixedPriceRevenueSum = 0;

    RolesFixedPriceRevenue.forEach((resource) => {
        fixedPriceRevenueSum += Number(resource.FixedPriceRevenue);
    });

    return fixedPriceRevenueSum;
};

// DealRevenueAndGMBeforeDeductionsPercentage
export const getDealRevenueAndGMBeforeDeductionsPercentage = async (
    DealRevenueAndGMBeforeDeductionsCost,
    TotalWavicleCost
) => {
    // Access the formula from the configuration
    const DealRevenueAndGMBeforeDeductionsPercentageFormula = config.DealRevenueAndGMBeforeDeductionsPercentage;

    // Check if the formula is defined
    if (!DealRevenueAndGMBeforeDeductionsPercentageFormula) {
        throw new Error(
            "DealRevenueAndGMBeforeDeductionsPercentage formula not found in the configuration."
        );
    }

    // Evaluate the formula and return the result
    const dealRevenueAndGMBeforeDeductionsPercentage = eval(
        DealRevenueAndGMBeforeDeductionsPercentageFormula.replace(
            /DealRevenueAndGMBeforeDeductionsCost/g,
            DealRevenueAndGMBeforeDeductionsCost.toString()
        ).replace(/TotalWavicleCost/g, TotalWavicleCost.toString())
    );
    return dealRevenueAndGMBeforeDeductionsPercentage;
};

export const getDealRevenueAndGMAfterDeductionsCost = async (
    RolesRevenueAfterDeductions
) => {
    // Retrieve the formula for DealRevenueAndGMAfterDeductionsCost from the configuration
    const DealRevenueAndGMAfterDeductionsCostFormula =
        config.DealRevenueAndGMAfterDeductionsCost;

    // Check if the formula is available
    if (!DealRevenueAndGMAfterDeductionsCostFormula) {
        throw new Error(
            `DealRevenueAndGMAfterDeductionsCost formulas not found in the configuration.`
        );
    }

    // Initialize a variable to hold the sum of revenue after deductions
    let revenueAfterDeductionsSum = 0;

    // Iterate over each role and sum up the revenue after deductions
    RolesRevenueAfterDeductions.forEach((resource) => {
        revenueAfterDeductionsSum += resource.RevenueAfterDeductions;
    });

    // Evaluate the formula using the summed revenue after deductions
    const dealRevenueAndGMAfterDeductionsCost = eval(
        DealRevenueAndGMAfterDeductionsCostFormula.replace(
            /TotalRevenueAfterDeductions/g,
            revenueAfterDeductionsSum.toString()
        )
    );

    // Return the calculated value
    return dealRevenueAndGMAfterDeductionsCost;
};
// DealRevenueAndGMAfterDeductionsPercentage calculation
export const getDealRevenueAndGMAfterDeductionsPercentage = async (
    DealRevenueAndGMAfterDeductionsCost,
    TotalWavicleCost
) => {
    const DealRevenueAndGMAfterDeductionsPercentage =
        config.DealRevenueAndGMAfterDeductionsPercentage;

    if (!DealRevenueAndGMAfterDeductionsPercentage) {
        throw new Error(
            `DealRevenueAndGMAfterDeductionsPercentage formulas not found in the configuration.`
        );
    }

    const dealRevenueAndGMAfterDeductionsPercentage = eval(
        DealRevenueAndGMAfterDeductionsPercentage.replace(
            /DealRevenueAndGMAfterDeductionsCost/g,
            DealRevenueAndGMAfterDeductionsCost.toString()
        ).replace(/TotalWavicleCost/g, TotalWavicleCost.toString())
    );

    return dealRevenueAndGMAfterDeductionsPercentage;
};

// Import the necessary modules and dependencies

// Define the function signature with proper typings
export const getFinalGrossMarginCost = async (
    TotalRevenueAfterDeductions,
    TotalWavicleCost
) => {
    // Access the FinalGrossMarginCost formula from the configuration
    const FinalGrossMarginCost = config.FinalGrossMarginCost;

    // Check if the formula is defined
    if (!FinalGrossMarginCost) {
        throw new Error(
            `FinalGrossMarginCost formulas not found in the configuration.`
        );
    }

    // Evaluate the formula by replacing placeholders with actual values
    const finalGrossMarginCost = eval(
        FinalGrossMarginCost.replace(
            /DealRevenueAndGMAfterDeductionsCost/g,
            TotalRevenueAfterDeductions.toString()
        ).replace(/TotalWavicleCost/g, TotalWavicleCost.toString())
    );

    // Return the calculated final gross margin cost
    return finalGrossMarginCost;
};

// Define the function signature, including type annotations
export const getFinalGrossMarginPercentage = async (
    DealRevenueAndGMAfterDeductionsPercentage
) => {
    // Retrieve the formula from the configuration
    const FinalGrossMarginPercentage = config.FinalGrossMarginPercentage;

    // Check if the formula is available
    if (!FinalGrossMarginPercentage) {
        throw new Error(
            `FinalGrossMarginPercentage formulas not found in the configuration.`
        );
    }

    const finalGrossMarginPercentage = eval(
        FinalGrossMarginPercentage.replace(
            /DealRevenueAndGMAfterDeductionsPercentage/g,
            DealRevenueAndGMAfterDeductionsPercentage.toString()
        )
    );

    // Return the calculated final gross margin percentage
    return finalGrossMarginPercentage;
};

// Define the function to calculate staffing ratios and margin current margin percentage
export const getStaffingRatiosAndMarginCurrentMarginPercentage = async (
    FinalGrossMarginPercentage
) => {
    const StaffingRatiosAndMarginCurrentMarginPercentage =
        config.StaffingRatiosAndMarginCurrentMarginPercentage;

    if (!StaffingRatiosAndMarginCurrentMarginPercentage) {
        throw new Error(
            `StaffingRatiosAndMarginCurrentMarginPercentage formulas not found in the configuration.`
        );
    }

    const staffingRatiosAndMarginCurrentMarginPercentage = eval(
        StaffingRatiosAndMarginCurrentMarginPercentage.replace(
            /FinalGrossMarginPercentage/g,
            FinalGrossMarginPercentage.toString()
        )
    );

    return staffingRatiosAndMarginCurrentMarginPercentage;
};

// Define the function to calculate staffing ratios and expected margin percentage
export const getStaffingRatiosAndExpectedMarginPercentage = async (
    StaffingRatiosAndMarginOnShore,
    StaffingRatiosAndMarginOffShore
) => {
    if (StaffingRatiosAndMarginOnShore < 0.5) {
        return 0.55;
    } else {
        if (
            StaffingRatiosAndMarginOnShore < 0.9 &&
            StaffingRatiosAndMarginOffShore > 0.49
        ) {
            return 0.5;
        } else {
            return 0.45;
        }
    }
};
// Total Wavicle Cost
export const getTotalWavicleCost = async (
    RolesWavicleCost
) => {
    const TotalWavicleCostFormula = config.TotalWavicleCost;

    if (!TotalWavicleCostFormula) {
        throw new Error(`TotalWavicleCost formula not found in the configuration.`);
    }

    let totalWavicleCost = 0;

    RolesWavicleCost.forEach((items) => {
        totalWavicleCost += items.WavicleCost;
    });

    const calculatedTotalWavicleCost = eval(
        TotalWavicleCostFormula.replace(
            /RoleTotalWavicleCost/g,
            totalWavicleCost.toString()
        )
    );
    return calculatedTotalWavicleCost;
};

// Total client price
export const getTotalClientPrice = async (
    RolesClientPrice
) => {
    const TotalClientPriceFormula = config.TotalClientPrice;

    if (!TotalClientPriceFormula) {
        throw new Error(`TotalClientPrice formula not found in the configuration.`);
    }

    let totalClientPrice = 0;

    RolesClientPrice.forEach((items) => {
        totalClientPrice += items.ClientStandardPrice;
    });

    const calculatedTotalClientPrice = eval(
        TotalClientPriceFormula.replace(
            /RoleTotalClientPrice/g,
            totalClientPrice.toString()
        )
    );
    return calculatedTotalClientPrice;
};

// Total Actual Revenue
export const getTotalActualRevenue = async (
    RoleActualRevenue
) => {
    const TotalActualRevenueFormula = config.TotalActualRevenue;

    if (!TotalActualRevenueFormula) {
        throw new Error(
            `TotalActualRevenue formula not found in the configuration.`
        );
    }

    let totalActualRevenue = 0;

    RoleActualRevenue.forEach((items) => {
        totalActualRevenue += items.ActualRevenue;
    });

    const calculatedTotalActualRevenue = eval(
        TotalActualRevenueFormula.replace(
            /RoleTotalActualRevenue/g,
            totalActualRevenue.toString()
        )
    );
    return calculatedTotalActualRevenue;
};

// Total Fixed Price Revenue
export const getTotalFixedPriceRevenue = async (
    RoleFixedPriceRevenue
) => {
    const TotalFixedPriceRevenueFormula = config.TotalFixedPriceRevenue;

    if (!TotalFixedPriceRevenueFormula) {
        throw new Error(
            `TotalFixedPriceRevenue formula not found in the configuration.`
        );
    }

    let totalFixedPriceRevenue = 0;

    RoleFixedPriceRevenue.forEach((items) => {
        totalFixedPriceRevenue += items.TotalFixedPriceRevenue;
    });

    // Correct the variable name in the formula
    const calculatedTotalFixedPriceRevenue = eval(
        TotalFixedPriceRevenueFormula.replace(
            /RoleTotalFixedPriceRevenue/g,
            totalFixedPriceRevenue.toString()
        )
    );
    return calculatedTotalFixedPriceRevenue;
};

// Total Hours Proposed
export const getTotalHoursProposed = async (
    RoleHoursProposed
) => {
    const TotalHoursProposedFormula = config.TotalHoursProposed;

    if (!TotalHoursProposedFormula) {
        throw new Error(
            `TotalHoursProposed formula not found in the configuration.`
        );
    }

    let totalHoursProposed = 0;

    RoleHoursProposed.forEach((items) => {
        totalHoursProposed += items.ProposedHours;
    });

    const calculatedTotalHoursProposed = eval(
        TotalHoursProposedFormula.replace(
            /RoleTotalHoursProposed/g,
            totalHoursProposed.toString()
        )
    );
    return calculatedTotalHoursProposed;
};

// Monthly Payment
export const getMonthlyPayment = async (
    FixedPriceRevenue,
    startDate,
    endDate
) => {
    const extractMonthAndYear = (date) => {
        const parsedDate = new Date(date.replace(/-/g, "/")); // Replace hyphens with slashes
        const month = parsedDate.getMonth() + 1; // Months are zero-based
        const year = parsedDate.getFullYear();
        return { month, year };
    };

    // Extract month and year from start and end dates
    const { month: EstStartMonth, year: EstStartYear } =
        extractMonthAndYear(startDate);
    const { month: EstEndMonth, year: EstEndYear } = extractMonthAndYear(endDate);

    const MonthlyPaymentFormula = config.MonthlyPayment;
    if (!MonthlyPaymentFormula) {
        throw new Error(`MonthlyPayment formula not found in the configuration.`);
    }

    let totalFixedPriceRevenue = 0;

    FixedPriceRevenue.forEach((items) => {
        totalFixedPriceRevenue += items.FixedPriceRevenue;
    });

    console.log(totalFixedPriceRevenue)

    // Calculate the monthly payment using the provided formula
  const calculatedMonthlyPayment = eval(
        MonthlyPaymentFormula.replace(
            /FixedPriceRevenue/g,
            totalFixedPriceRevenue.toString()
        )
            .replace(/EstStartYear/g, EstStartYear.toString())
            .replace(/EstStartMonth/g, EstStartMonth.toString())
            .replace(/EstEndYear/g, EstEndYear.toString())
            .replace(/EstEndMonth/g, EstEndMonth.toString())
    );

    console.log(MonthlyPaymentFormula.replace(
        /FixedPriceRevenue/g,
        totalFixedPriceRevenue.toString()
    )
        .replace(/EstStartYear/g, EstStartYear.toString())
        .replace(/EstStartMonth/g, EstStartMonth.toString())
        .replace(/EstEndYear/g, EstEndYear.toString())
        .replace(/EstEndMonth/g, EstEndMonth.toString()),calculatedMonthlyPayment)

    return calculatedMonthlyPayment;
};
