import FilledRoles from "../../screens/dealreview/utils/checkrolecardproperyfilled/roleCardProperyFilled";
import IsCheckValid from "../../screens/dealreview/utils/checkvaild/CheckVaild";
import isSaveAPICommonsFilled from "../../screens/dealreview/utils/saveapivalidation/saveAPIFieldvalidation";
import {
	ChangeIsRequiredColumnFilledState,
	caculateTotalWavicleInvestmentCost,
	caculateTotalCLCost,
	caculateTotalPLCost,
	visibleSnackBar,
} from "../slices/DealReview";
import {
	getAirfareAmount,
	// getCLCost,
	getClientStandardPrice,
	// getDealRevenueAndGmAfterDeductionsPercentage,
	getDuration,
	// getEM2AndDL2InBoxUtilizationPercentage,
	// getFinalGrossMarginPercentage,
	getFixedPriceRevenueAndActualRevenue,
	// getHotelsAmount,
	// getHoursOffShoreOROffShore,
	getInABoxCost,
	// getMonthlyPayment,
	// getPLCost,
	getProposedHours,
	getRateAndCostFromServer,
	// getRefAfterDeductionsCost,
	// getRevBeforeDeductionsCost,
	// getRevBeforeDeductionsPercentage,
	getRevenueAfterDeductions,
	// getRevenueAndGMAfterDeductionsCost,
	// getStaffingRatioAndExpectedMarginPercentage,
	// getStaffingRatioAndMarginCurrentMarginPercentage,
	// getStaffingRatiosAndMarginsPercentage,
	// getTotalActualRevenue,
	// getTotalClientPrice,
	// getTotalHoursProposed,
	// getTotalTravelExpensesAmount,
	// getTotalWavicleCost,
	// getWavicleCost,
	// getWavicleDiscountCost,
	// getfinalGrossMarginCost,
} from "../slices/DealReviewCalcAPI'sCalls";

const checkIsVaildProperty = (property1, property2) => {
	return (
		property1 !== property2 &&
		IsCheckValid(property1) &&
		IsCheckValid(property2)
	);
};

export const StateMonitor = (store) => (next) => (action) => {
	try {
		const prevState = store.getState();

		const result = next(action);

		const nextState = store.getState();

		if (action.type === "persist/PERSIST") return;
		if (action.type === "persist/REHYDRATE") return;
		if (action.type === "breadcrumbs/setPath") return;
		if (action.type === "Deals/ChangeIsRequiredColumnFilledState") return;
		console.log(action);

		if (
			prevState.dealsAndRole.RoleCardDetails !==
				nextState.dealsAndRole.RoleCardDetails ||
			prevState.dealsAndRole.commonInputs !==
				nextState.dealsAndRole.commonInputs
		) {
			const {
				dealsAndRole: {
					commonInputs: {
						Duration: prevDuration,
						ESTStartdate: prevESTStartdate,
						ESTEndDate: prevESTEndDate,
						ESTClosedDate: prevESTClosedDate,
						FinalGrossMarginPercentage: prevFinalGrossMarginPercentage,
						DealRevenueAndGMAfterDeductionsPercentage:
							prevDealRevenueAndGMAfterDeductionsPercentage,
						TotalFixedPriceRevenue: prevTotalFixedPriceRevenue,
						OffShore: prevOffShore,
						OnShore: prevOnShore,
						TotalRevenueAfterDeductions: prevTotalRevenueAfterDeductions,
						TotalWavicleCost: prevTotalWavicleCost,
						RevBefDeductions: prevRevBefDeductions,
						RevAftDeductions: prevRevAftDeductions,
						Airfare: prevAirfare,
						Hotels: prevHotels,
						Meals: prevMeals,
						GroundTransport: prevGroundTransport,
						Others: prevOthers,
						WavicleDiscount: prevWavicleDiscount,
						PLUtilization: prevPLUtilization,
						CLUtilization: prevCLUtilization,
						ExpensePercentage: prevExpensePercentage,
						DiscountInvestment: prevDiscountInvestment,
						TotalDiscount: prevTotalDiscount,
					},
					RoleCardDetails: prevRoleCardDetails,
				},
			} = prevState ?? {};
			const {
				dealsAndRole: {
					commonInputs: {
						Duration: nextDuration,
						ESTStartdate: nextESTStartdate,
						ESTEndDate: nextESTEndDate,
						ESTClosedDate: nextESTClosedDate,
						FinalGrossMarginPercentage: nextFinalGrossMarginPercentage,
						DealRevenueAndGMAfterDeductionsPercentage:
							nextDealRevenueAndGMAfterDeductionsPercentage,
						TotalFixedPriceRevenue: nextTotalFixedPriceRevenue,
						OffShore: nextOffShore,
						OnShore: nextOnShore,
						TotalRevenueAfterDeductions: nextTotalRevenueAfterDeductions,
						TotalWavicleCost: nextTotalWavicleCost,
						RevBefDeductions: nextRevBefDeductions,
						RevAftDeductions: nextRevAftDeductions,
						Airfare: nextAirfare,
						Hotels: nextHotels,
						Meals: nextMeals,
						GroundTransport: nextGroundTransport,
						Others: nextOthers,
						WavicleDiscount: nextWavicleDiscount,
						PLUtilization: nextPLUtilization,
						CLUtilization: nextCLUtilization,
						ExpensePercentage: nextExpensePercentage,
						DiscountInvestment: nextDiscountInvestment,
						TotalDiscount: nextTotalDiscount,
					},
					RoleCardDetails: nextRoleCardDetails,
				},
			} = nextState ?? {};
			if (
				prevState.dealsAndRole.commonInputs !==
				nextState.dealsAndRole.commonInputs
			) {
				if (
					prevESTEndDate !== nextESTEndDate ||
					prevESTStartdate !== nextESTStartdate
				) {
					store.dispatch(getDuration());
					// store.dispatch(getMonthlyPayment());
				}

				// if (prevFinalGrossMarginPercentage !== nextFinalGrossMarginPercentage) {
				// 	store.dispatch(
				// 		getStaffingRatioAndMarginCurrentMarginPercentage(
				// 			nextFinalGrossMarginPercentage
				// 		)
				// 	);
				// }

				if (prevTotalDiscount !== nextTotalDiscount) {
					nextState.dealsAndRole.RoleCardDetails.forEach((card, index) => {
						store.dispatch(getRevenueAfterDeductions({ index }));
					});
				}

				// if (
				// 	prevDealRevenueAndGMAfterDeductionsPercentage !==
				// 	nextDealRevenueAndGMAfterDeductionsPercentage
				// ) {
				// 	store.dispatch(
				// 		getFinalGrossMarginPercentage(
				// 			nextDealRevenueAndGMAfterDeductionsPercentage
				// 		)
				// 	);
				// }

				if (prevTotalFixedPriceRevenue !== nextTotalFixedPriceRevenue) {
					// store.dispatch(getMonthlyPayment(nextTotalFixedPriceRevenue));
				}

				// if (prevOffShore !== nextOffShore || prevOnShore !== nextOnShore) {
				// 	store.dispatch(
				// 		getStaffingRatioAndExpectedMarginPercentage(
				// 			nextTotalFixedPriceRevenue
				// 		)
				// 	);
				// }

				// if (
				// 	prevTotalRevenueAfterDeductions !== nextTotalRevenueAfterDeductions ||
				// 	prevTotalWavicleCost !== nextTotalWavicleCost
				// ) {
				// 	store.dispatch(getfinalGrossMarginCost());
				// }

				// if (
				// 	prevRevBefDeductions !== nextRevBefDeductions ||
				// 	prevTotalWavicleCost !== nextTotalWavicleCost
				// ) {
				// 	store.dispatch(getfinalGrossMarginCost());
				// 	// store.dispatch(getRevBeforeDeductionsPercentage());
				// }
				// if (
				// 	prevRevAftDeductions !== nextRevAftDeductions ||
				// 	prevTotalWavicleCost !== nextTotalWavicleCost
				// ) {
				// 	store.dispatch(getDealRevenueAndGmAfterDeductionsPercentage());
				// }

				// if (prevAirfare !== nextAirfare) {
				// 	store.dispatch(getHotelsAmount());
				// }

				// if (
				// 	prevAirfare !== nextAirfare ||
				// 	prevHotels !== nextHotels ||
				// 	prevMeals !== nextMeals ||
				// 	prevGroundTransport !== nextGroundTransport ||
				// 	prevOthers !== nextOthers
				// ) {
				// 	store.dispatch(getTotalTravelExpensesAmount());
				// }

				if (prevExpensePercentage !== nextExpensePercentage) {
					// store.dispatch(getAirfareAmount());
				}

				if (prevWavicleDiscount !== nextWavicleDiscount) {
					// console.log("DiscountChanged");
					// nextState.dealsAndRole.RoleCardDetails.forEach((card, index) => {
					// 	store.dispatch(getWavicleDiscountCost({ index }));
					// 	store.dispatch(getRevenueAfterDeductions({ index }));
					// });
				}

				if (prevPLUtilization !== nextPLUtilization) {
					nextState.dealsAndRole.RoleCardDetails.forEach((card, index) => {
						// store.dispatch(getPLCost({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
					});
				}
				if (prevCLUtilization !== nextCLUtilization) {
					nextState.dealsAndRole.RoleCardDetails.forEach((card, index) => {
						// store.dispatch(getCLCost({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
					});
				}

				if (prevDiscountInvestment !== nextDiscountInvestment) {
					// if (nextDiscountInvestment === "%") {
					// 	console.log(nextDiscountInvestment);
					// 	nextState.dealsAndRole.RoleCardDetails.forEach((card, index) => {
					// 		store.dispatch(getWavicleDiscountCost({ index }));
					// 		store.dispatch(getRevenueAfterDeductions({ index }));
					// 	});
					// }
				}
			}

			if (
				prevState.dealsAndRole.RoleCardDetails.length !==
				nextState.dealsAndRole.RoleCardDetails.length
			) {
				if (
					prevState.dealsAndRole.RoleCardDetails.length >
					nextState.dealsAndRole.RoleCardDetails.length
				) {
					console.log(nextRoleCardDetails, prevRoleCardDetails);

					if (action.type === "calc/getRoleForStaffing/fulfilled") return;
					// prevRoleCardDetails.forEach(async (nextItem, index) => {
					// 	console.log(nextItem);
					// 	console.log(nextRoleCardDetails[index]);
					// });
					nextRoleCardDetails.forEach(async (nextItem, index) => {
						const prevItem = prevRoleCardDetails[index];

						if (prevItem !== nextItem) return;

						const {
							StartDate: prevStartDate,
							EndDate: prevEndDate,
							Location: prevLocation,
							CostUtilization: prevCostUtilization,
							HourlyCost: prevHourlyCost,
							Resources: prevResources,
							ProjectUtilization: prevProjectUtilization,
							ActualRate: prevActualRate,
							StandardRate: prevStandardRate,
							FixedPriceRevenue: prevFixedPriceRevenue,
							RoleId: prevRoleId,
							Band: prevBand,
							RevenueAfterDeduction: prevRevenueAfterDeduction,
							WavicleCost: prevWavicleCost,
							WavicleDiscountCost: prevWavicleDiscountCost,
							ClientStandardPrice: prevClientStandardPrice,
							ActualRevenue: prevActualRevenue,
							ProposedHours: prevProposedHours,
							PLCost: prevPLCost,
							CLCost: prevCLCost,
						} = prevItem ?? {};
						const {
							StartDate: nextStartDate,
							EndDate: nextEndDate,
							Location: nextLocation,
							CostUtilization: nextCostUtilization,
							HourlyCost: nextHourlyCost,
							Resources: nextResources,
							ProjectUtilization: nextProjectUtilization,
							ActualRate: nextActualRate,
							StandardRate: nextStandardRate,
							FixedPriceRevenue: nextFixedPriceRevenue,
							RoleId: nextRoleId,
							Band: nextBand,
							RevenueAfterDeduction: nextRevenueAfterDeduction,
							WavicleCost: nextWavicleCost,
							WavicleDiscountCost: nextWavicleDiscountCost,
							ClientStandardPrice: nextClientStandardPrice,
							ActualRevenue: nextActualRevenue,
							ProposedHours: nextProposedHours,
							PLCost: nextPLCost,
							CLCost: nextCLCost,
						} = nextItem ?? {};

						// store.dispatch(getRefAfterDeductionsCost());
						// store.dispatch(getHoursOffShoreOROffShore({ index }));
						// store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						// store.dispatch(getProposedHours({ index }));
						// store.dispatch(getClientStandardPrice({ index }));
						// store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getPLCost({ index }));
						// store.dispatch(getCLCost({ index }));

						// if (prevEndDate !== nextEndDate) {
						// store.dispatch(getWavicleCost({ index }));
						// }

						// if (prevWavicleDiscountCost !== nextWavicleDiscountCost) {
						// 	console.log("wavicleDiscountChange");
						// 	// if (!isNaN(nextWavicleDiscountCost))
						// store.dispatch(caculateTotalWavicleInvestmentCost());
						// }

						// if (prevLocation !== nextLocation) {
						// store.dispatch(getWavicleDiscountCost({ index }));
						// store.dispatch(getStaffingRatiosAndMarginsPercentage({ index }));
						// }

						// if (prevCostUtilization !== nextCostUtilization) {
						// store.dispatch(getEM2AndDL2InBoxUtilizationPercentage({ index }));
						// }

						// if (prevResources !== nextResources) {
						store.dispatch(getInABoxCost({ index }));
						// }

						// if (prevFixedPriceRevenue !== nextFixedPriceRevenue) {
						// store.dispatch(getRevBeforeDeductionsCost({ index }));
						// store.dispatch(getMonthlyPayment());
						// }

						// if (prevRevenueAfterDeduction !== nextRevenueAfterDeduction) {
						// store.dispatch(getAirfareAmount({ index }));
						// }

						// if (prevWavicleCost !== nextWavicleCost) {
						// store.dispatch(getTotalWavicleCost({ index }));
						// }

						// if (prevClientStandardPrice !== nextClientStandardPrice) {
						// store.dispatch(getTotalClientPrice({ index }));
						// }

						// if (prevActualRevenue !== nextActualRevenue) {
						// store.dispatch(getTotalActualRevenue({ index }));
						// }

						// if (prevProposedHours !== nextProposedHours) {
						// store.dispatch(getTotalHoursProposed({ index }));
						// }

						// if (prevPLCost !== nextPLCost) {
						store.dispatch(caculateTotalPLCost());
						// }
						// if (prevCLCost !== nextCLCost) {
						store.dispatch(caculateTotalCLCost());
						// }
					});
				}
			}

			if (
				prevState.dealsAndRole.RoleCardDetails !==
				nextState.dealsAndRole.RoleCardDetails
			) {
				nextRoleCardDetails.forEach(async (nextItem, index) => {
					const prevItem = prevRoleCardDetails[index];

					if (prevItem === nextItem) return;

					const {
						StartDate: prevStartDate,
						EndDate: prevEndDate,
						Location: prevLocation,
						CostUtilization: prevCostUtilization,
						HourlyCost: prevHourlyCost,
						Resources: prevResources,
						ProjectUtilization: prevProjectUtilization,
						ActualRate: prevActualRate,
						StandardRate: prevStandardRate,
						FixedPriceRevenue: prevFixedPriceRevenue,
						RoleId: prevRoleId,
						Band: prevBand,
						RevenueAfterDeduction: prevRevenueAfterDeduction,
						WavicleCost: prevWavicleCost,
						WavicleDiscountCost: prevWavicleDiscountCost,
						ClientStandardPrice: prevClientStandardPrice,
						ActualRevenue: prevActualRevenue,
						ProposedHours: prevProposedHours,
						PLCost: prevPLCost,
						CLCost: prevCLCost,
					} = prevItem ?? {};
					const {
						StartDate: nextStartDate,
						EndDate: nextEndDate,
						Location: nextLocation,
						CostUtilization: nextCostUtilization,
						HourlyCost: nextHourlyCost,
						Resources: nextResources,
						ProjectUtilization: nextProjectUtilization,
						ActualRate: nextActualRate,
						StandardRate: nextStandardRate,
						FixedPriceRevenue: nextFixedPriceRevenue,
						RoleId: nextRoleId,
						Band: nextBand,
						RevenueAfterDeduction: nextRevenueAfterDeduction,
						WavicleCost: nextWavicleCost,
						WavicleDiscountCost: nextWavicleDiscountCost,
						ClientStandardPrice: nextClientStandardPrice,
						ActualRevenue: nextActualRevenue,
						ProposedHours: nextProposedHours,
						PLCost: nextPLCost,
						CLCost: nextCLCost,
					} = nextItem ?? {};

					//simplified and optimized version
					if (action.type === "calc/getRoleForStaffing/fulfilled") {
						// if (
						// 	nextStartDate &&
						// 	nextEndDate &&
						// 	nextStartDate <= nextEndDate &&
						// 	nextLocation
						// ) {
						// 	await store.dispatch(getHoursOffShoreOROffShore({ index }));
						// }

						if (nextLocation && nextRoleId && nextBand) {
							// await store.dispatch(getRateAndCostFromServer({ index }));
						}
					}
					if (action.type === "calc/getRoleForStaffing/fulfilled") return;
					// if (prevRevenueAfterDeduction !== nextRevenueAfterDeduction) {
					// 	// store.dispatch(getRevenueAndGMAfterDeductionsCost());
					// 	store.dispatch(getRefAfterDeductionsCost());
					// }

					if (prevStartDate !== nextStartDate) {
						// store.dispatch(getHoursOffShoreOROffShore({ index }));
						store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						// store.dispatch(getProposedHours({ index }));
						// store.dispatch(getClientStandardPrice({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getPLCost({ index }));
						// store.dispatch(getCLCost({ index }));
					}

					if (prevEndDate !== nextEndDate) {
						// store.dispatch(getHoursOffShoreOROffShore({ index }));
						// store.dispatch(getWavicleCost({ index }));
						store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						// store.dispatch(getProposedHours({ index }));
						// store.dispatch(getClientStandardPrice({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getPLCost({ index }));
						// store.dispatch(getCLCost({ index }));
					}

					if (prevWavicleDiscountCost !== nextWavicleDiscountCost) {
						console.log(prevWavicleDiscountCost, nextWavicleDiscountCost);

						if (
							!IsCheckValid(prevWavicleDiscountCost) &&
							!IsCheckValid(nextWavicleDiscountCost)
						) {
							store.dispatch(caculateTotalWavicleInvestmentCost());
						}
					}

					if (prevLocation !== nextLocation) {
						store.dispatch(getRateAndCostFromServer({ index }));
						// store.dispatch(getHoursOffShoreOROffShore({ index }));
						// store.dispatch(getWavicleCost({ index }));
						// store.dispatch(getWavicleDiscountCost({ index }));
						store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						// store.dispatch(getProposedHours({ index }));
						// store.dispatch(getClientStandardPrice({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getPLCost({ index }));
						// store.dispatch(getCLCost({ index }));
						// store.dispatch(getStaffingRatiosAndMarginsPercentage({ index }));
					}

					if (prevCostUtilization !== nextCostUtilization) {
						// store.dispatch(getWavicleCost({ index }));
						// store.dispatch(getEM2AndDL2InBoxUtilizationPercentage({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
					}

					if (prevHourlyCost !== nextHourlyCost) {
						// store.dispatch(getWavicleCost({ index }));
					}

					if (prevResources !== nextResources) {
						// store.dispatch(getWavicleCost({ index }));
						store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						// store.dispatch(getProposedHours({ index }));
						// store.dispatch(getClientStandardPrice({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getPLCost({ index }));
						// store.dispatch(getCLCost({ index }));
						store.dispatch(getInABoxCost({ index }));
						// store.dispatch(getStaffingRatiosAndMarginsPercentage({ index }));
						// store.dispatch(getEM2AndDL2InBoxUtilizationPercentage({ index }));
					}

					if (prevProjectUtilization !== nextProjectUtilization) {
						store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						// store.dispatch(getProposedHours({ index }));
						// store.dispatch(getClientStandardPrice({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getPLCost({ index }));
						// store.dispatch(getCLCost({ index }));
					}

					if (prevActualRate !== nextActualRate) {
						store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getPLCost({ index }));
						// store.dispatch(getCLCost({ index }));
					}

					if (prevStandardRate !== nextStandardRate) {
						// store.dispatch(getClientStandardPrice({ index }));
					}

					if (prevFixedPriceRevenue !== nextFixedPriceRevenue) {
						// store.dispatch(getRevBeforeDeductionsCost({ index }));
						store.dispatch(getRevenueAfterDeductions({ index }));
						// store.dispatch(getMonthlyPayment());
					}

					if (prevRoleId !== nextRoleId) {
						store.dispatch(getRateAndCostFromServer({ index }));
						store.dispatch(getFixedPriceRevenueAndActualRevenue({ index }));
						store.dispatch(getInABoxCost({ index }));
						// store.dispatch(getEM2AndDL2InBoxUtilizationPercentage({ index }));
					}

					if (prevBand !== nextBand) {
						store.dispatch(getRateAndCostFromServer({ index }));
					}

					if (prevRevenueAfterDeduction !== nextRevenueAfterDeduction) {
						// store.dispatch(getAirfareAmount({ index }));
					}

					if (prevWavicleCost !== nextWavicleCost) {
						// store.dispatch(getAirfareAmount({ index }));
						store.dispatch(getInABoxCost({ index }));
						// store.dispatch(getTotalWavicleCost({ index }));
					}

					// if (prevClientStandardPrice !== nextClientStandardPrice) {
					// 	store.dispatch(getTotalClientPrice({ index }));
					// }

					if (prevActualRevenue !== nextActualRevenue) {
						// store.dispatch(getTotalActualRevenue({ index }));
						// store.dispatch(getWavicleDiscountCost({ index }));
					}

					// if (prevProposedHours !== nextProposedHours) {
					// 	store.dispatch(getTotalHoursProposed({ index }));
					// }

					if (prevPLCost !== nextPLCost) {
						store.dispatch(caculateTotalPLCost());
					}
					if (prevCLCost !== nextCLCost) {
						store.dispatch(caculateTotalCLCost());
					}
				});
			}

			const isFilled = isSaveAPICommonsFilled(
				nextState.dealsAndRole.RoleCardDetails,
				nextState.dealsAndRole.commonInputs
			);

			const cards = FilledRoles(nextState.dealsAndRole.RoleCardDetails);

			if (!isFilled) {
				if (!cards.length > 0) {
					store.dispatch(
						visibleSnackBar({
							message: "Required Latest One Role Card",
							type: "error",
						})
					);
				}
			}
			store.dispatch(ChangeIsRequiredColumnFilledState(isFilled));
		}

		return result;
	} catch (error) {
		// Handle errors gracefully
		console.error("An error occurred in the StateMonitor middleware:", error);
		// Optionally, you can choose to re-throw the error to ensure it's not silently ignored
		// throw error;
		// Return the result to ensure the middleware continues to pass the action along
		return next(action);
	}
};
