import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../configure/exportConfigurtion";
import FilledRoles from "../../screens/dealreview/utils/checkrolecardproperyfilled/roleCardProperyFilled";
import IsEmptyArray from "../../utils/CheckArrayISEmpty";
import { isSaveAPICommonsFilledFromPayload } from "../../screens/dealreview/utils/saveapivalidation/saveAPIFieldvalidation";
import { DEALREVIEW, apiPatch } from "../../configure/apiConfig";
import { API_ROUTES, apiGet, apiPost } from "../../configure/apiConfig";
import statusConfig from "../../configure/StatusConfig";
import useIntl from "../../common/lang/locale/en-US";
import {
  getMonthlyPayment as getMonthlyPaymentFormula,
  GetActualRevenueOffShore as GetActualRevenueOffShoreFormula,
  GetActualRevenueOnShore as GetActualRevenueOnShoreFormula,
  getWavicleDiscountOnShore as getWavicleDiscountOnShoreFormula,
  getWavicleDiscountOffShore as getWavicleDiscountOffShoreFormula,
  getRevenueAfterDeductionsOnShore as getRevenueAfterDeductionsOnShoreFormula,
  getRevenueAfterDeductionsOffShore as getRevenueAfterDeductionsOffShoreFormula,
  getEM2InABoxCost as getEM2InABoxCostFormula,
  getDL2InABoxCost as getDL2InABoxCostFormula,
} from "../../utils/formulafunctions/FormulaFunctions";
import { isValid } from "../../utils/isNotNullField";
import { convertDateFormat } from "../../component/inputbox/ConvertDateFormat";
import { actionNameConfig } from "../../configure/menuAndSubmenuConfig";

export const getRateAndCostFromServer = createAsyncThunk(
  "calc/getRateAndCostFromServer",
  async ({ index, property, value }, thunkAPI) => {
    const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
    const item = RoleCardDetails[index];
    const RoleId = item?.RoleId;
    const Band = item?.Band;
    const Location = item?.Location;

    if (RoleId && Band && Location) {
      let source = axios.CancelToken.source();

      try {
        const response = await apiGet(API_ROUTES.GET_STFRTCT, {
          role_id: RoleId,
          band: Band,
          res_location: Location,
        });
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          return {
            data: response.data?.data,
            index: index,
          };
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          return thunkAPI.rejectWithValue({
            error: "error fetching getRateAndCostFromServer",
          });
        }
      }
    }
  }
);

// export const getHoursOffShoreOROffShore = createAsyncThunk(
// 	"calc/getHoursOffShoreOROffShore",
// 	async ({ index }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const item = RoleCardDetails[index];
// 		const StartDate = item?.StartDate;
// 		const EndDate = item?.EndDate;
// 		const Location = item?.Location;

// 		if (StartDate && EndDate && StartDate <= EndDate && Location) {
// 			let source = axios.CancelToken.source();
// 			try {
// 				const response = await apiPost(API_ROUTES.GET_HOURS, {
// 					startDate: StartDate,
// 					endDate: EndDate,
// 					location: Location,
// 				});
// 				if (response.data.success && response.data.hours) {
// 					console.log(response.data);
// 					return { data: response.data.hours, index: index };
// 				} else {
// 					return thunkAPI.rejectWithValue({
// 						error: "error fetching getHoursOffShoreOROffShore",
// 					});
// 				}
// 			} catch (error) {
// 				if (axios.isCancel(error)) {
// 					console.log("Request canceled:", error.message);
// 				} else {
// 					return thunkAPI.rejectWithValue({ error: error.message });
// 				}
// 			}
// 		} else {
// 			return thunkAPI.rejectWithValue({
// 				error: "error fetching getClientStandardPrice",
// 			});
// 		}
// 	}
// );

// export const getRevenueAndGMAfterDeductionsCost = createAsyncThunk(
// 	"calc/getRevenueAndGMAfterDeductionsCost",
// 	async (_, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const rolesRevenueAfterDeductions = RoleCardDetails.map((item) => {
// 			return { RevenueAfterDeductions: item.RevenueAfterDeduction };
// 		});

// 		if (rolesRevenueAfterDeductions.length <= 0)
// 			return thunkAPI.rejectWithValue({
// 				error: "error fetching getWavicleCost",
// 			});

// 		let source = axios.CancelToken.source();
// 		try {
// 			const response = await apiPost(
// 				API_ROUTES.GET_DEAL_REVENUE_AND_GM_AFTER_DEDUCTIONS_COST,
// 				{
// 					RolesRevenueAfterDeductions: rolesRevenueAfterDeductions,
// 				},
// 				source
// 			);
// 			console.log(response.data);
// 			if (
// 				response.data.success &&
// 				response.data.DealRevenueAndGMAfterDeductionsCost
// 			) {
// 				console.log(response.data, "dealRevenueAndGMAfterDeductionsCost");
// 				return response.data.DealRevenueAndGMAfterDeductionsCost;
// 			} else {
// 				return thunkAPI.rejectWithValue({
// 					error: "error fetching getWavicleCost",
// 				});
// 			}
// 		} catch (error) {
// 			if (axios.isCancel(error)) {
// 				console.log("Request canceled:", error.message);
// 			} else {
// 				return thunkAPI.rejectWithValue({ error: error.message });
// 			}
// 		}
// 	}
// );

export const getWavicleCost = createAsyncThunk(
  "calc/getWavicleCost",
  async ({ index }, thunkAPI) => {
    const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
    const item = RoleCardDetails[index];
    const StartDate = item?.StartDate;
    const EndDate = item?.EndDate;
    const Location = item?.Location;
    const Resources = item?.Resources;
    const CostUtilization = item?.CostUtilization;
    const HourlyCost = item?.HourlyCost;

    console.log("getWavicleCost");
    if (
      StartDate &&
      EndDate &&
      StartDate <= EndDate &&
      CostUtilization &&
      HourlyCost &&
      Resources &&
      Location
    ) {
      let source = axios.CancelToken.source();
      try {
        const response = await apiPost(API_ROUTES.GET_WAVICLE_COST, {
          startDate: StartDate,
          endDate: EndDate,
          location: Location,
          costUtilization: CostUtilization,
          hourlyCost: HourlyCost,
          numberOfResources: Resources,
        });
        if (response.data.success && response.data.wavicleCost) {
          console.log({ WavicleCost: response.data.wavicleCost, index });
          return { WavicleCost: response.data.wavicleCost, index };
        } else {
          return thunkAPI.rejectWithValue({
            error: "error fetching getWavicleCost",
          });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          return thunkAPI.rejectWithValue({ error: error.message });
        }
      }
    } else {
      return thunkAPI.rejectWithValue({
        error: "error fetching getWavicleCost",
      });
    }
  }
);

export const getWavicleDiscountCost = createAsyncThunk(
  "calc/getWavicleDiscountCost",
  async ({ index, property, value }, thunkAPI) => {
    const { RoleCardDetails, commonInputs } = thunkAPI.getState().dealsAndRole;
    const item = RoleCardDetails[index];

    console.log("getWavicleDiscountCost");

    const ActualRevenue = item?.ActualRevenue;
    const Location = item?.Location;
    const wavicleDiscountPercentage = commonInputs.WavicleDiscount || 0;

    if (
      Location === "OffShore" &&
      wavicleDiscountPercentage !== undefined &&
      wavicleDiscountPercentage !== null
    ) {
      console.log("getWavicleDiscountCost");
      const response = await getWavicleDiscountOffShoreFormula(
        ActualRevenue,
        wavicleDiscountPercentage
      );
      return {
        WavicleDiscountCost: response,
        index,
      };
    } else {
      console.log(
        "getWavicleDiscountCost",
        ActualRevenue,
        wavicleDiscountPercentage
      );

      const response = await getWavicleDiscountOnShoreFormula(
        ActualRevenue,
        wavicleDiscountPercentage
      );
      return {
        WavicleDiscountCost: response,
        index,
      };
    }
  }
);

export const getFixedPriceRevenueAndActualRevenue = createAsyncThunk(
  "calc/getFixedPriceRevenueAndActualRevenue",
  async ({ index }, thunkAPI) => {
    const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
    const item = RoleCardDetails[index];
    const StartDate = item?.StartDate;
    const EndDate = item?.EndDate;
    const Location = item?.Location;
    const ProjectUtilization = item?.ProjectUtilization;
    const ActualRate = item?.ActualRate;
    const Resources = item?.Resources;
    const RoleId = item?.RoleId;

    console.log(
      "getFixedPriceRevenueAndActualRevenue",
      RoleId,
      StartDate,
      EndDate,
      StartDate <= EndDate,
      ProjectUtilization,
      ActualRate,
      Resources,
      Location
    );

    if (
      RoleId &&
      StartDate &&
      EndDate &&
      StartDate <= EndDate &&
      ProjectUtilization &&
      ActualRate &&
      Resources &&
      Location
    ) {
      let source = axios.CancelToken.source();
      if (Location === "OffShore") {
        const response = await GetActualRevenueOffShoreFormula(
          ActualRate,
          StartDate,
          EndDate,
          ProjectUtilization,
          Resources
        );
        console.log(response);
        if (response !== undefined && response !== null && response !== "") {
          return {
            FixedPriceRevenue: response,
            ActualRevenue: response,
            index: index,
          };
        } else {
          return {
            FixedPriceRevenue: 0,
            ActualRevenue: 0,
            index: index,
          };
        }
      } else {
        const response = await GetActualRevenueOnShoreFormula(
          ActualRate,
          StartDate,
          EndDate,
          ProjectUtilization,
          Resources
        );
        console.log(response);
        if (response !== undefined && response !== null && response !== "") {
          return {
            FixedPriceRevenue: response,
            ActualRevenue: response,
            index: index,
          };
        } else {
          return {
            FixedPriceRevenue: 0,
            ActualRevenue: 0,
            index: index,
          };
        }
      }
      //   try {
      //     const response = await apiPost(
      //       API_ROUTES.GET_FIXED_PRICE_ACTUAL_REVENUE,
      //       {
      //         RoleId: RoleId,
      //         startDate: StartDate,
      //         endDate: EndDate,
      //         location: Location,
      //         projectUtilization: ProjectUtilization,
      //         actualRate: ActualRate,
      //         numberOfResources: Resources,
      //       },
      //       source
      //     );
      //     console.log(response);
      //     if (response.status === 200) {
      //       if (
      //         response.data?.FixedPriceRevenue !== undefined &&
      //         response.data?.FixedPriceRevenue !== null &&
      //         response.data?.FixedPriceRevenue !== "" &&
      //         response.data?.ActualRevenue !== undefined &&
      //         response.data?.ActualRevenue !== null &&
      //         response.data?.ActualRevenue !== ""
      //       ) {
      //         return {
      //           FixedPriceRevenue: response.data.FixedPriceRevenue,
      //           ActualRevenue: response.data.ActualRevenue,
      //           index: index,
      //         };
      //       } else {
      //         return thunkAPI.rejectWithValue({
      //           error: "error fetching getFixedPriceRevenueAndActualRevenue",
      //         });
      //       }
      //     } else {
      //       return thunkAPI.rejectWithValue({
      //         error: "error fetching getFixedPriceRevenueAndActualRevenue",
      //       });
      //     }
      //   } catch (error) {
      //     if (axios.isCancel(error)) {
      //       console.log("Request canceled:", error.message);
      //     } else {
      //       return thunkAPI.rejectWithValue({
      //         error: "error fetching getFixedPriceRevenueAndActualRevenue",
      //       });
      //     }
      //   }
      // } else {
      //   return thunkAPI.rejectWithValue({
      //     error: "error fetching getFixedPriceRevenueAndActualRevenue",
      //   });
    }
  }
);

// export const getProposedHours = createAsyncThunk(
// 	"calc/getProposedHours",
// 	async ({ index }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const item = RoleCardDetails[index];
// 		const StartDate = item?.StartDate;
// 		const EndDate = item?.EndDate;
// 		const Location = item?.Location;
// 		const ProjectUtilization = item?.ProjectUtilization;
// 		const Resources = item?.Resources;
// 		console.log(
// 			"getProposedHours called",
// 			item,
// 			StartDate,
// 			EndDate,
// 			Location,
// 			ProjectUtilization,
// 			Resources
// 		);

// 		if (
// 			StartDate &&
// 			EndDate &&
// 			StartDate <= EndDate &&
// 			ProjectUtilization &&
// 			Resources &&
// 			Location
// 		) {
// 			let source = axios.CancelToken.source();

// 			try {
// 				const response = await apiPost(
// 					API_ROUTES.GET_PROPOSED_HOURS,
// 					{
// 						startDate: StartDate,
// 						endDate: EndDate,
// 						location: Location,
// 						projectUtilization: ProjectUtilization,
// 						numberOfResources: Resources,
// 					},
// 					"getProposedHours"
// 				);
// 				console.log(response.data);
// 				if (response.data?.success) {
// 					if (response.data?.ProposedHours) {
// 						console.log(response.data);
// 						return {
// 							ProposedHours: response.data?.ProposedHours,
// 							index: index,
// 						};
// 					} else {
// 						return thunkAPI.rejectWithValue({
// 							error: "error fetching getProposedHours",
// 						});
// 					}
// 				} else {
// 					return thunkAPI.rejectWithValue({
// 						error: "error fetching getProposedHours",
// 					});
// 				}
// 			} catch (error) {
// 				if (axios.isCancel(error)) {
// 					console.log("Request canceled:", error.message);
// 				} else {
// 					return thunkAPI.rejectWithValue({
// 						error: "error fetching getProposedHours",
// 					});
// 				}
// 				console.log(error, "getProposedHourError");
// 			}
// 		} else {
// 			console.log("not satisfied the if condition in proposedHour");
// 			return thunkAPI.rejectWithValue({
// 				error: "error fetching getProposedHours",
// 			});
// 		}
// 	}
// );

// export const getClientStandardPrice = createAsyncThunk(
// 	"calc/getClientStandardPrice",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const item = RoleCardDetails[index];
// 		const StartDate = item?.StartDate;
// 		const EndDate = item?.EndDate;
// 		const Location = item?.Location;
// 		const ProjectUtilization = item?.ProjectUtilization;
// 		const Resources = item?.Resources;
// 		const StandardRate = item?.StandardRate;

// 		if (
// 			StartDate &&
// 			EndDate &&
// 			StartDate <= EndDate &&
// 			ProjectUtilization &&
// 			Resources &&
// 			StandardRate &&
// 			Location
// 		) {
// 			let source = axios.CancelToken.source();
// 			try {
// 				const response = await apiPost(
// 					API_ROUTES.GET_CLIENT_STANDARD_PRICE,
// 					{
// 						standardRate: StandardRate,
// 						startDate: StartDate,
// 						endDate: EndDate,
// 						location: Location,
// 						projectUtilization: ProjectUtilization,
// 						numberOfResources: Resources,
// 					},
// 					"getClientStandardPrice"
// 				);

// 				if (response.data?.success) {
// 					if (response.data?.ClientStandardPrice) {
// 						console.log(response.data);
// 						return {
// 							ClientStandardPrice: response.data?.ClientStandardPrice,
// 							index: index,
// 						};
// 					} else {
// 						return thunkAPI.rejectWithValue({
// 							error: "error fetching getClientStandardPrice",
// 						});
// 					}
// 				} else {
// 					return thunkAPI.rejectWithValue({
// 						error: "error fetching getClientStandardPrice",
// 					});
// 				}
// 			} catch (error) {
// 				if (axios.isCancel(error)) {
// 					console.log("Request canceled:", error.message);
// 				} else {
// 					return thunkAPI.rejectWithValue({
// 						error: "error fetching getClientStandardPrice",
// 					});
// 				}
// 			}
// 		} else {
// 			return thunkAPI.rejectWithValue({
// 				error: "error fetching getClientStandardPrice",
// 			});
// 		}
// 	}
// );

export const getRevenueAfterDeductions = createAsyncThunk(
  "calc/getRevenueAfterDeductions",
  async ({ index, property, value }, thunkAPI) => {
    const { RoleCardDetails, commonInputs } = thunkAPI.getState().dealsAndRole;
    const item = RoleCardDetails[index];
    const StartDate = item?.StartDate;
    const EndDate = item?.EndDate;
    const Location = item?.Location;
    const ProjectUtilization = item?.ProjectUtilization;
    const Resources = item?.Resources;
    const ActualRate = item?.ActualRate;
    const CLUtilization = commonInputs?.CLUtilization;
    const WavicleDiscount = commonInputs?.WavicleDiscount;
    const PLUtilization = commonInputs?.PLUtilization;

    console.log("getRevenueAfterDeductions", Location, index);
    if (Location === "OnShore") {
      const response = await getRevenueAfterDeductionsOnShoreFormula(
        ActualRate,
        StartDate,
        EndDate,
        ProjectUtilization,
        Resources,
        CLUtilization,
        PLUtilization,
        WavicleDiscount
      );
      console.log(response);
      if (response !== undefined && response !== null && response !== NaN) {
        return [
          {
            RevenueAfterDeduction: response,
            index: index,
          },
        ];
      }
    } else {
      const response = await getRevenueAfterDeductionsOffShoreFormula(
        ActualRate,
        StartDate,
        EndDate,
        ProjectUtilization,
        Resources,
        CLUtilization,
        PLUtilization,
        WavicleDiscount
      );
      console.log(response);
      if (response !== undefined && response !== null && response !== NaN) {
        return [
          {
            RevenueAfterDeduction: response,
            index: index,
          },
        ];
      }
    }
    // if (index === undefined) {
    //   const FilledCards = FilledRoles(RoleCardDetails, [
    //     "StartDate",
    //     "EndDate",
    //     "Location",
    //     "ProjectUtilization",
    //     "Resources",
    //     "ActualRate",
    //   ]);
    //   if (CLUtilization && PLUtilization && !IsEmptyArray(FilledCards)) {
    //     let source = axios.CancelToken.source();

    //     const results = await Promise.all(
    //       FilledCards?.map(async (RC, index) => {
    //         const response = await apiPost(
    //           API_ROUTES.GET_REVENUE_AFTER_DEDUCTIONS,
    //           {
    //             actualRate: RC.ActualRate,
    //             startDate: RC.StartDate,
    //             endDate: RC.EndDate,
    //             projectUtilization: RC.ProjectUtilization,
    //             numberOfResources: RC.Resources,
    //             CLUtilization: CLUtilization,
    //             PLUtilization: PLUtilization,
    //             wavicleDiscountPercentage: WavicleDiscount,
    //             location: RC.Location,
    //           },
    //           source
    //         );
    //         console.log(response);
    //         if (response.status === 200) {
    //           const obj = {
    //             RevenueAfterDeduction: response.data?.RevenueAfterDeductions,
    //             index: index,
    //           };
    //           return obj;
    //         }
    //       })
    //     );

    //     return results;
    //   }
    // }

    // if (
    //   ActualRate &&
    //   StartDate &&
    //   EndDate &&
    //   ProjectUtilization &&
    //   Resources &&
    //   CLUtilization &&
    //   PLUtilization &&
    //   Location
    // ) {
    //   let source = axios.CancelToken.source();
    //   try {
    //     const response = await apiPost(
    //       API_ROUTES.GET_REVENUE_AFTER_DEDUCTIONS,
    //       {
    //         actualRate: ActualRate,
    //         startDate: StartDate,
    //         endDate: EndDate,
    //         projectUtilization: ProjectUtilization,
    //         numberOfResources: Resources,
    //         CLUtilization: CLUtilization,
    //         PLUtilization: PLUtilization,
    //         wavicleDiscountPercentage: WavicleDiscount,
    //         location: Location,
    //       },
    //       source
    //     );

    //     if (response.data?.success) {
    //       if (response.data?.RevenueAfterDeductions) {
    //         return [
    //           {
    //             RevenueAfterDeduction: response.data?.RevenueAfterDeductions,
    //             index: index,
    //           },
    //         ];
    //       }
    //     } else {
    //       return thunkAPI.rejectWithValue({
    //         error: "error fetching getRevenueAfterDeductions",
    //       });
    //     }
    //   } catch (error) {
    //     if (axios.isCancel(error)) {
    //       console.log("Request canceled:", error.message);
    //     } else {
    //       return thunkAPI.rejectWithValue({
    //         error: "error fetching getRevenueAfterDeductions",
    //       });
    //     }
    //   }
    // } else {
    //   return thunkAPI.rejectWithValue({
    //     error: "error fetching getRevenueAfterDeductions",
    //   });
    // }
  }
);

export const getPLCost = createAsyncThunk(
  "calc/getPLCost",
  async ({ index, property, value }, thunkAPI) => {
    const { RoleCardDetails, commonInputs } = thunkAPI.getState().dealsAndRole;
    const item = RoleCardDetails[index];
    const StartDate = item?.StartDate;
    const EndDate = item?.EndDate;
    const Location = item?.Location;
    const ProjectUtilization = item?.ProjectUtilization;
    const Resources = item?.Resources;
    const ActualRate = item?.ActualRate;
    const PLUtilization = commonInputs?.PLUtilization;

    if (index === undefined) {
      const FilledCards = FilledRoles(RoleCardDetails, [
        "StartDate",
        "EndDate",
        "Location",
        "ProjectUtilization",
        "Resources",
        "ActualRate",
      ]);

      if (PLUtilization && !IsEmptyArray(FilledCards)) {
        let source = axios.CancelToken.source();

        const results = await Promise.all(
          FilledCards?.map(async (RC, index) => {
            const response = await apiPost(
              API_ROUTES.GET_PL_COST,
              {
                actualRate: RC.ActualRate,
                startDate: RC.StartDate,
                endDate: RC.EndDate,
                projectUtilization: RC.ProjectUtilization,
                numberOfResources: RC.Resources,
                PLUtilization: PLUtilization,
                location: RC.Location,
              },
              "getPLCost"
            );

            if (response.status === 200) {
              const obj = { PLCost: response.data?.PLCost, index: index };
              return obj;
            }
          })
        );

        return results;
      }
    }

    if (
      ActualRate &&
      StartDate &&
      EndDate &&
      ProjectUtilization &&
      Resources &&
      PLUtilization &&
      Location
    ) {
      let source = axios.CancelToken.source();
      try {
        const response = await apiPost(
          API_ROUTES.GET_PL_COST,
          {
            actualRate: ActualRate,
            startDate: StartDate,
            endDate: EndDate,
            projectUtilization: ProjectUtilization,
            numberOfResources: Resources,
            PLUtilization: PLUtilization,
            location: Location,
          },
          "getPLCost2"
        );

        if (response.status === 200) {
          if (response.data?.PLCost) {
            return [{ PLCost: response.data?.PLCost, index: index }];
          } else {
            return thunkAPI.rejectWithValue({
              error: "error fetching getPLCost",
            });
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return thunkAPI.rejectWithValue({
            error: "error fetching getPLCost",
          });
        } else {
          return thunkAPI.rejectWithValue({
            error: "error fetching getPLCost",
          });
        }
      }
    } else {
      return thunkAPI.rejectWithValue({
        error: "error fetching getPLCost",
      });
    }
  }
);

// export const getCLCost = createAsyncThunk(
// 	"calc/getCLCost",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails, commonInputs } = thunkAPI.getState().dealsAndRole;
// 		const item = RoleCardDetails[index];
// 		const StartDate = item?.StartDate;
// 		const EndDate = item?.EndDate;
// 		const Location = item?.Location;
// 		const ProjectUtilization = item?.ProjectUtilization;
// 		const Resources = item?.Resources;
// 		const ActualRate = item?.ActualRate;
// 		const CLUtilization = commonInputs?.CLUtilization;

// 		if (index === undefined) {
// 			const FilledCards = FilledRoles(RoleCardDetails, [
// 				"StartDate",
// 				"EndDate",
// 				"Location",
// 				"ProjectUtilization",
// 				"Resources",
// 				"ActualRate",
// 			]);

// 			console.log(FilledCards);

// 			if (!IsEmptyArray(FilledCards)) {
// 				let source = axios.CancelToken.source();

// 				console.log("isEmptyArray");

// 				const results = await Promise.all(
// 					FilledCards?.map(async (RC, index) => {
// 						const response = await apiPost(
// 							API_ROUTES.GET_CL_COST,
// 							{
// 								actualRate: RC.ActualRate,
// 								startDate: RC.StartDate,
// 								endDate: RC.EndDate,
// 								projectUtilization: RC.ProjectUtilization,
// 								numberOfResources: RC.Resources,
// 								CLUtilization: CLUtilization,
// 								location: RC.Location,
// 							},
// 							"getCLCost"
// 						);

// 						if (response.status === 200) {
// 							console.log(response.data);
// 							const obj = { CLCost: response.data?.CLCost, index: index };
// 							return obj;
// 						}
// 					})
// 				);

// 				return results;
// 			} else {
// 				console.log("required field missing");
// 			}
// 		}

// 		if (
// 			ActualRate &&
// 			StartDate &&
// 			EndDate &&
// 			ProjectUtilization &&
// 			Resources &&
// 			CLUtilization &&
// 			Location
// 		) {
// 			let source = axios.CancelToken.source();
// 			try {
// 				const response = await apiPost(
// 					API_ROUTES.GET_CL_COST,
// 					{
// 						actualRate: ActualRate,
// 						startDate: StartDate,
// 						endDate: EndDate,
// 						projectUtilization: ProjectUtilization,
// 						numberOfResources: Resources,
// 						CLUtilization: CLUtilization,
// 						location: Location,
// 					},
// 					"getCLCost2"
// 				);

// 				if (response.status === 200) {
// 					if (response.data?.CLCost) {
// 						return [{ CLCost: response.data?.CLCost, index: index }];
// 					} else {
// 						return thunkAPI.rejectWithValue({
// 							error: "error fetching getCLCost",
// 						});
// 					}
// 				}
// 			} catch (error) {
// 				if (axios.isCancel(error)) {
// 					console.log("Request canceled:", error.message);
// 				} else {
// 					return thunkAPI.rejectWithValue({
// 						error: "error fetching getCLCost",
// 					});
// 				}
// 			}
// 		} else {
// 			return thunkAPI.rejectWithValue({
// 				error: "error fetching getCLCost",
// 			});
// 		}
// 	}
// );

// export const getAirfareAmount = createAsyncThunk(
// 	"calc/getAirfareAmount",
// 	async (_, thunkAPI) => {
// 		const { RoleCardDetails, commonInputs } = thunkAPI.getState().dealsAndRole;
// 		try {
// 			const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 				return {
// 					RevenueAfterDeduction: items.RevenueAfterDeduction,
// 					WavicleCost: items.WavicleCost,
// 				};
// 			});

// 			const allValuesNotEmpty = NewRoleCardDetails.every((item) => {
// 				return Object.values(item).every(
// 					(value) => value !== undefined && value !== null && value !== ""
// 				);
// 			});

// 			const TravelPercentageApplied = commonInputs?.ExpensePercentage;

// 			if (allValuesNotEmpty && TravelPercentageApplied) {
// 				let source = axios.CancelToken.source();
// 				const response = await apiPost(
// 					API_ROUTES.GET_AIRFARE_AMOUNT,
// 					{
// 						RolesRevAfterDedAndWavicleCost: NewRoleCardDetails,
// 						TravelPercentageApplied,
// 					},
// 					"getAirfareAmount"
// 				);

// 				if (response.status === 200) {
// 					console.log(response.data);
// 					if (response.data?.AirfareAmount) {
// 						return {
// 							AirfareAmount: response.data?.AirfareAmount,
// 						};
// 					} else {
// 						return thunkAPI.rejectWithValue({
// 							error: "error fetching getAirfareAmount",
// 						});
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getHotelsAmount = createAsyncThunk(
// 	"calc/getHotelsAmount",
// 	async (_, thunkAPI) => {
// 		const { commonInputs } = thunkAPI.getState().dealsAndRole;

// 		try {
// 			const Airfare = commonInputs?.Airfare;

// 			if (Airfare) {
// 				let source = axios.CancelToken.source();
// 				const response = await apiPost(
// 					API_ROUTES.GET_HOTEL_AMOUNT,
// 					{
// 						AirfareAmount: Airfare,
// 					},
// 					"getHotelsAmount"
// 				);

// 				if (response.status === 200) {
// 					if (response.data?.HotelsAmount) {
// 						return {
// 							Hotels: response.data?.HotelsAmount,
// 							GroundTransport: response.data?.GroundTransportationAmount,
// 							Other: response.data?.OtherAmount,
// 							Meals: response.data?.MealsAmount,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getTotalTravelExpensesAmount = createAsyncThunk(
// 	"calc/get-total-travel-expenses-amount",
// 	async (_, thunkAPI) => {
// 		const { commonInputs } = thunkAPI.getState().dealsAndRole;

// 		try {
// 			const Airfare = commonInputs?.Airfare;
// 			const Hotels = commonInputs?.Hotels;
// 			const Meals = commonInputs?.Meals;
// 			const GroundTransport = commonInputs?.GroundTransport;
// 			const Others = commonInputs?.Others;

// 			if (Airfare && Hotels && Meals && GroundTransport && Others) {
// 				let source = axios.CancelToken.source();
// 				const response = await apiPost(
// 					API_ROUTES.GET_TOTAL_TRAVEL_EXPENSES_AMOUNT,
// 					{
// 						AirfareAmount: Airfare,
// 						HotelsAmount: Hotels,
// 						MealsAmount: Meals,
// 						GroundTransportationAmount: GroundTransport,
// 						OtherAmount: Others,
// 					},
// 					"getTotalTravelExpensesAmount"
// 				);

// 				if (response.status === 200) {
// 					const TotalTravelExpensesAmount =
// 						response.data?.TotalTravelExpensesAmount;
// 					if (response.data?.TotalTravelExpensesAmount) {
// 						return {
// 							TotalTravelExpensesAmount,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

export const getInABoxCost = createAsyncThunk(
  "calc/getInABoxCost",
  async ({ index, property, value }, thunkAPI) => {
    const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;

    const NewRoleCardDetails = RoleCardDetails.map((items) => {
      return {
        RoleId: items.RoleId,
        WavicleCost: items.WavicleCost,
        Resources: items.Resources,
      };
    });

    const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
      return items.RoleId && items.WavicleCost && items.Resources;
    });

    const allValuesNotEmpty = filterFilledRows.length > 0;

    console.log(filterFilledRows);

    try {
      if (allValuesNotEmpty) {
        const DL2InABoxResponse = await getDL2InABoxCostFormula(
          filterFilledRows
        );
        const EM2InABoxResponse = await getEM2InABoxCostFormula(
          filterFilledRows
        );
        console.log(DL2InABoxResponse, EM2InABoxResponse);
        if (
          DL2InABoxResponse !== null ||
          (DL2InABoxResponse !== undefined && EM2InABoxResponse !== null) ||
          EM2InABoxResponse !== undefined
        ) {
          return {
            EM2InABoxCost: EM2InABoxResponse,
            DL2InABoxCost: DL2InABoxResponse,
          };
        } else {
          throw new Error("Invalid response");
        }
        // let source = axios.CancelToken.source();
        // const response = await apiPost(
        // 	API_ROUTES.GET_IN_A_BOX_COST,
        // 	{
        // 		RolesJobRoleResourcesWavicleCost: filterFilledRows,
        // 	},
        // 	source
        // );
        // console.log(response.data);
        // if (response.status === 200) {
        // 	console.log(response.data);
        // 	const EM2InABoxCost = response.data?.EM2InABoxCost;
        // 	const DL2InABoxCost = response.data?.DL2InABoxCost;
        // 	if (
        // 		EM2InABoxCost !== undefined &&
        // 		EM2InABoxCost !== null &&
        // 		EM2InABoxCost !== "" &&
        // 		DL2InABoxCost !== undefined &&
        // 		DL2InABoxCost !== null &&
        // 		DL2InABoxCost !== ""
        // 	) {
        // 		return {
        // 			EM2InABoxCost,
        // 			DL2InABoxCost,
        // 		};
        // 	} else {
        // 		throw new Error("Invalid response");
        // 	}
        // }
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

// export const getStaffingRatiosAndMarginsPercentage = createAsyncThunk(
// 	"calc/getStaffingRatiosAndMarginsPercentage",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				Location: items.Location,
// 				Resources: items.Resources,
// 			};
// 		});
// 		const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 			return items.Location && items.Resources;
// 		});

// 		const allValuesNotEmpty = filterFilledRows.length > 0;

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();
// 				const response = await apiPost(
// 					API_ROUTES.GET_STAFFING_RATIO_AND_MARGIN_PERCENTAGE,
// 					{
// 						RolesLocationAndResources: filterFilledRows,
// 					},
// 					"getStaffingRatiosAndMarginsPercentage"
// 				);

// 				console.log(response);

// 				if (response.status === 200) {
// 					const StaffingRatiosAndMarginOffShorePercentage =
// 						response.data?.StaffingRatiosAndMarginOffShorePercentage;
// 					const StaffingRatiosAndMarginOnShorePercentage =
// 						response.data?.StaffingRatiosAndMarginOnShorePercentage;
// 					if (
// 						StaffingRatiosAndMarginOffShorePercentage !== undefined &&
// 						StaffingRatiosAndMarginOffShorePercentage !== null &&
// 						StaffingRatiosAndMarginOffShorePercentage !== "" &&
// 						StaffingRatiosAndMarginOnShorePercentage !== undefined &&
// 						StaffingRatiosAndMarginOnShorePercentage !== null &&
// 						StaffingRatiosAndMarginOnShorePercentage !== ""
// 					) {
// 						return {
// 							StaffingRatiosAndMarginOffShorePercentage,
// 							StaffingRatiosAndMarginOnShorePercentage,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getRevBeforeDeductionsCost = createAsyncThunk(
// 	"calc/getRevBeforeDeductionsCost",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				FixedPriceRevenue: items.FixedPriceRevenue,
// 			};
// 		});

// 		const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 			return items.FixedPriceRevenue;
// 		});
// 		const allValuesNotEmpty = filterFilledRows.length > 0;

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();

// 				const response = await apiPost(
// 					API_ROUTES.GET_REV_BEFORE_DEDUCTIONS_COST,
// 					{
// 						RolesFixedPriceRevenue: filterFilledRows,
// 					},
// 					"getRevBeforeDeductionsCost"
// 				);
// 				if (response.status === 200) {
// 					const DealRevenueAndGMBeforeDeductionsCost =
// 						response.data?.DealRevenueAndGMBeforeDeductionsCost;
// 					if (
// 						DealRevenueAndGMBeforeDeductionsCost !== undefined &&
// 						DealRevenueAndGMBeforeDeductionsCost !== null &&
// 						DealRevenueAndGMBeforeDeductionsCost !== ""
// 					) {
// 						return {
// 							DealRevenueAndGMBeforeDeductionsCost,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getRevBeforeDeductionsPercentage = createAsyncThunk(
// 	"calc/getRevBeforeDeductionsPercentage",
// 	async (_, thunkAPI) => {
// 		const { commonInputs } = thunkAPI.getState().dealsAndRole;

// 		try {
// 			let source = axios.CancelToken.source();
// 			if (commonInputs?.RevBefDeductions && commonInputs?.TotalWavicleCost) {
// 				const response = await apiPost(
// 					API_ROUTES.GET_REV_BEFORE_DEDUCTIONS_PERCENTAGE,
// 					{
// 						DealRevenueAndGMBeforeDeductionsCost: commonInputs.RevBefDeductions,
// 						TotalWavicleCost: commonInputs.TotalWavicleCost,
// 					},
// 					"getRevBeforeDeductionsPercentage"
// 				);
// 				console.log(response.data);
// 				if (response.status === 200) {
// 					return response.data.DealRevenueAndGMAfterDeductionsCost;
// 				}
// 			}
// 		} catch (error) {
// 			if (axios.isCancel(error)) {
// 				console.log("Request canceled:", error.message);
// 			} else {
// 				console.error("Error fetching data:", error);
// 				throw new Error("Invalid response");
// 			}
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getRefAfterDeductionsCost = createAsyncThunk(
// 	"calc/getRefAfterDeductionsCost",
// 	async (_, thunkAPI) => {
// 		const { RoleCardDetails, commonInputs } = thunkAPI.getState().dealsAndRole;
// 		console.log("called");
// 		console.log(commonInputs.DiscountInvestment);
// 		console.log(RoleCardDetails);
// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				RevenueAfterDeductions: items.RevenueAfterDeduction,
// 			};
// 		});

// 		const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 			return items.RevenueAfterDeductions;
// 		});

// 		const allValuesNotEmpty = filterFilledRows.length > 0;

// 		console.log(filterFilledRows);

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();
// 				const response = await apiPost(
// 					API_ROUTES.GET_REF_AFTER_DEDUCTIONS_COST,
// 					{
// 						RolesRevenueAfterDeductions: filterFilledRows,
// 					},
// 					"getRefAfterDeductionsCost"
// 				);
// 				console.log(response.data);
// 				if (response.status === 200) {
// 					const DealRevenueAndGMAfterDeductionsCost =
// 						response.data?.DealRevenueAndGMAfterDeductionsCost;
// 					if (
// 						DealRevenueAndGMAfterDeductionsCost !== undefined &&
// 						DealRevenueAndGMAfterDeductionsCost !== null &&
// 						DealRevenueAndGMAfterDeductionsCost !== ""
// 					) {
// 						const res =
// 							commonInputs.DiscountInvestment === "$"
// 								? DealRevenueAndGMAfterDeductionsCost -
// 								  commonInputs.TotalDiscount
// 								: DealRevenueAndGMAfterDeductionsCost;
// 						return {
// 							DealRevenueAndGMAfterDeductionsCost: res,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getEM2AndDL2InBoxUtilizationPercentage = createAsyncThunk(
// 	"calc/getEM2AndDL2InBoxUtilizationPercentage",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				RoleId: items.RoleId,
// 				CostUtilization: items.CostUtilization,
// 				Resources: items.Resources,
// 			};
// 		});

// 		const RolesJobRoleResourcesCostUtilization = NewRoleCardDetails.filter(
// 			(items, index) => {
// 				return items.RoleId && items.CostUtilization && items.Resources;
// 			}
// 		);
// 		const allValuesNotEmpty = RolesJobRoleResourcesCostUtilization.length > 0;

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();
// 				const response = await apiPost(
// 					API_ROUTES.GET_EM2_AND_DL2_IN_BOX_UTILIZATION_PERCENTAGE,
// 					{
// 						RolesJobRoleResourcesCostUtilization,
// 					},
// 					"getEM2AndDL2InBoxUtilizationPercentage"
// 				);

// 				if (response.status === 200) {
// 					const EM2InABoxUtilizationPercentage =
// 						response.data?.EM2InABoxUtilizationPercentage;
// 					const DL2InABoxUtilizationPercentage =
// 						response.data?.DL2InABoxUtilizationPercentage;
// 					if (
// 						EM2InABoxUtilizationPercentage !== undefined &&
// 						EM2InABoxUtilizationPercentage !== null &&
// 						EM2InABoxUtilizationPercentage !== "" &&
// 						DL2InABoxUtilizationPercentage !== undefined &&
// 						DL2InABoxUtilizationPercentage !== null &&
// 						DL2InABoxUtilizationPercentage !== ""
// 					) {
// 						return {
// 							EM2InABoxUtilizationPercentage,
// 							DL2InABoxUtilizationPercentage,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getDealRevenueAndGmAfterDeductionsPercentage = createAsyncThunk(
// 	"calc/getDealRevenueAndGmAfterDeductionsPercentage",
// 	async (_, thunkAPI) => {
// 		const { commonInputs } = thunkAPI.getState().dealsAndRole;
// 		try {
// 			let source = axios.CancelToken.source();
// 			if (commonInputs?.RevAftDeductions && commonInputs?.TotalWavicleCost) {
// 				const response = await apiPost(
// 					API_ROUTES.GET_DEAL_REVENUE_AND_GM_AFTER_DEDUCTIONS_PERCENTAGE,
// 					{
// 						DealRevenueAndGMAfterDeductionsCost: commonInputs.RevAftDeductions,
// 						TotalWavicleCost: commonInputs.TotalWavicleCost,
// 					},
// 					"getDealRevenueAndGmAfterDeductionsPercentage"
// 				);

// 				console.log(response);
// 				if (response) {
// 					return response.data.DealRevenueAndGMAfterDeductionsPercentage;
// 				}
// 			}
// 		} catch (error) {
// 			if (axios.isCancel(error)) {
// 				console.log("Request canceled:", error.message);
// 			} else {
// 				console.error("Error fetching data:", error);
// 				throw new Error("Invalid response");
// 			}
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getTotalWavicleCost = createAsyncThunk(
// 	"calc/getTotalWavicleCost",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;

// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				WavicleCost: items.WavicleCost,
// 			};
// 		});

// 		const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 			return items.WavicleCost;
// 		});

// 		const allValuesNotEmpty = filterFilledRows.length > 0;

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();

// 				const response = await apiPost(
// 					API_ROUTES.GET_TOTAL_WAVICLE_COST,
// 					{
// 						RolesWavicleCost: filterFilledRows,
// 					},
// 					"getTotalWavicleCost"
// 				);

// 				if (response.data?.success) {
// 					const TotalWavicleCost = response.data?.TotalWavicleCost;
// 					if (
// 						TotalWavicleCost !== undefined &&
// 						TotalWavicleCost !== null &&
// 						TotalWavicleCost !== ""
// 					) {
// 						return {
// 							TotalWavicleCost: TotalWavicleCost,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				} else {
// 					throw new Error("Invalid response");
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getTotalClientPrice = createAsyncThunk(
// 	"calc/getTotalClientPrice",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;

// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				ClientStandardPrice: items.ClientStandardPrice,
// 			};
// 		});

// 		const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 			return items.ClientStandardPrice;
// 		});

// 		const allValuesNotEmpty = filterFilledRows.length > 0;

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();

// 				const response = await apiPost(
// 					API_ROUTES.GET_TOTAL_CLIENT_PRICE,
// 					{
// 						RolesClientPrice: filterFilledRows,
// 					},
// 					"getTotalClientPrice"
// 				);

// 				if (response.status === 200) {
// 					const TotalClientPrice = response.data?.TotalClientPrice;
// 					if (
// 						TotalClientPrice !== undefined &&
// 						TotalClientPrice !== null &&
// 						TotalClientPrice !== ""
// 					) {
// 						return {
// 							TotalClientPrice,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getTotalActualRevenue = createAsyncThunk(
// 	"calc/getTotalActualRevenue",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;

// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				ActualRevenue: items.ActualRevenue,
// 			};
// 		});

// 		const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 			return items.ActualRevenue;
// 		});

// 		const allValuesNotEmpty = filterFilledRows.length > 0;

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();

// 				const response = await apiPost(
// 					API_ROUTES.GET_TOTAL_ACTUAL_REVENUE,
// 					{
// 						RoleActualRevenue: filterFilledRows,
// 					},
// 					"getTotalActualRevenue"
// 				);

// 				if (response.status === 200) {
// 					const TotalActualRevenue = response.data?.TotalActualRevenue;
// 					if (
// 						TotalActualRevenue !== undefined &&
// 						TotalActualRevenue !== null &&
// 						TotalActualRevenue !== ""
// 					) {
// 						return {
// 							TotalActualRevenue,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getTotalHoursProposed = createAsyncThunk(
// 	"calc/getTotalHoursProposed",
// 	async ({ index, property, value }, thunkAPI) => {
// 		const { RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 			return {
// 				ProposedHours: items.ProposedHours,
// 			};
// 		});

// 		const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 			return items.ProposedHours;
// 		});

// 		const allValuesNotEmpty = filterFilledRows.length > 0;

// 		try {
// 			if (allValuesNotEmpty) {
// 				let source = axios.CancelToken.source();
// 				const response = await apiPost(
// 					API_ROUTES.GET_TOTAL_HOURS_PROPOSED,
// 					{
// 						RoleHoursProposed: filterFilledRows,
// 					},
// 					"getTotalHoursProposed"
// 				);

// 				if (response.data) {
// 					const TotalHoursProposed = response.data?.TotalHoursProposed;
// 					if (
// 						TotalHoursProposed !== undefined &&
// 						TotalHoursProposed !== null &&
// 						TotalHoursProposed !== ""
// 					) {
// 						return {
// 							TotalHoursProposed,
// 						};
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				} else {
// 					throw new Error("Invalid response");
// 				}
// 			}
// 		} catch (error) {
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

export const getDealsfromdrandhs = createAsyncThunk(
  "calc/getDealsfromdrandhs",
  async (_, thunkAPI) => {
    let source = axios.CancelToken.source();
    try {
      const result = await apiGet(
        API_ROUTES.GET_DEALS_FROM_DR_AND_HS,
        {},
        "getDealsfromdrandhs"
      );
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const getEndDateByDuration = createAsyncThunk(
  "calc/getEndDateByDuration",
  async (_, thunkAPI) => {
    const { commonInputs } = thunkAPI.getState().dealsAndRole;
    const { ESTStartdate, Duration } = commonInputs;
	const startDate = new Date(ESTStartdate);
  
	// Calculate the end date by adding the duration in weeks
	const endDate = new Date(startDate);
	endDate.setDate((startDate.getDate() + Duration * 7) - (Duration === 0 ? 0 : 1)); // Subtract 1 day to get the end of the last day
  
    return endDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }
);

export const getDuration = createAsyncThunk(
  "calc/getDuration",
  async (_, thunkAPI) => {
    const { commonInputs } = thunkAPI.getState().dealsAndRole;
    try {
      let source = axios.CancelToken.source();
      if (commonInputs?.ESTStartdate && commonInputs?.ESTEndDate) {
        const response = await apiPost(
          API_ROUTES.GET_DURATION_WEEKS,
          {
            startDate: commonInputs.ESTStartdate,
            endDate: commonInputs.ESTEndDate,
          },
          "getDuration"
        );
        if (response.status === 200) {
          return response.data.durationInWeeks;
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

// export const getfinalGrossMarginCost = createAsyncThunk(
// 	"calc/getfinalGrossMarginCost",
// 	async (_, thunkAPI) => {
// 		const { commonInputs } = thunkAPI.getState().dealsAndRole;

// 		try {
// 			let source = axios.CancelToken.source();
// 			if (
// 				commonInputs?.TotalRevenueAfterDeductions &&
// 				commonInputs?.TotalWavicleCost
// 			) {
// 				const response = await apiPost(
// 					API_ROUTES.GET_FINAL_GROSS_MARGIN_COST,
// 					{
// 						TotalRevenueAfterDeductions:
// 							commonInputs.TotalRevenueAfterDeductions,
// 						TotalWavicleCost: commonInputs.TotalWavicleCost,
// 					},
// 					"getfinalGrossMarginCost"
// 				);

// 				if (response.status === 200) {
// 					console.log(response.data);
// 					if (response.data.FinalGrossMarginCost) {
// 						console.log(response.data.FinalGrossMarginCost);
// 						return response.data.FinalGrossMarginCost;
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			if (axios.isCancel(error)) {
// 				console.log("Request canceled:", error.message);
// 			} else {
// 				console.error("Error fetching data:", error);
// 				throw new Error("Invalid response");
// 			}
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getFinalGrossMarginPercentage = createAsyncThunk(
// 	"calc/getFinalGrossMarginPercentage",
// 	async (value, thunkAPI) => {
// 		const { commonInputs } = thunkAPI.getState().dealsAndRole;
// 		const DealRevenueAndGMAfterDeductionsPercentage =
// 			commonInputs.DealRevenueAndGMAfterDeductionsPercentage || value;
// 		try {
// 			let source = axios.CancelToken.source();
// 			if (DealRevenueAndGMAfterDeductionsPercentage) {
// 				const response = await apiPost(
// 					API_ROUTES.GET_FINAL_GROSS_MARGIN_PERCENTAGE,
// 					{
// 						DealRevenueAndGMAfterDeductionsPercentage,
// 					},
// 					"getFinalGrossMarginPercentage"
// 				);

// 				if (response.status === 200) {
// 					if (response.data.FinalGrossMarginPercentage) {
// 						return response.data.FinalGrossMarginPercentage;
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			if (axios.isCancel(error)) {
// 				console.log("Request canceled:", error.message);
// 			} else {
// 				console.error("Error fetching data:", error);
// 				throw new Error("Invalid response");
// 			}
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getStaffingRatioAndExpectedMarginPercentage = createAsyncThunk(
// 	"calc/getStaffingRatioAndExpectedMarginPercentage",
// 	async (_, thunkAPI) => {
// 		const { commonInputs } = thunkAPI.getState().dealsAndRole;
// 		const OnShore = commonInputs.OnShore / 100;
// 		const OffShore = commonInputs.OffShore / 100;
// 		console.log(OnShore, OffShore);

// 		try {
// 			let source = axios.CancelToken.source();
// 			if (OnShore || OffShore) {
// 				const response = await apiPost(
// 					API_ROUTES.GET_STAFFING_RATIO_AND_EXPECTED_MARGIN_PERCENTAGE,
// 					{
// 						StaffingRatiosAndMarginOnShore: OnShore,
// 						StaffingRatiosAndMarginOffShore: OffShore,
// 					},
// 					"getStaffingRatioAndExpectedMarginPercentage"
// 				);
// 				console.log(response.data);
// 				if (response) {
// 					console.log(response.data);
// 					if (response.data) {
// 						return (
// 							response.data.StaffingRatiosAndExpectedMarginPercentage * 100
// 						);
// 					} else {
// 						throw new Error("Invalid response");
// 					}
// 				}
// 			}
// 		} catch (error) {
// 			if (axios.isCancel(error)) {
// 				console.log("Request canceled:", error.message);
// 			} else {
// 				console.error("Error fetching data:", error);
// 				throw new Error("Invalid response");
// 			}
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

// export const getStaffingRatioAndMarginCurrentMarginPercentage =
// 	createAsyncThunk(
// 		"calc/getStaffingRatioAndMarginCurrentMarginPercentage",
// 		async (_, thunkAPI) => {
// 			const { commonInputs } = thunkAPI.getState().dealsAndRole;

// 			try {
// 				let source = axios.CancelToken.source();
// 				if (commonInputs?.FinalGrossMarginPercentage) {
// 					const response = await apiPost(
// 						API_ROUTES.GET_STAFFING_RATIO_AND_MARGIN_CURRENT_MARGIN_PERCENTAGE,
// 						{
// 							FinalGrossMarginPercentage:
// 								commonInputs.FinalGrossMarginPercentage,
// 						},
// 						"getStaffingRatioAndMarginCurrentMarginPercentage"
// 					);

// 					if (response.status === 200) {
// 						if (response.data.StaffingRatiosAndMarginCurrentMarginPercentage) {
// 							return response.data
// 								.StaffingRatiosAndMarginCurrentMarginPercentage;
// 						} else {
// 							throw new Error("Invalid response");
// 						}
// 					}
// 				}
// 			} catch (error) {
// 				if (axios.isCancel(error)) {
// 					console.log("Request canceled:", error.message);
// 				} else {
// 					console.error("Error fetching data:", error);
// 					throw new Error("Invalid response");
// 				}

// 				return thunkAPI.rejectWithValue({
// 					error: error.message,
// 				});
// 			}
// 		}
// 	);

// export const getMonthlyPayment = createAsyncThunk(
// 	"calc/getMonthlyPayment",
// 	async (_, thunkAPI) => {
// 		const { commonInputs, RoleCardDetails } = thunkAPI.getState().dealsAndRole;
// 		const startDate = commonInputs.ESTStartdate;
// 		const endDate = commonInputs.ESTEndDate;

// 		try {
// 			const NewRoleCardDetails = RoleCardDetails.map((items) => {
// 				return {
// 					FixedPriceRevenue: items.FixedPriceRevenue,
// 				};
// 			});

// 			const filterFilledRows = NewRoleCardDetails.filter((items, index) => {
// 				return items.FixedPriceRevenue;
// 			});
// 			console.log({
// 				FixedPriceRevenue: filterFilledRows,
// 				startDate,
// 				endDate,
// 			});
// 			const allValuesNotEmpty = filterFilledRows.length > 0;
// 			let source = axios.CancelToken.source();
// 			if (allValuesNotEmpty && startDate && endDate) {
// 				const response = await apiPost(
// 					API_ROUTES.GET_MONTHLY_PAYMENT,
// 					{
// 						FixedPriceRevenue: filterFilledRows,
// 						startDate,
// 						endDate,
// 					},
// 					"getMonthlyPayment"
// 				);
// 				// const response =await getMonthlyPaymentFormula(filterFilledRows,startDate,endDate,)
// 				console.log(response);

// 				if (response.status === 200) {
// 					if (response.data.MonthlyPayment) {
// 						return response.data.MonthlyPayment;
// 					} else {
// 						let totalFixedPriceRevenue = 0;

// 						filterFilledRows.forEach((items) => {
// 							totalFixedPriceRevenue += items.FixedPriceRevenue;
// 						});
// 						console.log(totalFixedPriceRevenue);
// 						return totalFixedPriceRevenue;
// 					}
// 					// if (response.data.MonthlyPayment) {
// 					// 	return response.data.MonthlyPayment;
// 					// } else {
// 					// 	throw new Error("Invalid response");
// 					// }
// 				}
// 			}
// 		} catch (error) {
// 			if (axios.isCancel(error)) {
// 				console.log("Request canceled:", error.message);
// 			} else {
// 				console.error("Error fetching data:", error);
// 				throw new Error("Invalid response");
// 			}
// 			return thunkAPI.rejectWithValue({
// 				error: error.message,
// 			});
// 		}
// 	}
// );

export const getDRDataForOverviewAndStaffing = createAsyncThunk(
  "calc/getDRDataForOverviewAndStaffing",
  async (projectId, thunkAPI) => {
    try {
      console.log("getDRDataForOverviewAndStaffing called");
      let source = axios.CancelToken.source();
      const response = await axios.get(
        `${BASE_URL}/${DEALREVIEW}/drgetsummarydetails?project_id=${projectId}`,
        {
          cancelToken: source.token,
        }
      );

      if (response.status === 200 && !IsEmptyArray(response.data)) {
        console.log(response.data);
        return response.data;
      }
      if (IsEmptyArray(response.data)) {
        console.log(response.data);
        throw new Error("Array Empty Response");
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const getRoleForStaffing = createAsyncThunk(
  "calc/getRoleForStaffing",
  async (projectId, thunkAPI) => {
    try {
      const roleResponse = await apiGet(
        `/${DEALREVIEW}/drstaffinggetrolecard?project_id=${projectId}`,
        {},
        "calc/getRoleForStaffing"
      );

      if (roleResponse.status === 200) {
        if (!IsEmptyArray(roleResponse.data?.data)) {
          return roleResponse.data?.data;
        } else {
          throw new Error("Role Cards Not Found");
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

const checkDealReviewStaffing = (staffingData) => {
  const record = staffingData.reduce((temp, card) => {
    temp[`${card.staff_sheet_id}_ResourceType`] = isValid(card.resource_type)
      ? ""
      : `Resource Type for ${card.sow_role_name} is Required`;
	temp[`${card.staff_sheet_id}_RoleRequirement`] = isValid(card.role_requirements)
      ? ""
      : `Role Requirement for ${card.sow_role_name} is Required`;
    return temp;
  }, {});
  return record;
};

const ValidateData = (values) => {
  const {
    dealReviewData: { dealReview, dealStaffingSheetArray },
  } = values;
  return {
    ActualStartDate: isValid(dealReview.actual_start_date)
      ? ""
      : "Proposed Start Date is Required",
    ActualEndDate: isValid(dealReview.actual_end_date)
      ? ""
      : "Proposed End Date is Required",
    ServiceCategories:
      dealReview.services_included?.length > 0
        ? ""
        : "Service Category is Required",
    LifeCycle:
      dealReview.life_cycle?.length > 0 ? "" : "Life Cycle is Required",
    Technology:
      dealReview.technology?.length > 0 ? "" : "Technology is Required",
    PricingType: isValid(dealReview.pricing_type) && dealReview.pricing_type != 0
      ? ""
      : "Pricing Type is Required",
	TotalDiscount: isValid(dealReview.total_discount) ? "" : "Total Discount is Required",
	PLUtilization: isValid(dealReview.prac_lead_utilization) ? "" : "Practice Lead Utilization is Required",
	CLUtilization: isValid(dealReview.comp_lead_utilization) ? "" : "Comp Lead Utilization is Required",
	TravelExpense: isValid(dealReview.total_deal_expenses) ? "" : "Travel Expense is Required",
	dealStaffingSheetArray: dealStaffingSheetArray.length ? "" : "Required to Add Staffing",
	// ...checkDealReviewStaffing(dealStaffingSheetArray)
  };
};
// console.log("test");
const getErrors = (errors) => {
	return Object.values(errors).filter((itm) => {
		return isValid(itm)
	})
}

export const updateStaffingAndOverview = createAsyncThunk(
  "calc/updateStaffingAndOverview",
  async (data, thunkAPI) => {
    try {
      const { projectid, userName, header} = data;
      const { RoleCardDetails, commonInputs, isSavedDataIntoDB } =
        thunkAPI.getState().dealsAndRole;
			const transformDataForAPI = (data) => {
				const dealReviewData = {
          dealReview: {
            company_name: commonInputs.CompanyName,
            project_name: commonInputs.ProjectName,
            est_close_date: commonInputs.ESTClosedDate,
            deal_review_id: commonInputs.DealreviewID,
            project_id: projectid,
            est_start_date: convertDateFormat(
              commonInputs.ESTStartdate,
              useIntl["new-date.format"]
            ),
            est_end_date: convertDateFormat(
              commonInputs.ESTEndDate,
              useIntl["new-date.format"]
            ),
            peak_headcount: Math.round(Number(commonInputs.PeekHeadcount)),
            duration_in_weeks: Math.round(Number(commonInputs.Duration)),
            life_cycles: { life_cycles: commonInputs.LifeCycle },
            pricing_type: commonInputs.PricingType,
            services_included: {
              services_includes: commonInputs.ServiceCategories,
            },
            technology: { technologies: commonInputs.Technology },
            fee_expenses: commonInputs.FeeExpenses,
            risk_and_assumptions: commonInputs.RiskAndAssumptions,
            notes: commonInputs.Notes,
            total_hours_proposed: Math.round(
              Number(commonInputs.TotalHoursProposed)
            ),
            total_client_price: Math.round(
              Number(commonInputs.TotalClientPrice)
            ),
            total_wavicle_cost: Math.round(
              Number(commonInputs.TotalWavicleCost)
            ),
            total_revenue: Math.round(Number(commonInputs.TotalActRevenue)),
            total_revenue_after_deductions: Math.round(
              Number(commonInputs.TotalRevenueAfterDeductions)
            ),
            onsite_staff_percent: Math.round(Number(commonInputs.OnShore)),
            offshore_staff_percent: Math.round(Number(commonInputs.OffShore)),
            prac_lead_utilization: Math.round(
              Number(commonInputs.PLUtilization)
            ),
            comp_lead_utilization: Math.round(
              Number(commonInputs.CLUtilization)
            ),
            wavicle_discount: Math.round(Number(commonInputs.WavicleDiscount)),
            total_travel_expenses: Math.round(
              Number(commonInputs.TotalTravelExpenses)
            ),
            current_margin: Math.round(Number(commonInputs.CurrentMargin)),
            expected_margin: Math.round(Number(commonInputs.ExpectedMargin)),
            revenue_before_deductions: Math.round(
              Number(commonInputs.RevBefDeductions)
            ),
            revenue_after_deductions: Math.round(
              Number(commonInputs.RevAftDeductions)
            ),
            final_gross_revenue: Math.round(
              Number(commonInputs.FinalGrossMargin)
            ),
            monthly_payment:
              Math.round(Number(commonInputs.MonthlyPayment)) || 0,
            current_owner: commonInputs.DealOwner,
            dr_status: commonInputs.DrStatus || statusConfig.DRINPRG,
            created_by: commonInputs.CreatedBy,
            last_update_by: userName || commonInputs.LastUpdateBy,
            parent_deal_review_id: null,
            dr_effective_ts: commonInputs.DrEffectiveTs,
            dr_end_ts: commonInputs.DrEndTs,
            solutions_arch_overview: commonInputs.SolutionsArchOverview,
            capabilities_lead_overview: commonInputs.CapabilitiesLeadOverview,
            legal_overview: commonInputs.LegalOverview,
            staffing_overview: commonInputs.StaffingOverview,
            "2-in-a-box-em": Math.round(Number(commonInputs.InABoxEM)),
            "2-in-a-box-dl": Math.round(Number(commonInputs.InABoxDL)),
            actual_start_date: commonInputs.ESTStartdate,
            actual_end_date: commonInputs.ESTEndDate,
            total_fixed_price: Math.round(Number(commonInputs.TotalFixedPrice)),
            total_discount: Math.round(Number(commonInputs.TotalDiscount)),
            total_cl_cost: Math.round(Number(commonInputs.TotalClCost)),
            total_pl_cost: Math.round(Number(commonInputs.TotalPlCost)),
            airfare: Math.round(Number(commonInputs.Airfare)),
            hotel_stay_cost: commonInputs.Hotels,
            ground_transport: commonInputs.GroundTransport,
            meal_expense: commonInputs.Meals,
            other_expense: commonInputs.Others,
            total_deal_expenses: Number(commonInputs.Expenes),
            client_managed: commonInputs.ClientManaged,
          },
          dealStaffingSheetArray: RoleCardDetails?.map((item, index) => ({
            cardIndex: index,
            staff_sheet_id: item.SheetID || 0,
            deal_review_id: commonInputs.DealreviewID,
            sow_role_name: item.SWORoleName,
            wavicle_role_id: item.RoleId,
            band: item.Band,
            is_offshore: `${item.Location === "OffShore"}`,
            actual_rate: item.ActualRate,
            utilization_percent: Math.round(Number(item.ProjectUtilization)),
            cost_utilization_percent: Math.round(Number(item.CostUtilization)),
            no_of_resources: Math.round(Number(item.Resources)),
            start_date: item.StartDate,
            end_date: item.EndDate,
            proposed_hours: Math.round(Number(item.ProposedHours)),
            indv_client_std_price: Math.round(Number(item.ClientStandardPrice)),
            indv_actual_revenue: Math.round(Number(item.ActualRevenue)),
            indv_revenue_after_deductions: Math.round(
              Number(item.RevenueAfterDeduction)
            ),
            created_by: commonInputs.CreatedBy,
            last_update_by: commonInputs.LastUpdateBy,
            indv_wavicle_cost: Math.round(Number(item.WavicleCost)),
            indv_discount_cost: Math.round(Number(item.WavicleDiscountCost)),
            indv_pl_cost: Math.round(Number(item.PLCost)),
            indv_cl_cost: Math.round(Number(item.CLCost)),
            client_managed: item.ClientManaged,
            required_skills: { req_skills: item.RequiredSkills || [] },
            resource_type: item.ResourceType,
            laptop_needed: item.LaptopNeeded,
            interview_panel: { interview_panels: item.InterviewPanel || [] },
            role_requirements: item.RoleRequirements,
            have_client_interview: item.HaveClientInterview,
            client_interview_info: item.ClientInterviewInfo,
          })),
        };
				return { dealReviewData };
			};
			const payload = transformDataForAPI();
			if (isSaveAPICommonsFilledFromPayload(payload?.dealReviewData?.dealReview)) {
				if (isSavedDataIntoDB) {
					let source = axios.CancelToken.source();
					const roleResponse = await apiPost(
						API_ROUTES.UPDATE_STAFFING_AND_OVERVIEW,
						payload,
						"updateStaffingAndOverview",
            header
					);
					if (roleResponse.status === 200) {
						if (roleResponse.data) {
							console.log(roleResponse.data);
							return roleResponse.data;
						}
					} else {
						throw new Error("Error, While Saving Deal");
					}
				}
			} else {
				// let errorMessage = "";
				// const Roles = FilledRoles(RoleCardDetails);
				// console.log(Roles);
				// errorMessage = Roles.length === 0 ? "Atleast One Role Requied." : "";
				throw new Error("Required Column must be Filled");
			}
		} catch (error) {
			// Check if the error is a cancellation error
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				return thunkAPI.rejectWithValue({
					error: error.message,
				});
			}
		}
	}
);

export const getDrFilterDetailsFromDB = createAsyncThunk(
  "calc/getDrFilterDetailsFromDB",
  async (projectId, thunkAPI) => {
    try {
      let source = axios.CancelToken.source();
      const response = await axios.get(
        `${BASE_URL}/${DEALREVIEW}/drgetfilter`,
        {
          cancelToken: source.token,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const getNewDRDetailsFromDB = createAsyncThunk(
  "calc/getNewDRDetailsFromDB",
  async (projectId, thunkAPI) => {
    try {
      let source = axios.CancelToken.source();
      const response = await axios.get(
        `${BASE_URL}/${DEALREVIEW}/drgetoverview?project_id=${projectId}`,
        {
          cancelToken: source.token,
        }
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const deleteRoleCardDB = createAsyncThunk(
	"calc/deleteRoleCardDB",
	async ({ clickedIndex }, thunkAPI) => {
		const { RoleCardDetails, commonInputs } = thunkAPI.getState().dealsAndRole;
		const item = RoleCardDetails[clickedIndex];
		try {
			if (!item?.SheetID) {
				return clickedIndex;
			}
			
			let source = axios.CancelToken.source();
			const payload = {
				"staffSheetId" : item.SheetID,
				"overviewEstEndDate": new Date(commonInputs.ESTEndDate).toISOString().split("T")?.[0] || null,
				"overviewEstStartDate": commonInputs.ESTStartdate,
				"commonInputTravelPercentage": commonInputs.ExpensePercentage || "0",
				"commonInputWavDiscount": commonInputs.DiscountInvestment === "$" ? `$${commonInputs.WavicleDiscount || 0}` : `${commonInputs.WavicleDiscount || 0}%`,
				"lastUpdatedBy": commonInputs.LastUpdateBy || "",
				"commonInputPLUtilizationPercentage": commonInputs.PLUtilization || "0",
				"commonInputCLUtilizationPercentage": commonInputs.CLUtilization || "0",
			}
			const response = await apiPatch(
				API_ROUTES.DELETE_ROLE_CARD_DB,
				payload,
				{
					cancelToken: source.token,
				},
        { "Socket-Action" : actionNameConfig["DEALREVIEW"].DELETE_ROLE_FOR_DEAL }
			);
			console.log(response);
			if (response.status === 200) {
				return clickedIndex;
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				console.error("Error fetching data:", error);
				throw new Error("Invalid response");
			}
			return thunkAPI.rejectWithValue({
				error: error.message,
			});
		}
	}
);

export const updatedStatus = createAsyncThunk(
  "calc/updatedStatus",
  async ({ projectId, DrStatus, comments, header }, thunkAPI) => {
    console.log(projectId, DrStatus, comments);
    try {
      let source = axios.CancelToken.source();
      const response = await apiPost(
        API_ROUTES.UPDATED_STATUS,
        { status: DrStatus, projectId: projectId, comments },
        {
          cancelToken: source.token,
        },
        header
      );

      console.log(response);

      if (response.status === 200 && response.data[0]?.update_status) {
        return { message: `Status updated to ${DrStatus}`, status: DrStatus };
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const submitLegal = createAsyncThunk(
  "calc/submitLegal",
  async ({ projectId, DrStatus, dealReviewId }, thunkAPI) => {
    const deal_review_id =
      thunkAPI.getState().dealsAndRole.commonInputs.DealreviewID;

    try {
      if (deal_review_id && projectId && DrStatus) {
        let source = axios.CancelToken.source();
        const response = await apiPost(
          API_ROUTES.SUBMIT_LEGAL,
          {
            deal_review_id: dealReviewId,
            project_id: projectId,
            leg_status: DrStatus,
            //   "deal_review_id": 1287,
            //   "project_id": "107",
            //   "leg_status": "LEG_NEW"
          },
          {
            cancelToken: source.token,
          }
        );
        console.log(response);
        if (response.status === 200 && response.data.data[0].insert_dr_info) {
          // data: { data: [ { insert_dr_info: -1 } ] },
          return response.data.data[0].insert_dr_info;
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const updateNewDR = createAsyncThunk(
  "calc/updateNewDR",
  async (_, thunkAPI) => {
    const { NewDRSrceenData } = thunkAPI.getState().dealsAndRole;
    const {
      hs_deal_id,
      deal_description,
      technology,
      // 			closed_won_description: "Sample dec from postman",
      // 			closed_lost_description: "Sample dec from postman",
    } = NewDRSrceenData[0];
    try {
      let source = axios.CancelToken.source();
      const response = await apiPost(
        API_ROUTES.UPDATE_NEW_DR,
        {
          hs_deal_id,
          deal_description,
          technology,
          closed_won_description: "Sample dec from UI",
          closed_lost_description: "Sample dec from UI",
        },
        {
          cancelToken: source.token,
        }
      );
      console.log(response);
      if (response.status === 200) {
        return response.data?.data;
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error);
        throw new Error("Invalid response");
      }
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);
