const statusConfig = {
  HSNEW: "HS_NEW",
  HSINPRG: "HS_INPRG",
  HSDCW: "HS_DCW",
  HSDCL: "HS_DCL",
  DRNEW: "DR_NEW",
  DRINPRG: "DR_INPRG",
  DRINREV: "DR_INREV",
  DRCMPLT: "DR_CMPLT",
  DRAPVD: "DR_APVD",
  DRREOPEN: "DR_REOPEN",
  DRPEND: "DR_PEND",
  DRCUSTAPVD: "DR_CUST_APVD",
  DRCUSTREJ: "DR_CUST_REJ",
  LEGNEW: "LEG_NEW",
  LEGINPRG: "LEG_INPRG",
  LEGVLDT: "LEG_VLDT",
  LEGREV: "LEG_REV",
  LEGCMPLT: "LEG_CMPLT",
  LEGCUSTREV: "LEG_CUST_REV",
  LEGCUSTAPVD: "LEG_CUST_APVD",
  LEGCUSTREJ: "LEG_CUST_REJ",
  FINNEW: "FIN_NEW",
  FININPRG: "FIN_INPRG",
  FINREV: "FIN_REV",
  FINCMPLT: "FIN_CMPLT",
  RMNEW: "RM_NEW",
  RMINPRG: "RM_INPRG",
  RMREOPEN: "RM_REOPEN",
  RMREV: "RM_REV",
  RMCMPLT: "RM_CMPLT",
  RMAPVD: "RM_APVD",
  SCOPEDNEW: "SCOPED_NEW",
  SCOPEDINPRG:"SCOPED_INPRG",
  SCOPEDCMLPT:"SCOPED_CMPLT",

  //status for screens
  SCR_LEGNEW: "New",
  SCR_LEGINPRG: "In-progress",
  SCR_LEGREV: "Review",
  SCR_LEGCMPLT: "Completed",
  SCR_LEGCUSTREV: "Customer Review",
  SCR_LEGCUSTAPVD: "Customer Approved",
  SCR_LEGCUSTREJ: "Customer Rejected",

  //inab
  INABNEW:'INAB_NEW',
  INABAPRV:'INAB_APPROVED',
  INABCMPLT:'INAB_COMPLETED',
  INABREJ:'INAB_REJECTED',
};

export default statusConfig;
