import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Typography, styled, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledSnackbar = styled(Snackbar)({
  borderRadius: "16px",
  border: "1px solid #5776E7", // Default border color
  background: "#F7F9FB", // Background color
});

const StyledAlert = styled(Alert)(({ theme, severity }) => ({
  padding: "6px 24px",
  borderRadius: "16px",
  border: `1px solid ${
    severity === "success"
      ? theme.palette.success.main
      : theme.palette.error.main
  }`,
  background: "white", // Background color
  color: "black", // Text color
  opacity: 10,
  gap: ".5rem",
  "& .MuiAlert-icon": {
    marginRight: theme.spacing(1), // Adjust spacing as needed
  },
  "& .MuiAlert-iconFilledSuccess": {
    color: theme.palette.success.main, // Adjust color for success severity
  },
  "& .MuiAlert-iconFilledError": {
    color: theme.palette.error.main, // Adjust color for error severity
  },
  "& .MuiAlert-iconFilledWarning": {
    color: theme.palette.warning.main, // Adjust color for warning severity
  },
  "& .MuiAlert-iconFilledInfo": {
    color: theme.palette.info.main, // Adjust color for info severity
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .MuiAlert-icon": {
    marginRight: 1, // Adjust spacing as needed
  },
  "& .MuiAlert-message": {
    marginRight: 1, // Adjust spacing as needed
  },
  "& .sub-label": {
    fontSize: "12px",
    color: "#1C1C1C66",
    marginTop: "4px", // Adjust margin top as needed
  },
}));

const iconMapping = {
  success: <CheckCircleIcon />,
  error: <ErrorIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
};
const useSnackbar = (duration = 1000) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState({});

  const ShowSnackbar = (message, severity, title = null) => {
    setOptions({ message, severity, title });
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const SnackbarComponent = () => {
    const theme =useTheme()
    return (
      <StyledSnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={duration} // Set autoHideDuration to null to prevent auto-hiding
        onClose={handleClose}
        id="snackbar"
      >
        <div>
          <StyledAlert
            variant="outlined"
            id='inner-snackbar'
            onClose={handleClose}
            severity={options.severity}
            icon={iconMapping[options.severity]}
          >
            <Typography variant="body2" color={"black"}>
              {options.severity === "error"
                ? options.title || "Something went wrong"
                : "Success"}
            </Typography>
            <Typography className="sub-label">{options.message}</Typography>
          </StyledAlert>
        </div>
      </StyledSnackbar>
    );
  };

  return { SnackbarComponent, ShowSnackbar };
};

export default useSnackbar;
