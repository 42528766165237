// // rootreducer.js

// import { combineReducers } from "redux";
// import { configureStore } from "@reduxjs/toolkit";
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import DealReview from "./slices/DealReview";
// import authReducer from "./slices/UserDetails";
// import legalDetailsReducer from "./slices/LegalDetailsSlice";
// import themeReducer from "./slices/themeSlice";
// import overviewReducer from "./slices/OverviewSlice";
// import breadcrumbsReducer from "./slices/BreadCrumbsSlice";
// import staffingReducer from "./slices/StaffingSlice";
// import { StateMonitor } from "./middleware/StateMonitor";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const rootReducer = combineReducers({
//   dealsAndRole: DealReview,
//   currentUser: authReducer,
//   legalDetails: legalDetailsReducer,
//   theme: themeReducer,
//   overview: overviewReducer,
//   breadcrumbs: breadcrumbsReducer,
//   staffing: staffingReducer,
//   // other reducers...
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat(StateMonitor)

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware,
// });

// export const persistor = persistStore(store);

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default is localStorage
import storageSession from "redux-persist/lib/storage/session"; // Import sessionStorage
import DealReview from "./slices/DealReview";
import authReducer from "./slices/UserDetails";
import legalDetailsReducer from "./slices/LegalDetailsSlice";
import themeReducer from "./slices/themeSlice";
import overviewReducer from "./slices/OverviewSlice";
import breadcrumbsReducer from "./slices/BreadCrumbsSlice";
import staffingReducer from "./slices/StaffingSlice";
import loadingReducer from "./slices/loadingSlice";
import permissionReducer from "./slices/PermissionSlice";
import { StateMonitor } from "./middleware/StateMonitor";

// Configure persist config for slices you want to store in localStorage
const localStorageConfig = {
  key: "root", // You can customize the key if needed
  storage: storage,
  whitelist: ["currentUser", "theme", "breadcrumbs", "legalDetails"], // Slices you want to store in localStorage
};

// Configure persist config for slices you want to store in sessionStorage
const sessionStorageConfig = {
  key: "sessionRoot", // You can customize the key if needed
  storage: storageSession,
};

// Combine reducers with persist reducers
const rootReducer = combineReducers({
  dealsAndRole: DealReview,
  currentUser: authReducer,
  legalDetails: legalDetailsReducer,
  theme: themeReducer,
  overview: overviewReducer,
  breadcrumbs: breadcrumbsReducer,
  staffing: staffingReducer,
  loader: loadingReducer,
  permission: persistReducer(sessionStorageConfig,permissionReducer),
  // other reducers...
});

const persistedReducer = persistReducer(localStorageConfig, rootReducer);

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(StateMonitor);

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);
