import React from "react";
import "./TextEditer.css";
import { styled } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ value, onChange, disabled,id }) => {
	return (
		<RootStyle>
			<ReactQuill
				theme='snow'
				id={id}
				readOnly={disabled}
				value={value}
				onChange={onChange}
			/>
		</RootStyle>
	);
};

const RootStyle = styled("div")(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	"& .ql-container.ql-snow": {
		borderColor: theme.palette.borderColor,
		...theme.typography.body1,
	},
	"& .ql-toolbar.ql-snow": {
		border: `solid 1px ${theme.palette.borderColor}`,
		backgroundColor: theme.palette.background.primary,
	},
	"& .ql-toolbar.ql-snow .ql-picker-label": {
		color: theme.palette.text.primary,
	},
	"& .ql-snow .ql-stroke": {
		stroke: theme.palette.text.primary,		
	},
	"& .ql-editor": {
		minHeight: 200,
		backgroundColor: theme.palette.background.primary,
		"&.ql-blank::before": {
			fontStyle: "normal",
			color: theme.palette.text.primary,
		},
		"& pre.ql-syntax": {
			...theme.typography.body2,
			padding: theme.spacing(2),
			borderRadius: theme.shape.borderRadius,
		},
	},
}));

export default QuillEditor;
