import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOverlayLoading: false,
};

const loadingSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoadingSpinner: (state, action) => {
      const { isOverlayLoading } = action.payload;
      state.isOverlayLoading = isOverlayLoading;
    },
  },
});

export const { setLoadingSpinner } = loadingSlice.actions;
export default loadingSlice.reducer;
