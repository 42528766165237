export const permissionMenu = [
    {
        id: 1,
        menuName: 'Overview',
        url: '/dashboard/scopedprojects',
        isParentRoute: true,
        permissions: {
            table: [],
            tab: [
                "scopedprojects",
                "projectinproposal",
                "dealreview",
                "legal",
                "resourcemanagement",
                "finance",
                "history",
            ],
            card: ["Scoped", "In Proposal", "Deal Review", "Legal Review", "Resource Management"],
            button: ["refresh"]
        },
        otherRoutes: {
            "/dashboard/projectinproposal": {
                permissions: {
                    table: [],
                    tab: [
                        "scopedprojects",
                        "projectinproposal",
                        "dealreview",
                        "legal",
                        "resourcemanagement",
                        "finance",
                        "history",
                    ],
                    card: ["Scoped", "In Proposal", "Deal Review", "Legal Review", "Resource Management"],
                },
            },
            "/dashboard/dealreview": {
                permissions: {
                    table: [],
                    tab: [
                        "scopedprojects",
                        "projectinproposal",
                        "dealreview",
                        "legal",
                        "resourcemanagement",
                        "finance",
                        "history",
                    ],
                    card: ["Scoped", "In Proposal", "Deal Review", "Legal Review", "Resource Management"],
                },
            },
            "/dashboard/legal" : {
                permissions: {
                    table: [],
                    tab: [
                        "scopedprojects",
                        "projectinproposal",
                        "dealreview",
                        "legal",
                        "resourcemanagement",
                        "finance",
                        "history",
                    ],
                    card: ["Scoped", "In Proposal", "Deal Review", "Legal Review", "Resource Management"],
                },
            },  
            "/dashboard/resourcemanagement" : {
                permissions: {
                    table: [],
                    tab: [
                        "scopedprojects",
                        "projectinproposal",
                        "dealreview",
                        "legal",
                        "resourcemanagement",
                        "finance",
                        "history",
                    ],
                    card: ["Scoped", "In Proposal", "Deal Review", "Legal Review", "Resource Management"],
                },
            },
            "/dashboard/finance" : {
                permissions: {
                    table: [],
                    tab: [
                        "scopedprojects",
                        "projectinproposal",
                        "dealreview",
                        "legal",
                        "resourcemanagement",
                        "finance",
                        "history",
                    ],
                    card: ["Scoped", "In Proposal", "Deal Review", "Legal Review", "Resource Management"],
                },
            },
            "/dashboard/history" : {
                permissions: {
                    table: [],
                    tab: [
                        "scopedprojects",
                        "projectinproposal",
                        "dealreview",
                        "legal",
                        "resourcemanagement",
                        "finance",
                        "history",
                    ],
                    card: ["Scoped", "In Proposal", "Deal Review", "Legal Review", "Resource Management"],
                },
            },
            "/dashboard/scoped-overview/new": {
                permissions: {
                    card: ["projectOverview", "projectStatus", "projectDescription", "additionalInfo", "editNotes"],
                    table: [],
                    tab: [],
                    button: ["BeginPreliminaryStaffing", "saveButton"],
                }
            },
            "/dashboard/scoped-review/in-progress": {
                permissions: {
                    card: ["projectDetail", "notes"],
                    table: ["preliminaryTable"],
                    tab: [],
                    button: ["floatingButton", "saveButton", "submitButton", "modal-confirm-button", "modal-cancel-button"],
                }
            },
            "/dashboard/scoped-overview/completed": {
                permissions: {
                    card: ["projectOverview", "projectStatus", "projectDescription", "additionalInfo", "editNotes"],
                    table: [],
                    tab: [],
                    button: ["BeginPreliminaryStaffing", "saveButton"],
                }
            },
            "/dashboard/over-view-project/proposal/dealreview/overview" : {
                permissions: {
                    card: ["overviewCardInDealOverviewPage","statusbarCardInDealOverviewPage","dealreviewOverviewProjectDescriptionContainor","dealreviewOverviewAdditionalInfoContainor"],
                    table: [],
                    tab: ["Overview", "Resources"],
                    button: ["begindealreview","addInfo-edit-Engagement Manager","addInfo-edit-Practice Lead","addInfo-edit-Competency Lead","Submit for Review"]
                },
            },
            "/dashboard/over-view-project/proposal/projectinproposal/overview": {
                permissions: {
                    card: ["overviewCardInDealOverviewPage","statusbarCardInDealOverviewPage","dealreviewOverviewProjectDescriptionContainor","dealreviewOverviewAdditionalInfoContainor"],
                    table: [],
                    tab: ["Overview", "Resources"],
                    button: ["begindealreview","addInfo-edit-Engagement Manager","addInfo-edit-Practice Lead","addInfo-edit-Competency Lead","Submit for Review"]
                },
            },
            "/dashboard/deal-review/in-progress/dealreview/overview" : {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","whichHindOfprojectContainer","projectDateSelectContainer","serviceCategoriesContainer","lifeCycleContainer","pricingTypeContainer","notesContainer","technologyContainer"],
                    table: [],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","Submit for Review"]
                },
            },
            "/dashboard/deal-review/in-progress/dealreview/staffing": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","highlightCardInDealreviewStaffing","notesForUsersInDealreview"],
                    table: ["roleTableInDealreview"],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","popup-openclick-link","cancel-popup-button","update-popup-button","Submit for Review"]
                },
            },
            "/dashboard/deal-review/in-progress/dealreview/staffingintake": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","roleRequirementContainer","clientInterviewContainer","staffingTypeResource","requiredSkillContainer","overviewInDealreviewReviewTab"],
                    table: ["roleTableInDealreview"],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","submit-button","Submit for Review", "have-client-interview"]
                },
            },
            "/dashboard/deal-review/in-progress/dealreview/review": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","overviewInDealreviewReviewTab","highlightCardInDealreviewReview","listCardInDealreviewReview"],
                    table: ["staffingTableInDealreviewReview"],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","submit-button","Submit for Review","modal-cancel-button","modal-confirm-button"]
                },
            },
            "/dashboard/over-view-project/reopen/dealreview/overview" : {
                permissions: {
                    card: ["overviewCardInDealOverviewPage","statusbarCardInDealOverviewPage","dealreviewOverviewProjectDescriptionContainor","dealreviewOverviewAdditionalInfoContainor"],
                    table: [],
                    tab: ["Overview", "Resources"],
                    button: ["begindealreview","addInfo-edit-Engagement Manager","addInfo-edit-Practice Lead","addInfo-edit-Competency Lead","Submit for Review"]
                },
            },
            "/dashboard/review-and-approval/in-review": {
                permissions: {
                    card: ["overviewCardInDealreviewInreview","dealReviewProjectDetails","dealReviewMarginDetails","clientManagedDiscription","dealReviewNotes","highlightCardInDealreviewInreview","listCardInDealreviewInreview"],
                    table: ["resourcesTable","staffingIntakeReviewTable"],
                    tab: ["Overview", "Margins & Staffing", "Staffing Intake-RM"],
                    button: ["approve-reject-button-container","legal-reject","legal-approve","modal-cancel-button","modal-confirm-button"]
                },
            },
            "/dashboard/review-and-approval/approved" : {
                permissions: {
                    card: ["overviewCardInDealreviewInreview","dealReviewProjectDetails","dealReviewMarginDetails","clientManagedDiscription","dealReviewNotes","highlightCardInDealreviewInreview","listCardInDealreviewInreview"],
                    table: ["resourcesTable","staffingIntakeReviewTable"],
                    tab: ["Overview", "Margins & Staffing", "Staffing Intake-RM"],
                    button: ["send-to-legal-container","submit-legal"]
                },
            },
            "/dashboard/legal-questionnaire/pending" : {
                permissions: {
                    card: ["overviewCardInDealreviewQuestionary","datesContainer","additionalInformation","clientResponsibilityInQuestionary","assumptionValuesInQuestionary","dealdescriptionInQuestionary"],
                    table: [],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["submit-button","save-button","save-and-submit-container","modal-confirm-button","modal-cancel-button","isAgile","deliverablesSet","isCapped","isSupportIncluded"]
                },
            },
            "/dashboard/legal-over-view/new/legal/overview": {
                // Legal new Overview
                permissions: {
                    card: ["legalOverViewProjectCard", "legalOverViewProjectStatus", "additional-info", "deal-description", "project-details"],
                    table: [],
                    tab: ["Overview", "Deal Review"],
                    button: ["Begin Legal Review"]
                }
            },
            "/dashboard/legal-over-view/new/legal/dealreview": {
                // Legal new Deal Review Overview
                permissions: {
                    card: ["legalDealReviewProjectCard", "dealReviewProjectDetails", "dealReviewMarginDetails", "clientManagedDiscription", "dealReviewNotes","revenueMargins","marginsStaffingCalc"],
                    table: ["resourcesTable"],
                    tab: ["Overview", "Deal Review", "legal-overview-overview", "legal-overview-MarginsStaffing"],
                    button: ["client-managed-switch", "Begin Legal Review"]
                }
            },
            "/dashboard/sow-generation/in-progress/legal/sowinfo" :{
                // SOW generation
                permissions: {
                    card: ["generateSOWProjectOverview", "deleverablesList", "projectAssumptions", "clientResponsibility"],
                    table: [],
                    tab: ["sowinfo", "generatesow"],
                    button: ["deal-description-editIcon", "assumptions-editIcon", "responsibility-editIcon", "save-changes"]
                }
            },
            "/dashboard/sow-generation/in-progress/legal/generatesow" :{
                // SOW generation
                permissions: {
                    card: ["generateSOWProjectOverview", "AutomatedSOWGeneration", "SelectSOWTemplate", "ContainerSOW",],
                    table: [],
                    tab: ["sowinfo", "generatesow"],
                    button: ["generate-sow-button","download-sow-button", "send-to-validation"]
                }
            },
            "/dashboard/sow-upload/in-validation/legal" : {
                // SOW upload in-validation
                permissions: {
                    card: ["legalOverviewProjectCard", "ChooseGeneratedSOW", "ChooseUploadSOW", "UploadSOW", "GeneratedSOW", "uploadedSow"],
                    table: [],
                    tab: ["sowinfo", "generatesow"],
                    button: ["upload-sow-button", "sendToReview", "UploadedPreview", "modal-cancel-button", "modal-confirm-button"]
                }
            },
            "/dashboard/sow-review/in-review/legal" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist"],
                    table: [],
                    tab: ["sowinfo", "uploadsow"],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected"]
                }
            },
            "/dashboard/sow-review/customer-review/legal" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist"],
                    table: [],
                    tab: ["sowinfo", "uploadsow"],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected"]
                }
            },
            "/dashboard/sow-review/rejected/legal" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist", "ReasonComments"],
                    table: [],
                    tab: [],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected", "deal-lost-button", "reopendeal-button", "reopenlegal-button", "floating-buttons"]
                }
            },
            "/dashboard/sow-review/approved/legal" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist", "sow-upload"],
                    table: [],
                    tab: ["sowinfo", "uploadsow"],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected", "send-resource-to-rm"]
                }
            },
            "/dashboard/resource-allocation-dashboard/resource-allocation/proposal" : {
                permissions: {
                    card: ["projectDescription","overviewCardInRMResourceallocation","detailsContainer","resourceRequested","view-sow"],
                    table: ["resourceRequested"],
                    tab: [],
                    button: ["begin-ra","view"],
                }
            },
            "/dashboard/resource-allocation/resource-allocation-fact/review/inprogress" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["submit-review","modal-confirm-button","modal-cancel-button"],
                }
            },
            "/dashboard/resource-allocation/resource-allocation-fact" : {
                permissions: {
                    card: ["projectoverview"],
                    table: [],
                    tab: [],
                    button: ["review", "save", "Show only available resources", "Auto-Recommend"],
                }
            },
            "/dashboard/resource-allocation/resource-allocation-fact/review/in-review" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["reject","approve","modal-confirm-button","modal-cancel-button"],
                }
            },
            "/dashboard/resource-allocation/resource-allocation-fact/review/reopen" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["return-dashboard"],
                }
            },
            "/dashboard/resource-allocation/resource-allocation-fact/review/approved" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["complete"],
                }
            },
            "/dashboard/resource-allocation/resource-allocation-fact/review/completed" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["return-dashboard"],
                }
            },
        },
    },
    {
        id: 2,
        menuName: 'Scoped Deals',
        url: '/scoped-dashboard',
        isParentRoute: true,
        permissions: {
            card: [],
            table: [],
            tab: [],
            button: [],
        },
        otherRoutes:
        {
            "/scoped-dashboard/scoped-overview/new": {
                permissions: {
                    card: ["projectOverview", "projectStatus", "projectDescription", "additionalInfo", "editNotes"],
                    table: [],
                    tab: [],
                    button: ["BeginPreliminaryStaffing", "saveButton"],
                }
            },
            "/scoped-dashboard/scoped-review/in-progress": {
                permissions: {
                    card: ["projectDetail", "notes"],
                    table: ["preliminaryTable"],
                    tab: [],
                    button: ["floatingButton", "saveButton", "submitButton", "modal-confirm-button", "modal-cancel-button"],
                }
            },
            "/scoped-dashboard/scoped-overview/completed":{
                permissions: {
                    card: ["projectOverview", "projectStatus", "projectDescription", "additionalInfo", "editNotes"],
                    table: [],
                    tab: [],
                    button: ["BeginPreliminaryStaffing", "saveButton"],
                }
            },
        },
    },
    {
        id: 3,
        menuName: 'INAB',
        url: '/inab/new',
        isParentRoute: true,
        permissions: {
            card: ["New Submissions", "Projects In-Flight", "Projects Completed"],
            table: [],
            tab: ["New", "Projectsin-Flight", "Approved", "Completed", "Cancelled/Rejected"],
            button: ["newSubmissionButton"],
        },
        otherRoutes:
        {
            "/inab/projectsinflight" : {
                permissions: {
                    card: ["New Submissions", "Projects In-Flight", "Projects Completed"],
                    table: [],
                    tab: ["New", "Projectsin-Flight", "Approved", "Completed", "Cancelled/Rejected"],
                    button: ["newSubmissionButton"],
                },
            },
            "/inab/approved" : {
                permissions: {
                    card: ["New Submissions", "Projects In-Flight", "Projects Completed"],
                    table: [],
                    tab: ["New", "Projectsin-Flight", "Approved", "Completed", "Cancelled/Rejected"],
                    button: ["newSubmissionButton"],
                },
            },
            "/inab/completed" : {
                permissions: {
                    card: ["New Submissions", "Projects In-Flight", "Projects Completed"],
                    table: [],
                    tab: ["New", "Projectsin-Flight", "Approved", "Completed", "Cancelled/Rejected"],
                    button: ["newSubmissionButton"],
                },
            },
            "/inab/cancelledrejected" : {
                permissions: {
                    card: ["New Submissions", "Projects In-Flight", "Projects Completed"],
                    table: [],
                    tab: ["New", "Projectsin-Flight", "Approved", "Completed", "Cancelled/Rejected"],
                    button: ["newSubmissionButton"],
                },
            },
            "/inab/new-submission": {
                permissions: {
                    card: ["projectDetails","projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: ["inabNewPageFloatingContainer","FloatingButtonCancel","FloatingButtonSubmit"],
                }
            },
            "/inab/review-and-approval/proposal": {
                permissions: {
                    card: ["projectDetails","projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: ["inabApprovePageFloatingContainer","FloatingButtonReject","FloatingButtonApprove","modal-cancel-button","modal-confirm-button"],
                }
            },
            "/inab/submit-for-rm/approved": {
                permissions: {
                    card: ["projectDetails","projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: ["FloatingButtonSendtoResourceManagement","inabSubmitPageFloatingContainer"],
                }
            },
            "/inab/submit-for-rm/completed": {
                permissions: {
                    card: ["projectDetails","projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: [],
                }
            },
            "/inab/submit-for-rm/rejected": {
                permissions: {
                    card: ["projectDetails","projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: [],
                }
            },
        }
    },
    {
        id: 4,
        menuName: 'Deal Review',
        url: '/deal-review-dashboard/new',
        isParentRoute: true,
        permissions: {
            card: ["New", "In Progress", "In Review", "Complete"],
            table: [],
            tab: ["New", "In Progress", "Re-Open", "In Review", "Approved", "Legal Pending", "Completed"],
        },
        otherRoutes:
        {
            "/deal-review-dashboard/over-view-project/proposal/new/overview": {
                permissions: {
                    card: ["overviewCardInDealOverviewPage","statusbarCardInDealOverviewPage","dealreviewOverviewProjectDescriptionContainor","dealreviewOverviewAdditionalInfoContainor"],
                    table: [],
                    tab: ["Overview", "Resources"],
                    button: ["begindealreview","addInfo-edit-Engagement Manager","addInfo-edit-Practice Lead","addInfo-edit-Competency Lead","Submit for Review"]
                },
            },
            "/deal-review-dashboard/deal-review/in-progress/inprogress/overview": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","whichHindOfprojectContainer","projectDateSelectContainer","serviceCategoriesContainer","lifeCycleContainer","pricingTypeContainer","notesContainer","technologyContainer","highlightCardInDealreviewStaffing"],
                    table: [],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","Submit for Review"]
                },
            },
            "/deal-review-dashboard/deal-review/in-progress/inprogress/staffing": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","highlightCardInDealreviewStaffing","notesForUsersInDealreview"],
                    table: ["roleTableInDealreview"],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","popup-openclick-link","cancel-popup-button","update-popup-button","Submit for Review"]
                },
            },
            "/deal-review-dashboard/deal-review/in-progress/inprogress/staffingintake": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","roleRequirementContainer","clientInterviewContainer","staffingTypeResource","requiredSkillContainer","overviewInDealreviewReviewTab"],
                    table: ["roleTableInDealreview"],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","submit-button","Submit for Review","have-client-interview"]
                },
            },
            "/deal-review-dashboard/deal-review/in-progress/inprogress/review": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","overviewInDealreviewReviewTab","highlightCardInDealreviewReview","listCardInDealreviewReview"],
                    table: ["staffingTableInDealreviewReview"],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","submit-button","Submit for Review","modal-cancel-button","modal-confirm-button"]
                },
            },
            "/deal-review-dashboard/review-and-approval/in-review": {
                permissions: {
                    card: ["overviewCardInDealreviewInreview","dealReviewProjectDetails","dealReviewMarginDetails","clientManagedDiscription","dealReviewNotes","highlightCardInDealreviewInreview","listCardInDealreviewInreview"],
                    table: ["resourcesTable","staffingIntakeReviewTable"],
                    tab: ["Overview", "Margins & Staffing", "Staffing Intake-RM"],
                    button: ["approve-reject-button-container","legal-reject","legal-approve","modal-cancel-button","modal-confirm-button"]
                },
            },
            "/deal-review-dashboard/review-and-approval/approved": {
                permissions: {
                    card: ["overviewCardInDealreviewInreview","dealReviewProjectDetails","dealReviewMarginDetails","clientManagedDiscription","dealReviewNotes","highlightCardInDealreviewInreview","listCardInDealreviewInreview"],
                    table: ["resourcesTable","staffingIntakeReviewTable"],
                    tab: ["Overview", "Margins & Staffing", "Staffing Intake-RM"],
                    button: ["send-to-legal-container","submit-legal"]
                },
            },
            "/deal-review-dashboard/over-view-project/reopen/reopen/overview": {
                permissions: {
                    card: ["overviewCardInDealOverviewPage","statusbarCardInDealOverviewPage","dealreviewOverviewProjectDescriptionContainor","dealreviewOverviewAdditionalInfoContainor"],
                    table: [],
                    tab: ["Overview", "Resources"],
                    button: ["begindealreview","addInfo-edit-Engagement Manager","addInfo-edit-Practice Lead","addInfo-edit-Competency Lead","Submit for Review"]
                },
            },
            "/deal-review-dashboard/deal-review/in-progress/overview/overview": {
                permissions: {
                    card: ["overviewCardInDealreviewReviewPage","whichHindOfprojectContainer","projectDateSelectContainer","serviceCategoriesContainer","lifeCycleContainer","pricingTypeContainer","notesContainer","technologyContainer","highlightCardInDealreviewStaffing"],
                    table: [],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["save-changes-in-dealreview-review","Submit for Review"]
                },
            },
            "/deal-review-dashboard/legal-questionnaire/pending": {
                permissions: {
                    card: ["overviewCardInDealreviewQuestionary","datesContainer","additionalInformation","clientResponsibilityInQuestionary","assumptionValuesInQuestionary","dealdescriptionInQuestionary"],
                    table: [],
                    tab: ["Overview", "Staffing", "Staffing Intake", "Review"],
                    button: ["submit-button","save-button","save-and-submit-container","modal-confirm-button","modal-cancel-button","isAgile","deliverablesSet","isCapped","isSupportIncluded"]
                },
            },
            "/deal-review-dashboard/review-and-approval/completed": {
                permissions: {
                    card: ["overviewCardInDealreviewInreview","projectDetailsContainer","marginOverviewdetails","clientManagedProjectContainer","notesContainer","highlightCardInDealreviewInreview","listCardInDealreviewInreview"],
                    table: ["staffingTableInDealreviewInreview","staffingIntakeReviewTable"],
                    tab: ["Overview", "Margins & Staffing", "Staffing Intake-RM"],
                    button: ["legal-backtodashboard","back-to-dashboard-button-container"]
                },
            },
        }
    },
    {
        id: 5,
        menuName: 'Legal Review',
        url: '/legal-dashboard/new',
        isParentRoute: true,
        permissions: {
            card: ["New", "In Progress", "Validation", "In Review", "Complete"],
            table: [],
            tab: ["New", "In Progress", "Validation", "In Review", "Completed"],
            button: []
        },
        otherRoutes:
        {
            
            "/legal-dashboard/inprogress" :{
                permissions: {
                    card: ["New", "In Progress", "Validation", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Validation", "In Review", "Completed"],
                    button: []
                },
            },
            "/legal-dashboard/validation" : {
                permissions: {
                    card: ["New", "In Progress", "Validation", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Validation", "In Review", "Completed"],
                    button: []
                },
            },
            "/legal-dashboard/inreview" : {
                permissions: {
                    card: ["New", "In Progress", "Validation", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Validation", "In Review", "Completed"],
                    button: []
                },
            },
            "/legal-dashboard/completed" : {
                permissions: {
                    card: ["New", "In Progress", "Validation", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Validation", "In Review", "Completed"],
                    button: []
                },
            },
            "/legal-dashboard/legal-over-view/new/new/overview": {
                permissions: {
                    card: ["legalOverViewProjectCard", "legalOverViewProjectStatus", "additional-info", "deal-description", "project-details"],
                    table: [],
                    tab: ["Overview", "Deal Review"],
                    button: [ "Begin Legal Review"]
                }
            },
            "/legal-dashboard/legal-over-view/new/new/dealreview": {
                permissions: {
                    card: ["legalDealReviewProjectCard", "dealReviewProjectDetails", "dealReviewMarginDetails", "clientManagedDiscription", "dealReviewNotes","revenueMargins","marginsStaffingCalc"],
                    table: ["resourcesTable"],
                    tab: ["Overview", "Deal Review", "legal-overview-overview", "legal-overview-MarginsStaffing"],
                    button: ["client-managed-switch", "Begin Legal Review"]
                }
            },
            "/legal-dashboard/sow-generation/in-progress/inprogress/sowinfo" : {
                permissions: {
                    card: ["generateSOWProjectOverview", "deleverablesList", "projectAssumptions", "clientResponsibility"],
                    table: [],
                    tab: ["sowinfo", "generatesow"],
                    button: ["deal-description-editIcon", "assumptions-editIcon", "responsibility-editIcon", "save-changes"]
                }
            },
            "/legal-dashboard/sow-generation/in-progress/inprogress/generatesow" :{
                permissions: {
                    card: ["generateSOWProjectOverview", "AutomatedSOWGeneration", "SelectSOWTemplate", "ContainerSOW",],
                    table: [],
                    tab: ["sowinfo", "generatesow"],
                    button: ["generate-sow-button","download-sow-button", "send-to-validation"]
                }
            },
            "/legal-dashboard/sow-upload/in-validation/validation" : {
                permissions: {
                    card: ["legalOverviewProjectCard", "ChooseGeneratedSOW", "ChooseUploadSOW", "UploadSOW", "GeneratedSOW", "uploadedSow"],
                    table: [],
                    tab: ["sowinfo", "generatesow"],
                    button: ["upload-sow-button", "sendToReview", "UploadedPreview", "modal-cancel-button", "modal-confirm-button"]
                }
            },
            "/legal-dashboard/sow-review/in-review/inreview" : {
                // SOW upload in-validation
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist"],
                    table: [],
                    tab: ["sowinfo", "uploadsow"],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected"]
                }
            },
            "/legal-dashboard/sow-review/customer-review/inreview" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist"],
                    table: [],
                    tab: ["sowinfo", "uploadsow"],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected"]
                }
            },
            "/legal-dashboard/sow-review/approved/inreview" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist", "sow-upload"],
                    table: [],
                    tab: ["sowinfo", "uploadsow"],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected","send-resource-to-rm"]
                }
            },
            "/legal-dashboard/sow-review/rejected/inreview" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist", "ReasonComments"],
                    table: [],
                    tab: [],
                    button: ["cust-rev-switch", "modal-cancel-button", "modal-confirm-button", "approved", "rejected", "deal-lost-button", "reopendeal-button", "reopenlegal-button", "floating-buttons"]
                }
            },
            "/legal-dashboard/sow-review/completed/completed" : {
                permissions: {
                    card: ["legal-project-overview-card", "sow-view", "legal-checklist", "sow-upload"],
                    table: [],
                    tab: [],
                    button: ["cust-rev-switch", "approved", "rejected"]
                }
            },
        }
    },
    {
        id: 9,
        menuName: 'RM Dashboard',
        url: '/rm/rm-dashboard',
        isParentRoute: true,
        permissions: {
            card: ["Resources not in Client Projects","Resources who has a project end date coming up","Total Resources", "Total Projects", "Allocated", "Not Allocated", "Resource Allocation", "Allocation by Competency", "Allocation by Band", "Allocation by Location", "Allocation by Role"],
            table: ["Resources not in Client Projects", "Resources who has a project end date coming up"],
            tab: [],
            button: []
        },
        otherRoutes: {}
    },
    {
        id: 10,
        menuName: 'RM Pepole',
        url: '/rm/peoplelanding/allresources',
        isParentRoute: true,
        permissions: {
            card: [],
            table: ["allResourcesTable"],
            tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
            chart: [],
            report: [],
            button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
        },
        otherRoutes:{
            "/rm/peoplelanding/mentorsads" : {
                permissions: {
                    card: [],
                    table: ["managersTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/inprojects" : {
                permissions: {
                    card: [],
                    table: ["inprojectTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/inbench" : {
                permissions: {
                    card: [],
                    table: ["inbenchTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/approachingbench" : {
                permissions: {
                    card: [],
                    table: ["approachBenchTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/fulltime" : {
                permissions: {
                    card: [],
                    table: ["fullTimeEmployeeTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/contractors" : {
                permissions: {
                    card: [],
                    table: ["contractorTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/leadership" : {
                permissions: {
                    card: [],
                    table: ["leadershipTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/india" : {
                permissions: {
                    card: [],
                    table: ["indiaPeopleTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/us" : {
                permissions: {
                    card: [],
                    table: ["usPeopleTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/canada" : {
                permissions: {
                    card: [],
                    table: ["canadaPeopleTable"],
                    tab: ["All Resources", "Mentors/AD’s", "In Projects", "In Bench", "Approaching Bench", "Full-Time", "Contractors", "Leadership", "India", "US", "Canada"],
                    chart: [],
                    report: [],
                    button:["addIconInPeopleLanding","closeButton","export-button","download-button","apply-changes-button"],
                },
            },
            "/rm/peoplelanding/add-employee" : {
                permissions: {
                    card: ["employee-first-name","employee-last-name","employee-type","employee-joining-date","employee-organization","employee-geography","employee-department","employee-manager","employee-title/role","employee-band","employee-emailaddress","employee-total-experience/month","employee-total-experience/year","employee-state","employee-city","employee-phonenumber","employee-dateOfBirth","employee-personalEmail","employee-primary-skill","employee-secondary-skill","employee-domain-experience","employee-certifications","resumeUploadContainer","personalInformationForm","experienceForm","skillAndExpertiseForm","employeeDetailsForm"],
                    table: [],
                    tab: [],
                    chart: [],
                    report: [],
                    button:["addemployee","add-employee-button-container"],
                },
            },
            "/rm/peoplelanding/profile/allocated" : {
                permissions: {
                    card: ["overviewCardInPeople","statusbarInPeople","resumeViewContainer","employeeCurrentProjectDetails","quickStats","internalProjects","projectHistory"],
                    table: ["projectHistory","internalProjects"],
                    tab: [],
                    chart: [],
                    report: [],
                    button:["view","editButton","saveButton","cancelButton"],
                },
            },
        }
    },
    {
        id: 11,
        menuName: 'RM Projects',
        url: '/rm/projects/allprojects',
        isParentRoute: true,
        permissions: {
            card: ["Active Client Projects", "Current Clients", "Projects Expired", "Wavicle Projects"],
            table: [],
            tab: ["All Projects", "Active Projects", "Completed Projects", "Wavicle Projects", "Complete"],
        },
        otherRoutes:{
            "/rm/projects/activeprojects" : {
                permissions: {
                    card: ["Active Client Projects", "Current Clients", "Projects Expired", "Wavicle Projects"],
                    table: [],
                    tab: ["All Projects", "Active Projects", "Completed Projects", "Wavicle Projects", "Complete"],
                },
            },
            "/rm/projects/completedprojects" : {
                permissions: {
                    card: ["Active Client Projects", "Current Clients", "Projects Expired", "Wavicle Projects"],
                    table: [],
                    tab: ["All Projects", "Active Projects", "Completed Projects", "Wavicle Projects", "Complete"],
                },
            },
            "/rm/projects/wavicleprojects" : {
                permissions: {
                    card: ["Active Client Projects", "Current Clients", "Projects Expired", "Wavicle Projects"],
                    table: [],
                    tab: ["All Projects", "Active Projects", "Completed Projects", "Wavicle Projects", "Complete"],
                },
            },
            "/rm/projects/project-details/active/overview" : {
                permissions: {
                    card: ["projectoverviewcard","detailscard","latestfiles","notes"],
                    table: [],
                    tab: ["Resources","Overview"],
                    button: ["view-sow","save-changes","cancel"],
                }
            },
            "/rm/projects/project-details/pending/overview" :{
                permissions: {
                    card: ["projectoverviewcard", "detailscard", "latestfiles", "notes"],
                    table: [],
                    tab: ["overview", "resources"],
                    button: ["viewsow", "cancel", "savechanges"]
                },
            },
            "/rm/projects/project-details/active/resources" : {
                permissions: {
                    card: ["projectoverviewcard"],
                    table: ["currentResources"],
                    tab: ["Resources","Overview"],
                    button: ["add","delete"],
                }
            },
            "/rm/projects/project-details/pending/resources" : {
                permissions: {
                    card: ["projectoverviewcard",],
                    table: ["currentResources"],
                    tab: ["overview", "resources"],
                    button: ["delete", "add"]
                },
            }, 
            "/rm/projects/inab-details/proposal" : {
                permissions: {
                    card: ["projectDetails", "projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: [],
                }
            },
            "/rm/projects/inab-details/approved" : {
                permissions: {
                    card: ["projectDetails", "projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: [],
                }
            },
            "/rm/projects/inab-details/rejected" : {
                permissions: {
                    card: ["projectDetails", "projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: [],
                }
            },
            "/rm/projects/inab-details/completed" : {
                permissions: {
                    card: ["projectDetails", "projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: [],
                }
            },
           
        },
            
    },
    {
        id: 12,
        menuName: 'RM Resource Allocation',
        url: '/rm/resource-allocation-dashboard/new',
        isParentRoute: true,
        permissions: {
            card: ["New", "In Progress", "In Review", "Complete"],
            table: [],
            tab: ["New", "In Progress", "Re-Open", "Review", "Complete", "Demand Pipeline"],
        },
        otherRoutes:{
            "/rm/resource-allocation-dashboard/inprogress" : {
                permissions: {
                    card: ["New", "In Progress", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Re-Open", "Review", "Complete", "Demand Pipeline"],
                },
            },
            "/rm/resource-allocation-dashboard/reopen" : {
                permissions: {
                    card: ["New", "In Progress", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Re-Open", "Review", "Complete", "Demand Pipeline"],
                },
            },
            "/rm/resource-allocation-dashboard/review" : {
                permissions: {
                    card: ["New", "In Progress", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Re-Open", "Review", "Complete", "Demand Pipeline"],
                },
            },
            "/rm/resource-allocation-dashboard/complete" : {
                permissions: {
                    card: ["New", "In Progress", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Re-Open", "Review", "Complete", "Demand Pipeline"],
                },
            },
            "/rm/resource-allocation-dashboard/demandpipeline" : {
                permissions: {
                    card: ["New", "In Progress", "In Review", "Complete"],
                    table: [],
                    tab: ["New", "In Progress", "Re-Open", "Review", "Complete", "Demand Pipeline"],
                },
            },
            "/rm/inab-resource-allocation": {
                permissions: {
                    card: ["projectDetails", "projectNameInInabContainer","totalCostContainer","ResourcesAllocatedEstimatedStaffingStaff","projecOwnerInInabContainer","projectProposalStartDateInInabContainer","projectBusinessOrPropositionInInabContainer","projectDescriptionInInabContainer","projectSponsorsInInabContainer","projectProposalEndDateInInabContainer","projectDurationInWeeksInInabContainer","projectPlatformOrInfrastructureCostEstimateInInabContainer","projectUploadProposalInInabContainer"],
                    table: ["inabStaffingTable"],
                    tab: [],
                    button: ["begin-resource-allocation-button"],
                }
            },
            "/rm/inab-resource-allocation/resource-allocation-fact/review/inprogress" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["submit-review","modal-confirm-button","modal-cancel-button"],
                }
            },
            "/rm/inab-resource-allocation/resource-allocation-fact/review/in-review" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["approve","reject","modal-confirm-button","modal-cancel-button"],
                }
            },
            "/rm/inab-resource-allocation/resource-allocation-fact/review/reopen" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["return-dashboard"],
                }
            },
            "/rm/inab-resource-allocation/resource-allocation-fact/reopen" : {
                permissions: {
                    card: ["projectoverview"],
                    table: [],
                    tab: [],
                    button: ["review", "save", "Show only available resources", "Auto-Recommend"],
                }
            },
            "/rm/inab-resource-allocation/resource-allocation-fact/in-progress" : {
                permissions: {
                    card: ["projectoverview"],
                    table: [],
                    tab: [],
                    button: ["review", "save", "Show only available resources", "Auto-Recommend"],
                }
            },
            "/rm/inab-resource-allocation/resource-allocation-fact/review/approved" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["complete"],
                }
            },
            "/rm/inab-resource-allocation/resource-allocation-fact/review/completed" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["return-dashboard"],
                }
            },
            "/rm/resource-allocation/resource-allocation-fact" : {
                permissions: {
                    card: ["projectoverview"],
                    table: [],
                    tab: [],
                    button: ["review", "save", "Show only available resources", "Auto-Recommend"],
                }
            },
            "/rm/resource-allocation/resource-allocation-fact/review/inprogress" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["submit-review","modal-confirm-button","modal-cancel-button"],
                }
            },
            "/rm/resource-allocation/resource-allocation-fact/review/in-review" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["reject","approve","modal-confirm-button","modal-cancel-button"],
                }
            },
            "/rm/resource-allocation/resource-allocation-fact/review/approved" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["complete"],
                }
            },
            "/rm/resource-allocation/resource-allocation-fact/review/completed" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["return-dashboard"],
                }
            },
            "/rm/resource-allocation/resource-allocation-fact/review/reopen" : {
                permissions: {
                    card: ["overviewCardInRMReview","roleDetailsTable"],
                    table: ["roleDetailsTable"],
                    tab: [],
                    button: ["return-dashboard"],
                }
            },
            "/rm/resource-allocation-dashboard/resource-allocation/proposal" : {
                permissions: {
                    card: ["projectDescription","overviewCardInRMResourceallocation","detailsContainer","resourceRequested","view-sow"],
                    table: ["resourceRequested"],
                    tab: [],
                    button: ["begin-ra","view"],
                }
            },
            // "/rm/resource-allocation/resource-allocation-fact/inprogress"
            // "/rm/inab-resource-allocation/resource-allocation-fact/in-progress"
            // "/rm/inab-resource-allocation/resource-allocation-fact/inprogress"
        }
    }
]

