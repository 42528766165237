// LegalDetailsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES, apiGet } from "../../configure/apiConfig";
import { PageConfig } from "../../configure/PathConfig";

const initialState = {
  legalDetails: [],
};

export const fetchLegalDetails = createAsyncThunk(
  `${PageConfig.FETCH_LEGAL}`,
  async (_, thunkAPI) => {
    try {
      const response = await apiGet(API_ROUTES.GET_LEGAL_DASHBOARD);

      if (response) {
        const data = response.data.data;
        console.log(data)
        if (Array.isArray(data)) {
          return data;
        } else {
          throw new Error("Invalid data format in the response");
        }
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Error fetching legal details",
      });
    }
  }
);

const legalDetailsSlice = createSlice({
  name: "legalDetails",
  initialState,
  reducers: {
    updateLegalDetails: (state, action) => {
      const updatedDetails = action.payload;
      const existingDetailsIndex = state.legalDetails.findIndex(
        (detail) => detail.deal_id === updatedDetails.deal_id
      );

      if (existingDetailsIndex !== -1) {
        state.legalDetails[existingDetailsIndex] = updatedDetails;
      }
    },
    deleteLegalDetails: (state, action) => {
      const dealIdToDelete = action.payload;
      state.legalDetails = state.legalDetails.filter(
        (detail) => detail.deal_id !== dealIdToDelete
      );
    },
    resetLegalDetails: (state) => {
      state.legalDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLegalDetails.fulfilled, (state, action) => {
        state.legalDetails = action.payload;
      })
      .addCase(fetchLegalDetails.rejected, (state, action) => {
        console.log("Error fetching legal details");
      });
  },
});

export const { updateLegalDetails, deleteLegalDetails, resetLegalDetails } =
  legalDetailsSlice.actions;
export default legalDetailsSlice.reducer;
