import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const SecTitleDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  boxSizing: 'border-box', // Corrected syntax for box-sizing
  gap: '6px',
  textAlign: 'left', // Corrected syntax for text-align
  color: '#ffb082', // You can access theme colors here if needed
  fontFamily: 'var(--default-font)',
  flex: 1,
}));

export const BoxStyled = styled('div')({
  width: "100%",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "rgba(255, 255, 255, 0.08)",
  display: "flex",
  flexDirection: "row", // Changed to row to align items horizontally
  alignItems: "center",
  justifyContent: "space-between", // Added to create space between items
  padding: "12px",
  boxSizing: "border-box",
  textAlign: "center",
  fontSize: "var(--font-size-sm)",
  color: "var(--color-lightsteelblue)",
  fontFamily: "var(--default-font)",
  gap: "12px",
}); 