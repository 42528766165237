import { Box, CircularProgress } from "@mui/material";
import React from "react";
import AnimatedLoader from "../component/loader/Loader";

export const LoaderComponent = ({ loaderType = "" }) => {
	return (
		<>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='center'
				height='80vh'
				width='100%'>
				{loaderType === "animated" ? (
					<AnimatedLoader />
				) : (
					<CircularProgress style={{ color: "#a3491c" }} />
				)}
			</Box>
		</>
	);
};
