import { common } from "@mui/material/colors";
import areSpecificValuesFilled from "../checkpropertyfilled/checkPropertyFilled";

const isSaveAPICommonsFilled = (RoleCardDetails, commonInputs) => {
  const commonInputsColumns = [
    "CreatedBy",
    "LastUpdateBy",
    "ESTStartdate",
    "ESTEndDate",
  ];
  const commonValuesAreFilled = areSpecificValuesFilled(
    commonInputs,
    commonInputsColumns
  );
  return commonValuesAreFilled;
};

export const isSaveAPICommonsFilledFromPayload = (payload) => {
  const commonInputsColumns = [
    "created_by",
    "last_update_by",
    "est_start_date",
    "est_end_date",
  ];
  const commonValuesAreFilled = areSpecificValuesFilled(
    payload,
    commonInputsColumns
  );
  return commonValuesAreFilled;
};

export default isSaveAPICommonsFilled;
