import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  path: [],
};

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setPath: (state, action) => {
      state.path = [...action.payload];
    },
    resetPath: (state) => {
      state.path = initialState.path;
    },
  },
});

export const { setPath, resetPath } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
