export const lightColors = {
	black: {
		full: "#000000",
		light80: "#333333",
		light60: "#666666",
		light40: "#999999",
		light20: "#CCCCCC",
		light10: "#E6E6E6",
		light: "#F7F9FB",
	},
	white: {
		full: "#FFFFFF",
		light80: "#F2F2F2",
		light60: "#CCCCCC",
		light40: "#999999",
		light20: "#7F7F7F",
		light10: "#666666",
		light: "#666666",
	},
	primary: {
		blue: "#2196F3",
		orange: "#FF9800",
		blue50: "#E3F2FD",
		blue25: "#BBDEFB",
		light: "#B3E5FC",
		background: "#EFEFEF",
		blue100: "#5776e7",
		blueviolet100: "#314281",
	},
	secondary: {
		indigo: "#3F51B5",
		purple: "#9C27B0",
		cyan: "#00BCD4",
		blue: "#2196F3",
		green: "#4CAF50",
		mint: "#00E676",
		yellow: "#FFEB3B",
		orange: "#FF9800",
		red: "#F44336",
		darkviolet: "#704C9F",
		shade:"rgba(247, 249, 251, 1)"
	},
};

export const darkColors = {
	black: {
		full: "#FFFFFF",
		light80: "#E6E6E6",
		light60: "#CCCCCC",
		light40: "#999999",
		light20: "#7F7F7F",
		light10: "#666666",
		light: "rgba(28, 28, 28, 0.4)",
	},
	white: {
		full: "#000000",
		light80: "#333333",
		light60: "#666666",
		light40: "#999999",
		light20: "#CCCCCC",
		light10: "#E6E6E6",
	},
	primary: {
		blue: "#2196F3",
		orange: "#FF9800",
		purple: "#9C27B0",
		white5: "#F2F2F2",
		black100: "#000000",
		black80: "#333333",
	},
	secondary: {
		indigo: "#3F51B5",
		purple: "#9C27B0",
		cyan: "#00BCD4",
		blue: "#2196F3",
		green: "#4CAF50",
		mint: "#00E676",
		yellow: "#FFEB3B",
		orange: "#FF9800",
		red: "#F44336",
		darkviolet: "#704C9F",
		shade:"rgba(198, 199, 201, 1)"
	},
};
