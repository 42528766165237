import { DEALREVIEW } from "./apiConfig";

export const menuConfig = {
  OVERVIEW:1,
  SCOPED:2,
  INAP:3,
  DEALREVIEW:4,
  LEGAL:5,
  RM:6,
  ADMIN:7,
}

export const submenuConfig = {
  INAP:{
    DEFAULT: 0,
  },
  SCOPED:{
    DEFAULT: 0,
  },
  OVERVIEW: {
    DEFAULT: 0,
  },
  RM:{DASHBOARD: 101, PEOPLE: 102, PROJECTS: 103, RESOURCE: 104},
  ADMIN: {
    DEFAULT: 0,
  },
  DEALREVIEW: {
    DEFAULT: 0,
  },
  LEGAL: {
    DEFAULT: 0,
  },
}

export const menuActionsConfig = {
  INAP:{
    CREATE_INAP_PROJECT: "createINABDeal",
    REJECT_INAP_PROJECT: "INABDealReject",
    APPROVE_INAP_PROJECT: "INABDealApprove",
    SUBMIT_TO_RM_FROM_INAP: "submitToRMFromINAB",
  },
  SCOPED:{
    BEGIN_SCOPED_PROJECT: "beginScopedProject",
    SUBMIT_SCOPED_PROJECT: "submitScopedProject",
  },
}

export const actionNameConfig = {
  DEALREVIEW:{
    BEGIN_DEALREVIEW: "beginDealReview",
    RESTART_DEALREVIEW: "restartDealReview",
    ADD_ROLE_FOR_DEAL: "addRoleForDeal",
    UPDATE_ROLE_FOR_DEAL: "updateRoleForDeal",
    DELETE_ROLE_FOR_DEAL: "deleteRoleForDeal",
    UPDATE_DISCOUNT_AND_EXPENSES: "updateDiscountAndExpenses",
    DEAL_DATA_UPDATE: "dealDataUpdate",
    REVIEW_DEALREVIEW: "reviewDealReview",
    REJECT_DEALREVIEW: "rejectDealReview",
    APPROVE_DEALREVIEW: "approveDealReview",
    UPDATE_QUESTIONARY_VALUES: "updateQuestionaryValues",
    SEND_DEAL_TO_LEGAL: "sendDealToLegal",
  },
  SCOPED:{
    DEAL_NOTES_UPDATED: "dealNotesUpdated",
    BEGIN_SCOPED_PROJECT: "beginScopedProject",
    DEAL_RESOURCE_UPDATE: "dealResourceUpdate",
    SUBMIT_SCOPED_PROJECT: "submitScopedProject",
    DELETE_ROLE_FROM_SCOPED_RESOURCE: "deleteRoleFromScopedResource",
  },
  INAP:{
    CREATE_INAP_DEAL: "createINABDeal",
    INAP_DEAL_REJECT: "INABDealReject",
    INAP_DEAL_APPROVE: "INABDealApprove",
    SUBMIT_TO_RM_FROM_INAP: "submitToRMFromINAB",
  },
  LEGAL:{
    BEGIN_LEGAL_REVIEW: "beginLegalReview",
    INFO_UPDATE_IN_SOW: "infoUpdateInSow",
    GENERATE_SOW: "generateSow",
    SEND_TO_VALIDATION: "sendToValidation",
    SEND_TO_REVIEW:"sendToReview",
    CUSTOMER_REVIEWED: "customerReviewed",
    CUSTOMER_APPROVED: "customerApproved",
    CUSTOMER_REJECTED: "customerRejected",
    SEND_TO_RM: "sendToRM",
    RE_OPEN_LEGAL_REVIEW: "reOpenLegalReview",
    RE_OPEN_DEAL_REVIEW: "reOpenDealReview",
    DEAL_LOST:"dealLost",
  },
  RM:{
    UPDATE_DEAL_NOTE: "updateDealNote",
    BEGIN_RESOURCE_ALLOCATION: "beginResourceAllocation",
    UPDATE_RESOURCE_ALLOCATION: "updateResourceAllocation",
    BEGIN_REVIEW: "beginReview",
    SUBMIT_REVIEWED_RESOURCES: "submitReviewedResources",
    RESOURCE_REJECT: "resourceReject",
    RESOURCE_APPROVED: "resourceApproved",
    COMPLETE_RESOURCE_ALLOCATION: "completeResourceAllocation",
    ADD_EMPLOYEE: "addEmployee"
  },
};