// Function to generate a unique hash
export function generateHash() {
  return (
    "browser-" +
    Math.random().toString(36).substr(2, 16) +
    Date.now().toString(36)
  );
}

// Function to get a cookie by name
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

// Function to set a cookie
export function setCookie(name, value, days) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}


