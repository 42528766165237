import { createTheme } from "@mui/material/styles";
import "../globalStyles.css";

export const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#010817",
		},
		secondary: {
			main: "#2073ee",
		},
		text: {
			main: "#fff",
			heading: "#EE6C20",
			light: "#92A5ED",
			heading1: "#ffb082",
			link: "#708EFF",
		},
		buttonColor: {
			main: "#2646c0",
			status: "rgba(238, 108, 32,0.25)",
		},
		borderColor: {
			main: "#4363DA",
			light: "#243161",
			button: "#0071ce",
			dark: "rgba(112, 142, 255, 0.5)",
			status: "#EE6C20",
		},
		background: {
			main: "#010817",
			dark: "rgba(238, 108, 32, 0.25)",
			button: "#708eff",
			light: "rgba(112, 142, 255, 0.5)",
			blank: "#070d1a",
		},
	},
	typography: {
		fontSize: 16,
		fontFamily: `'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto' `,
		button: {
			textTransform: "none",
			fontFamily: `'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto' `,
		},
		body1: {
			fontSize: 16,
		},
		body2: {
			fontSize: 14,
		},
		body3: {
			fontFamily: `'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'`,
			fontSize: 12,
		},
		h4: {
			fontSize: 32,
		},
		h6: {
			fontSize: 18,
		},
	},
});

export const LightTheme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#fffff",
		},
		secondary: {
			main: "#2073ee",
		},
		text: {
			main: "#fff",
			heading: "#EE6C20",
			light: "#92A5ED",
			heading1: "#ffb082",
			link: "#708EFF",
		},
		buttonColor: {
			main: "#2646c0",
			status: "rgba(238, 108, 32,0.25)",
		},
		borderColor: {
			main: "#fffff",
			light: "#243161",
			button: "#0071ce",
			dark: "#fffff",
			status: "#EE6C20",
		},
		background: {
			main: "#010817",
			dark: "#fffff",
			button: "#708eff",
			light: "#fffff",
			blank: "#070d1a",
		},
	},
	typography: {
		fontSize: 16,
		fontFamily: `'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'`,
		button: {
			textTransform: "none",
			fontFamily: `'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'`,
		},
		body1: {
			fontSize: 16,
		},
		body2: {
			fontSize: 14,
		},
		body3: {
			fontFamily: `'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto'`,
			fontSize: 12,
		},
		h4: {
			fontSize: 32,
		},
		h6: {
			fontSize: 18,
		},
	},
});
