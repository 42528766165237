import React, { useEffect, useMemo, useState } from "react";
import {
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ShowHideColumnsButton
} from "material-react-table";
import { NoRecordsText, TableWrapper } from '../../globalStyles';
import { Box, createTheme, ThemeProvider, useTheme } from "@mui/material";
import SearchBar from "../dashboardcardsearch/Search";
import TableFilterModalChild from "./TableFilterModalChild";
import SecTitle from "../toptitlecomponent/SecTitle";
import { usePopuptableTheme } from "./TableStyles";
import { CustomButton } from "../Buttons/PopoverButton";
import { PermittedItems } from "../../utils/RbacUtils";
import { useSelector } from "react-redux";

//nested data is ok, see accessorKeys in ColumnDef below

const CustomTable = ({
  id,
  columns,
  data = [],
  title,
  filteredData,
  handlePaginationChange,
  inWhiteScreen = false,
  creatingRow = false,
  globalSearchValue = "",
  renderBottomToolbar = null,
  onCreatingRowCancel = () => {},
  onCreatingRowSave = () => {},
  onEditingRowSave = () => {},
  onEditingRowCancel = () => {},
  onRowSelectionChange,
  rowSelection,
  setPaginations,
  paginations = { pageIndex: 0, pageSize: 5 },
  enableTopToolbar = true,
  enableBottomToolbar = true,
  columnFilter,
  darkBackground = false,
  enableRowActions = false,
  enableRowSelection = false,
  enableSelectAll = true,
  enableMultiRowSelection = true,
  enableColumnFilters = true,
  enableEditing = false,
  renderRowActions = null,
  enableSorting = true,
  onClickRow = () => {},
  columnVisibility = {},
  handleSearchBar = false,
  handleCustomFilter = false,
  searchBarFiterProps={},
  searchBarOnChange,
  enablePagination = true,
  isRefetchingTodos = false,
  isLoadingTodos = false,
  onFullScreen,
  grouping = [],
  enableExpanding = false,
  positionActionsColumn = "last",
  handleDownloadClick = () => {},
  rowCount,
  manualPagination = false,
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const theme = useTheme();
  const popuptableTheme = usePopuptableTheme();
  useEffect(() => {
    if (!!columnFilter) {
      setColumnFilters(columnFilter);
    }
  }, [columnFilter]);

  const table = useMaterialReactTable({
    columns,
    data: data,
    columnResizeMode: "onEnd",
    enableColumnActions: false,
    enableSorting,
    enableColumnFilters,
    enableRowActions,
    enableTopToolbar,
    enableBottomToolbar,
    enableToolbarInternalActions: true,
    localization: { noRecordsToDisplay: <NoRecordsText>No Records to Display</NoRecordsText> ,rowsPerPage:'',hideAll:"Hide All",showAll:"Show All"},
    displayColumnDefOptions: {
      'mrt-row-actions':{
        header:'',
      },
      "mrt-row-numbers": {
        enableResizing: true,
        size: 150,
        grow: true,
      },
      "mrt-row-expand":{
        size: 50,
      },
    },
    enableRowNumbers: false,
    layoutMode: "grid",
    onColumnFiltersChange: setColumnFilters,
    enableFacetedValues: true,
    enableFilterMatchHighlighting: false,
    state: {
      pagination: paginations,
      columnFilters,
      density: "compact",
      globalFilter: globalSearchValue,
      isLoading: isLoadingTodos, //cell skeletons and loading overlay
      showProgressBars: isRefetchingTodos, //progress bars while refetching
      rowSelection: rowSelection ?? {},
    },
    initialState: {
      columnSizing: { maxSize: 500 },
      columnVisibility,
      grouping,
      expanded:true,
    },
    //pagination
    onPaginationChange: setPaginations,
    //table color change
    muiTopToolbarProps: {
      sx: {
        bgcolor: darkBackground
          ? theme.palette.background.secondary
          : theme.palette.background.primary,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        ...theme.typography.h5,
        whiteSpace: 'normal',   // Allows text to wrap normally
        wordBreak: 'break-word', // Breaks long words
        overflowWrap: 'break-word', // Ensures words break to fit container
        color: theme.palette.text.secondary,
      },
    }, 
    muiTableHeadRowProps: {
      sx: {
        whiteSpace: 'normal',   // Allows text to wrap normally
        wordBreak: 'break-word', // Breaks long words
        overflowWrap: 'break-word', // Ensures words break to fit container
        bgcolor: darkBackground
          ? theme.palette.background.secondary
          : theme.palette.background.primary,
      },
    },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        fontWeight: 400,
        bgcolor: darkBackground
          ? theme.palette.background.secondary
          : theme.palette.background.primary,
      },
      onClick: onClickRow,
    },
    muiTableBodyCellProps: {
      sx: { padding: "8px", fontWeight: 400, textWrap: 'wrap' },
    },
    muiBottomToolbarProps: {
      sx: {
        bgcolor: darkBackground
          ? theme.palette.background.secondary
          : theme.palette.background.primary,
      },
    },

    //action column creation
    positionActionsColumn:positionActionsColumn,
    renderRowActions: renderRowActions,
    renderTopToolbar: ({ table }) => {
      filteredData && filteredData(table);
      return (
        <Box
          sx={{
            bgcolor: darkBackground
          ? theme.palette.background.secondary
          : theme.palette.background.primary,
            gap: "0.5rem",
          }}
        >
          <SecTitle
            titlevariant="h5"
            title={title}
            rightComponent={
              <Box
                sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}
              >
                {/* import MRT sub-components */}
                <MRT_GlobalFilterTextField table={table} />
                {enableSorting && <MRT_ToggleFiltersButton table={table} />}
                <MRT_ToggleFullScreenButton id="fullScreen" table={table} onClick={()=>onFullScreen(table)}/>
              </Box>
            }
          />
        </Box>
      );
    },
    //custom pagination setup
    paginationDisplayMode: "pages",
    muiTablePaperProps: ({ table }) => ({
      style: {
        margin: table.getState().isFullScreen ? "0 0 0 16%" : undefined,
        width: table.getState().isFullScreen ? "100%" : undefined,
        maxWidth: table.getState().isFullScreen ? "84%" : undefined,
        padding: table.getState().isFullScreen ? "2rem 2rem" : undefined,
        backgroundColor:table.getState().isFullScreen ? theme.palette.background.shade:undefined
      },
    }),
    muiSelectCheckboxProps:({ row,staticRowIndex,table }) => ({
      sx:{
        display: row.depth === 0 ? "block" : "none",
      }
    }),
    muiPaginationProps: {
      color: "primary",
      className: "page",
      showRowsPerPage: false,
      showFirstButton: false,
      showLastButton: false,
      variant: "outlined",
      sx: { boxShadow: "none" },
    },
    //create new row
    positionCreatingRow: "bottom",
    editDisplayMode: "row",
    createDisplayMode: "row",
    enableEditing: enableEditing,
    rowCount: rowCount ? rowCount : data.length,
    enableCellActions: true,
    enablePagination: enablePagination,
    renderBottomToolbar,
    onCreatingRowSave: onCreatingRowSave,
    onCreatingRowCancel: onCreatingRowCancel,
    onEditingRowSave: onEditingRowSave,
    onRowSelectionChange: onRowSelectionChange,
    enableRowSelection: enableRowSelection,
    enableMultiRowSelection: enableMultiRowSelection,
    enableSelectAll: enableSelectAll,
    enableGrouping: true,
    enableSubRowSelection: false,
    enableExpanding,
    groupedColumnMode: false,
    enableColumnDragging: false,
    manualPagination:manualPagination,
  });
  const permission = useSelector((state) => state?.permission)

  const hasAnyPermittedCard = PermittedItems({ data: [{ id: id }], component: "table", dataKey: "id", permission});

  return (
    hasAnyPermittedCard.length > 0 &&
    <TableWrapper id={id} style={{ maxWidth: "100%", overflowX: "auto", width: "100%" }}>
      {handleSearchBar && (
        <SearchBar
          ShowHideComponent={
            <ThemeProvider theme={popuptableTheme}>
            <MRT_ShowHideColumnsButton id="show-hide" table={table}/>
            <CustomButton id="download-button" onClick={()=>handleDownloadClick(table)}>Download</CustomButton>
            </ThemeProvider>
          }
          FilterComponent={
            <TableFilterModalChild
             {...searchBarFiterProps}
            />
          }
          onChange={searchBarOnChange}
        />
      )}
      <MaterialReactTable table={table} />
    </TableWrapper>
  );
};

export default React.memo(CustomTable);
