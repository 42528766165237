import { createTheme } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { useMemo } from "react";

export const usePopuptableTheme = () => {
  const theme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        components: {
          MuiSwitch: {
            styleOverrides: {
              root: {
                width: 32,
                height: 20,
                padding: 0,
                marginRight: 10,
              },
              switchBase: {
                padding: 2,
                margin: 0,
                transitionDuration: "300ms",
                "&.Mui-checked": {
                  transform: "translateX(12px)",
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#fffff", // Active thumb color when checked
                  },
                  "& + .MuiSwitch-track": {
                    backgroundColor: "rgba(87, 118, 231, 1)", // Active track color when checked
                    opacity: 1,
                  },
                  "&.Mui-disabled + .MuiSwitch-track": {
                    backgroundColor: "rgba(87, 118, 231, 1)", // Active track color when checked and disabled
                    opacity: 1,
                  },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                  color: "#ffffff",
                  border: "6px solid #fff",
                },
                "&.Mui-disabled .MuiSwitch-thumb": {
                  backgroundColor: theme.palette.grey[100],
                },
              },
              thumb: {
                boxSizing: "border-box",
                width: 16,
                height: 16,
                color: "#ffffff",
              },
              track: {
                borderRadius: 10,
                backgroundColor: "lightgray", // Default track color
                transition: theme.transitions.create(["background-color"], {
                  duration: 500,
                }),
                "&.Mui-disabled": {
                  backgroundColor: "rgba(87, 118, 231)", // Active track color when disabled
                  opacity: 0.5,
                },
                "&.Mui-checked": {
                  backgroundColor: "rgba(87, 118, 231, 1)", // Active track color when checked
                  opacity: 1,
                },
              },
            },
          },

          MuiPopover: {
            styleOverrides: {
              paper: {
                width: "fit-content !important",
                height: "60%",
                borderRadius: "4 !important",
                border: "none !important",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1) !important",
                "&:focus": {
                  outline: "none !important",
                },
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                // backgroundColor: ' !important', // Set background color to red
                borderRadius: "1rem !important", // Round corners
                padding: ".5rem !important", // Add padding
                minWidth: "90px !important", // Adjusted to a more reasonable value
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              root: {
                ...theme.typography.body2,
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                padding: "6px 16px",
                borderRadius: "5px",
                textTransform: "none",
                minWidth: "90px",
                fontWeight: "580",
                "&:disabled": {
                  cursor: 'not-allowed',
                },
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "rgba(87, 118, 231)",
                  color: "#ffffff",
                },
              },
            },
          }
        },
      }),
    []
  );
  return tableTheme;
};
