const siteConfig = {
    "sow.generated": "Sow generated successfully",
    "sow.generation.failed": "Sow generation failed",
    "sow.upload.failed": "File upload failed",
    "legalQuestionnaire.title": "Legal Questionnaire - Deal Review for",
    "common.dates": "DATES",
    "label.sowEffectiveDate": "SOW effective date",
    "label.msrEffectiveDate": "MSR effective date",
    "additionalInformation.title": "ADDITIONAL INFORMATION",
	"additionalInformation.agileMethodology":
		"Will the project follow agile methodology ?",
	"additionalInformation.supportActivities":
		"Is there any support related activities/SLA’s ?",
    "additionalInformation.cappedSow": "Is this a capped SOW ?",
    "descriptionOfServices.title": "DESCRIPTION OF SERVICES",
    "descriptionOfServices.deliverablesSet": "Do we have the deliverables set?",
	"descriptionOfServices.genericDeliverables":
		"Do we want to set the generic deliverables content?",
    "descriptionOfServices.serviceDescription": "service Description?",
    "assumptions.title": "ASSUMPTIONS",
    "clientResponsibilities.title": "CLIENT RESPONSIBILITIES",
    "submitButton.label": "Submit for Legal",
    "common.new": "New",
    "common.inprogress": "In-Progress",
    "common.inreview": "Review",
    "common.reject": "Reject",
    "common.approved": "Approved",
    "common.completed": "Completed",
    "title.progress": "IN-PROGRESS",
    "title.inreview": "REVIEW",
    "generic.error.snackbar": "Error: Something went wrong",
    "generic.dberror.snackbar": "Error in fetching results from database",
    "deal.status.update": "Deal Status Updated",
    "file.upload": "File Uploaded Successfully",

    // Admin Dashboard
    "card.title.newdeal": "New Deals",
    "card.title.inprogress": "In Progress",
    "card.title.closedwin": "Closed Win",
    "card.title.closedlost": "Closed Lost",
    "card.title.dealreview": "Deal Review",
    "card.title.legal": "Legal",
    "card.title.finance": "Finance",
    "card.title.rm": "RM",
    "card.title.customer": "Customer",

    "title.dealsbystatus": "DEALS BY STATUS",
    "title.newdealsubmission": "NEW DEAL SUBMISSIONS",
    "title.recentupdates": "RECENT UPDATES",

    "message.dashboard.new": "There are no new deals",
    "message.dashboard.recentupdates": "There are no recent updates",

    // DR filter table
    "dr.label.viewdetails": "View Details",
    "dr.label.alldealreviews": "All Deal Reviews",

    // DR
    "savedeal.success": "Successfully saved your deal.",
    "savelegal.success": "Successfully submit to legal",
    "save.success": "Successfully saved your deal.",

    "dr.button.backtoediting": "Back to Editing",
    "dr.button.staffing": "Staffing",
    "dr.button.overview": "Overview",

    // DR Section Title
    "section.title.overview": "OVERVIEW",
    "section.title.summary": "Summary",
    "section.title.newDealsReview": "NEW DEALS REVIEW",
    "section.title.InProgressDealReview": "IN PROGRESS DEAL REVIEW",
    "section.title.review": "REVIEW",

    // RM Setion Title
	"section.title.projectinpipeline":
		"Projects in Pipeline - Resource Allocation Requests",
    "section.title.allproject": "All Projects",

    "section.title.viewall": "VIEW ALL",

    "welcome.message": "Welcome to Wavicle HUB",
    "greeting.message": "Hi",

    // Dr Card labels
    "card.title.newDR": "New DR’s",
    "card.title.inProgressDR": "In Progress DR’s",
    "card.title.inReview": "In Review",
    "card.title.newDR": "New DR’s",

    // Section Title
    "sectionTitle.legalSOW": "Legal SOW for project",
    "sectionTitle.owner": "Owner",

    "label.selectFiles": "Select Files to Upload",
    "label.dragDrop": "or Drag and Drop your files here",
    //Dashboard headers
    "title.legal.new": "NEW LEGAL REVIEW",
    "title.legal.progress": "IN PROGRESS",
    "title.legal.review": "REVIEW",
    "title.legal.history": "HISTORY",
    "title.details": "DETAILS",
    "title.deal_review_summary": "DEAL REVIEW SUMMARY",
    "title.life_cycle_phase": "Life Cycle Phase",
    "title.pricing_type": "Pricing Type",
    "title.service_categories": "Service Categories",
    "title.reviewSOW": "Review and Finalize SOW for ",
    "title.generatedSOW": "Generated SOW",
    "title.uploadedSOW": "Uploaded SOW",

    // Button Texts
    "button.new": "New",
    "button.inProgress": "In Progress",
    "button.review": "Review",
    "button.beginLegalReview": "Begin Legal Review",
    "button.proceed": "Proceed",
    "button.viewStaffing": "View Staffing",
    "button.viewAll": "View All",
    "button.generate.sow": "Generate SOW",
    "button.upload.sow": "Upload SOW",
    "button.documentUpload": "Upload Document",
    "button.documentView": "View Document",
    "button.upload": "Upload",
    "button.retry": "Retry",

    //messages
    "message.legal.new": "No new legal reviews.",
    "message.legal.inprogess": "No reviews in progress.",
    "message.legal.review": "No reviews under review.",
    "message.legal.history": "No review history available.",

    "message.dr.new": "There are no new deals review",
    "message.dr.inprogess": "There are no in progress deals review",
    "message.dr.review": "There are no deals review",
    "message.dr.history": "There are no history",

    "message.loading": "Loading...",
    "message.error": "Error: Something went wrong",
    "message.success": "Uploaded successfully",
    "message.cancel": "Upload canceled by user",
    // Details Section Labels
    "details.title": "DETAILS",
    "details.dealDescriptions": "Deal Descriptions",
    "details.technology": "Technology",
    "details.dealReviewSummary": "DEAL REVIEW SUMMARY",
    "details.lifeCyclePhase": "Life Cycle Phase",
    "details.pricingType": "Pricing Type",
    "details.serviceCategories": "Service Categories",
    "details.marginAndCost": "MARGIN AND COST",
    "details.grossMargin": "Gross Margin",
    "details.totalActualRevenue": "Total Actual Revenue",
    "details.finalGrossMargin": "Final Gross Margin",

    // Table Headers
    "table.total": "Total",
    "table.travelExpenses": "Travel Expenses",
    "table.feesAndExpenses": "Fees and Expenses",

    // Card Labels
    "card.marginAndCost": "MARGIN AND COST",
    "card.travelExpenses": "Travel Expenses",
    "card.feesAndExpenses": "Fees and Expenses",
    "card.total": "Total",
    "card.projectDetails": "Project Details",
    "card.estimatedStartDate": "Estimated Start Date",
    "card.estimatedEndDate": "Estimated End Date",
  'card.rateCardDefault': 'Rate Card Default',

    // HubSpot Deal Details
    "hubspotDeal.hubSpotDealID": "HubSpot Deal ID",
    "hubspotDeal.projectID": "Project ID",
    "hubspotDeal.dealName": "Deal Name",
    "hubspotDeal.dealOrigin": "Deal Origin",
    "hubspotDeal.dealStage": "Deal Stage",
    "hubspotDeal.contractType": "Contract Type",
    "hubspotDeal.buyingProcess": "Buying Process",
    "hubspotDeal.estimatedCloseDate": "Estimated Close Date",
    "hubspotDeal.competencyLead": "Competency Lead",
    "hubspotDeal.practiceLead": "Practice Lead",
    "hubspotDeal.salesLead": "Sales Lead",
    "hubspotDeal.engagementManager": "Engagement Manager",
    "hubspotDeal.msaCompleted": "MSA Completed",
    "hubspotDeal.forecastProbability": "Forecast Probability",
    "hubspotDeal.forecastCategory": "Forecast Category",

    // Deal Review Details
    "dealReview.clientName": "Client Name",
    "dealReview.projectName": "Project Name",
    "dealReview.proposedStartDate": "Proposed Start Date",
    "dealReview.proposedEndDate": "Proposed End Date",
    "dealReview.actualStartDate": "Actual Start Date",
    "dealReview.duration": "Duration (In Weeks)",
    "dealReview.proposedCloseDate": "Proposed Close Date",
    "dealReview.actualEndDate": "Actual End Date",
    "dealReview.peakHeadcount": "Peak Headcount",
    "dealReview.percentOnshore": "% Onshore",
    "dealReview.percentOffshore": "% Offshore",
    "dealReview.msaComplete": "MSA Complete",

    // Margin and Cost Details
    "marginCost.currentMargin": "Current Margin",
    "marginCost.expectedMargin": "Expected Margin",
    "marginCost.monthlyPayment": "Monthly Payment",
    "marginCost.totalWavicleCost": "Total Wavicle Cost",
    "marginCost.onsiteStaffPercent": "Onsite Staff %",
    "marginCost.offshoreStaffPercent": "Offshore Staff %",
    "marginCost.plExecUtilization": "PL/Exec Utilization",
    "marginCost.clUtilization": "CL Utilization",
    "marginCost.dealRevenueBeforeDeductions": "Deal Revenue Before Deductions",
    "marginCost.dealRevenueAfterDeductions": "Deal Revenue After Deductions",
    "marginCost.2InABoxEM": "2-In-A-Box (EM)",
    "marginCost.2InABoxDL": "2-In-A-Box (DL)",

    // Calculation Details
    "calcDetails.totalFixedPriceRevenue": "Total Fixed Price Revenue",
    "calcDetails.totalClientPrice": "Total Client Price",
    "calcDetails.totalHoursProposed": "Total Hours Proposed",

    // Travel Expenses
    "expenses.airfares": "Airfares",
    "expenses.hotel": "Hotel",
    "expenses.groundTransport": "Ground Transport",
    "expenses.meal": "Meal",
    "expenses.other": "Other",

    // Fees and Expenses
    "fees.fees": "Fees",
    "fees.solutionsArch": "Solutions Arch",
    "fees.legal": "Legal",
    "fees.travel": "Travel",
    "fees.capabilityLeads": "Capability Leads",
    "fees.staffing": "Staffing",
    "fees.risksAssumptions": "Risks & Assumptions",
    "fees.other": "Other",
    "fees.expenses": "Expenses",

    // Additional Details
    "additional.generatedSOWPath": "Generated SOW Path",
    "additional.technology": "Technology",
    "additional.servicesIncluded": "Services Included",
    "additional.lifecycle": "Lifecycle",
    "additional.pricingTypeName": "Pricing Type Name",
    "additional.totalDealExpenses": "Total Deal Expenses",
    "additional.finalGrossRevenue": "Final Gross Revenue",
    "additional.totalActualRevenue": "Total Actual Revenue",
    "additional.dealOwner": "Deal Owner",

    //format date
    "new-date.format": "MM-DD-YYYY",
    "date.format": "YYYY-MM-DD",
    "project.date.format": "DD MMM, YYYY",
    "staffing.date.format": "MMM DD, YYYY",

    //questionnaire
    "generic.content":
        "Client shall have five (5) business days from the date of delivery (the “Review Period”) of anything provided as a result of the Services (“Deliverable”) to determine whether such Deliverable complies with Client requirements. Client will notify Wavicle in writing during the Review Period if any Deliverable requires modification, with such notice setting forth with particularity the changes required for such Deliverable.  If Client notifies Wavicle of such issues during the Review Period, Wavicle shall address such issues within a commercially reasonable time of such notice and resubmit such modified Deliverable for Client to re-review in accordance with this paragraph. Wavicle shall not be responsible for any delays resulting from Client not providing timely feedback on Deliverables. Client acknowledges and agrees that payment of fees is not conditioned upon acceptance and/or completion of any documents, code, or other materials that the Services above are intended to support (“Deliverables”), and modification or revision of all Deliverables will be at the rates set forth below.",

    //admin dashboard summary cards
    "summary.scoped": "Scoped",
    "summary.inproposals": "In Proposal",
    "summary.dealreview": "Deal Review",
    "summary.legal": "Legal Review",
    "summary.rm": "Resource Management",
    "summary.finance": "Finance",

    // admin tabs
    "tab.admin.users": "Users",
    "tab.admin.groups": "Groups",
    "tab.admin.roles": "Roles",

    //admin dashboard tabs
    //NOTE: These are used to navigation also so keep them safely.
    "tab.scopedprojects": "Scoped Projects",
    "tab.proposal": "Project in Proposal",
    "tab.dealreview": "Deal Review",
    "tab.legal": "Legal",
    "tab.rm": "Resource Management",
    "tab.finance": "Finance",
    "tab.history": "History",

    //dashboard
    "dashboard.clientname": "Client:",
    "dashboard.dealowner": "Deal Owner",
    "dashboard.owner": "Owner",
    "dashboard.createddate": "Created Date",
	"dashboard.preliminarystatus":"Preliminary Staffing",
    "dashboard.em": "Engagement Manager",
	"dashboard.subdate":"Submitted on",

    //dashboard for INAB
    "INAB.summary.new": "New Submissions",
    "INAB.summary.inprogress": "Projects In-Flight",
    "INAB.summary.completed": "Projects Completed",

    //tab details for deal and legals
    // NOTE: These are used to navigation also so keep them safely.
    "tab.new": "New",
    "tab.inprogress": "In Progress",
    "tab.reopen": "Re-Open",
    "tab.legpending": "Legal Pending",
    "tab.approved": "Approved",
    "tab.inreview": "In Review",
    "tab.completed": "Completed",
    "tab.validation": "Validation",
    "tab.overview": "Overview",
    "tab.staffing": "Staffing",
    "tab.staffingintake": "Staffing Intake",
    "tab.review": "Review",
    "tab.resources": "Resources",
    "tab.MarginsAndStaffing": "Margins & Staffing",
    "tab.staffingIntakerm": "Staffing Intake - RM",
    // RM tabs labels
    "INAB.tab.new": "New",
    "INAB.tab.projectsflight": "Projects In-Flight",
    "INAB.tab.approved": "Approved",
    "INAB.tab.completed": "Completed",
    "INAB.tab.cancelled": "Cancelled/Rejected",

    //dashboard for deal and legals
    "summary.new": "New",
    "summary.inprogress": "In Progress",
    "summary.inreview": "In Review",
    "summary.completed": "Complete",
    "summary.validation": "Validation",

    //tab details for deal and legals
    "tab.new": "New",
    "tab.inprogress": "In Progress",
    "tab.reopen": "Re-Open",
    "tab.legpending": "Legal Pending",
    "tab.approved": "Approved",
    "tab.inreview": "In Review",
    "tab.completed": "Completed",
    "tab.validation": "Validation",
    "tab.overview": "Overview",
    "tab.resources": "Resources",

    // RM tabs labels
    "tab.allresources": "All Resources",
    "tab.Mentors/ad's": "Mentors/AD’s",
    "tab.inprojects": "In Projects",
    "tab.inbench": "In Bench",
    "tab.approachingbench": "Approaching Bench",
    "tab.fulltime": "Full-Time",
    "tab.contractors": "Contractors",
    "tab.leadership": "Leadership",
    "tab.indiapeople": "India",
    "tab.uspeople": "US",
    "tab.canadapeople": "Canada",

    // RM Project Dashboard
    "rm.project.allprojects": "All Projects", //
    "rm.project.activeprojects": "Active Projects",
    "rm.project.completedprojects": "Completed Projects",
    "rm.project.wavicleprojects": "Wavicle Projects",
    "rm.project.complete": "Complete",
    

    // RM Resource Allocation Dashboard
    "rm.resourceallocation.new": "New",
    "rm.resourceallocation.inprogress": "In Progress",
    "rm.resourceallocation.reopen": "Re-Open",
    "rm.resourceallocation.inreview": "Review",
    "rm.resourceallocation.completed": "Complete",
    "rm.resourceallocation.demandpipeline": "Demand Pipeline",
    
    //Overview Preliminary
    "overview.noteforresourcemanagement": "Note for Resource Management",
    //fields
    project_name: "Project Name",
    company_name: "Client Name",
    hub_status: "Hub Status",
    buying_process: "Buying Process",
    competency_lead: "Competency Lead",
    contract_type: "Contract Type",
    deal_description: "Deal Description",
    deal_origin: "Deal Origin",
    deal_owner: "Deal Owner",
    deal_review_complete: "Deal Review Complete",
    deal_review_id: "Deal Review Id",
    deal_stage: "Deal Stage",
    engagement_manager: "Engagement Manager",
    est_close_date: "Est.Close Date",
    forecast_category: "Forecast Category",
    hs_deal_id: "Hs Deal Id",
    msa_complete: "MSA Completed",
    notes: "Notes",
    preliminary_staffing_notes: "Preliminary Staffing Notes - RM",
    pmo_acceptance: "PMO Acceptance",
    practice_lead: "Practice Lead",
    prem_staffing_submitted: "Premliminary Staffing",
    project_id_var: "Project Id",
    sales_lead: "Sales Lead",
    services_included: "Services Categories",
    staffing_submitted: "Staffing",
    life_cycle: "Lifecycle",
    technology: "Technology",
    updated_at: "Date",
    status: "Status",
    result: "Result",
    addtional_information: "Additional Information",
    customer_acceptance: "Customer Acceptance",
    pricing_type: "Pricing Type",

    //legal fields

    projectname: "Project Name",
    companyname: "Client Name",
    hubstatus: "Hub Status",
    buyingprocess: "Buying Process",
    competencylead: "Competency Lead",
    contracttype: "Contract Type",
    dealdescription: "Deal Description",
    dealorigin: "Deal Origin",
    dealowner: "Deal Owner",
    dealreviewcomplete: "Deal Review Complete",
    dealreviewid: "Deal Review Id",
    dealstage: "Deal Stage",
    engagementmanager: "Engagement Manager",
    estclosedate: "Est.Close Date",
    forecastcategory: "Forecast Category",
    hsdealid: "Hs Deal Id",
    msacomplete: "MSA Completed",
    pmoacceptance: "PMO Acceptance",
    practicelead: "Practice Lead",
    premstaffingsubmitted: "Premliminary Staffing",
    projectidvar: "Project Id",
    saleslead: "Sales Lead",
    servicesincluded: "Services Categories",
    staffingsubmitted: "Staffing",
    updatedat: "Date",

    //Generate SOW page
    "sow.leftContainer.header": "Automated SOW Generation",
    "sow.leftContainer.body": `This is a quick and easy way to generate the initial version of SOW. The
  SOW is generated automatically based on the information provided by deal
  review. It may not have all the required details. Kindly download the
  generated SOW and make changes as needed. You can re-upload them again
  here.`,
  'tab-sow-info': 'SOW Info',
  'sow-note-from-dealreview': 'Below are the information received from deal review team. If required, modifications can be made',

    //Template Selection
    "template.header": "Select A SOW Template",
    "button.content": "Generate SOW",

    //button contents
    "button.sow.generate": "Generate SOW",
    "button.sow.download": "Download SOW",
    "button.sow.view": "View SOW",

    //snackbar messages

    "snackbar.severity.success": "success",
    "snackbar.severity.error": "error",

    //tab header
    "sow.generate": "Generated SOW",
    "sow.generate.content":
        "This is the Auto Generated SOW. If you had downloaded the generated SOW and made any changes, those will not be here. Please choose the other option.",
    "sow.upload": "Upload SOW",
    "sow.upload.content":
        "Choose this option to upload any custom SOW’s or the auto generated SOW with the manual updates. This will be considered the final SOW. The auto generated SOW will be saved for future purpose.",

    //Status codes
    "status.dr.new": "DR_NEW",
    "status.dr.inprogress": "DR_INPRG",
    "status.dr.inreview": "DR_INREV",
    "status.leg.new": "LEG_NEW",
    "status.leg.inprogress": "LEG_INPRG",
    "status.leg.inreview": "LEG_INREV",

    //deal stage
    "deal.stage.won": "closed won",
    "deal.stage.lost": "closed lost",

    //Validation pop up
    "popup.header":
        "You are about to move the status of the deal to the next status in Legal. You can no longer make any changes to this deal - Unless the deal comes back to the inital state.",
    "popup.first": "The deal review has been reviewed and final number are set",
    "popup.second": "Please review the process before proceeding and confirm.",
    "dealReview.submitForReview":
        "You are about to submit this deal review for final approval. Please ensure all information is accurate and complete.",
	"dealReview.onReject":
		"You are rejecting the deal review for {deal name}. Please provide your review comments.",
    "dealReview.onApprove":
        "You are approving the deal review for {deal name}. Please ensure all information is accurate and complete. An approved deal review cannot be modified.",
    "dealReview.sendToLegal":
        "Are you sure you want to submit this deal review for legal preparation? Before submitting, ensure all required information for the legal process is complete. This action will mark the deal review process as complete for {dealname}.",
	"legalReview.legalSOW":
		"Are you sure that the SOW is finalized and no changes are needed?",
	"legalReview.approved":
		"Has the SOW been reviewed and signed by the customer?",
    "legalReview.initiateResourceManagement":
        "Are you sure you want to initiate the resource allocation process for this deal? This action will mark the legal process as complete for {dealname}.",
    "legalReview.rejected":
        "It appears the SOW was rejected by the customer. Please provide the reason/comments below and select the appropriate action.",
	"legalReview.dealLost":
		"We're sorry to hear that! Are you sure you want to close this deal and mark it as lost?",
    "legalReview.reopenDealReview":
        "Do you want to reopen the deal review for {deal name}? This action will reopen the deal review and notify the team. Please ensure all review comments are added before confirming.",
    "legalReview.reopenLegal":
        "Do you want to reopen the legal process for {deal name}? This action will reopen the legal process for this deal. Please ensure all review comments are added before confirming.",
    "resourceManagement.submitForReview":
        "You are about to submit this resource allocation sheet for final approval. Please ensure all information is accurate and complete.",
	"resourceManagement.approved":
		"1. Are you sure you want to approve the provided list of resources for {dealname}?",
    "resourceManagement.rejected":
        "2. You are rejecting the provided list of resources for {deal name}. Please provide your review comments.",


    //staffing intake
    "staffing.req.skills": "Required Skills",
    "staffing.skill.dropdown.placeholder": "--Select Skills--",
    "staffing.type.resource": "Type of Resource",
    "staffing.type.resource.placeholder": "Type",
    "staffing.rec.interview.panel.placeholder": "--Select Resources--",
    "staffing.role.req": "Role Requirements",
    "staffing.role.description": "Please provide as much information as possible",
  "staffing.role.req.placeholder":
    "Please provide as much information as possible",
    "staffing.client.interview": "Client Interview Required?",
    "staffing.client.interview.placeholder":
        "After initial Wavicle interviews, will candidates need to interview with the client? If yes, how many rounds of interviews?",
    "staffing.need.laptop": "Will a contractor need a Wavicle laptop?",
    "staffing.interview.panel": "Recommended Interview Panel",
    "staffing.client.managed": "Is this a Client Managed Project?",
    "staffing.small.description": "A small Description",
  'staffing.calculations': 'Calculations',
};
export default siteConfig;
