import { createSlice } from "@reduxjs/toolkit";
import { permissionMenu } from "../../utils/permission";

const initialState = {
    applicationPermission: permissionMenu,
    menuPermission: {},
    pagePermission: {},
};

export const permissionSlice = createSlice({
    name: "permission",
    initialState,
    reducers: {
        setCurrentPagePermission: (state, action) => {
            state.pagePermission = action.payload;
        },
        resetCurrentPagePermission: (state) => {
            state.pagePermission = initialState.pagePermission;
        },
        setApplicationPermission: (state, action) => {
            state.applicationPermission = action.payload;
        },
        resetApplicationPermission: (state, action) => {
            state.applicationPermission = initialState.applicationPermission;
        },
        setMenuPermission: (state, action) => {
            state.pagePermission = action.payload.permissions;
            state.menuPermission = action.payload;
        },
        resetMenuPermission: (state, action) => {
            state.pagePermission = initialState.pagePermission;
        },
    },
});

export const { setCurrentPagePermission, resetCurrentPagePermission, setApplicationPermission, resetApplicationPermission, setMenuPermission, resetMenuPermission } = permissionSlice.actions;

export default permissionSlice.reducer;
