import { Box, Stack, Typography, useTheme } from "@mui/material";
import "./SecTitle.css";
import { SecTitleDiv } from "./SecTitleStyles";

const SecTitle = ({
  title = "",
  subtitle = "",
  pt = 0,
  pb = 0,
  rightComponent = <></>,
  titleColor = "",
  titlevariant = "h5",
  subtitlevariant = "body3",
  showValidationMessage = false,
  message = null,
  required = false,
  style
}) => {
  const theme = useTheme();
  return (
    <SecTitleDiv
      style={{
        display: "flex",
        marginTop: pt,
        paddingBottom: pb,
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        ...style
      }}
    >
      <Box
        style={{
          display: "flex",
          gap: subtitle ? 10 : 0,
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ color: titleColor ? titleColor : theme.palette.text.primary }}
          variant={titlevariant}
          component="b"
        >
          {title}
          {required ? <span className='requiredstar'> *</span> : null}
          {showValidationMessage ? (
            <Typography
              sx={{
                color: "red",
                fontSize: "12px",
                fontWeight: 500,
				margin: '10px'
              }}
              variant={titlevariant}
              component="b"
            >
              {message}
            </Typography>
          ) : null}
        </Typography>

        <Box className="click-all-that">
          <Typography
            variant={subtitlevariant}
            color={theme.palette.text.main}
            component="p"
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      {rightComponent}
    </SecTitleDiv>
  );
};

export default SecTitle;
