import { Route, Routes, Navigate } from "react-router-dom";
import { privateRoutes } from "./PrivateRoutes";
import { useState } from "react";
import { useSelector } from "react-redux";
import { hasPermissions } from "../utils/RbacUtils";
import { Suspense, lazy } from "react";
import { LoaderComponent } from "./PrivateRoute";
import RouteChangeListener from "../utils/RouteChangeListener";

const LazyLoginForm = lazy(() => import("../screens/login/LoginForm"));
const LazyPermissionDenied = lazy(() => import("../screens/PermissionDenied"));
const LazySideDrawer = lazy(() => import("../component/drawer/sideDrawer"));

const AuthRouter = () => {
  const [isDraweropen, setIsDraweropen] = useState(true);
  const currentUserRoles = useSelector((state) => state.currentUser?.userRoles);
  const isAuthenticated = useSelector(
    (state) => state.currentUser?.isAuthenticated
  );

  return (
    <>
      <RouteChangeListener />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Suspense fallback={<LoaderComponent />}>
              <LazyLoginForm drawerController={setIsDraweropen} />
            </Suspense>
          }
        />
        <Route
          path="/permission-denied"
          element={
            <Suspense fallback={<LoaderComponent />}>
              <LazyPermissionDenied drawerController={setIsDraweropen} />
            </Suspense>
          }
        />
        <Route
          element={
            <Suspense fallback={<LoaderComponent />}>
              <LazySideDrawer />
            </Suspense>
          }
        >
          {privateRoutes.map(({ id, path, component: Component, access }) => (
            <Route
              key={id}
              path={path}
              element={
                currentUserRoles && isAuthenticated ? (
                  <>
                    {hasPermissions(access) ? (
                      <Suspense fallback={<LoaderComponent />}>
                        <Component drawerController={setIsDraweropen} />
                      </Suspense>
                    ) : (
                      <Navigate to="/permission-denied" replace />
                    )}
                  </>
                ) : (
                  <>
                    <Navigate to="/permission-denied" replace />
                  </>
                )
              }
            ></Route>
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default AuthRouter;
