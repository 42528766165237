import React from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import "./index.css";
import App from "./App";
import { msalConfig } from "./auth/AuthConfig";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { store } from "./redux/rootReducer";

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<MsalProvider instance={msalInstance}>
				<App />
			</MsalProvider>
		</Provider>
	</React.StrictMode>
);
