import { createTheme } from "@mui/material/styles";
import fonts from "./fonts";
import { darkColors, lightColors } from "./colors";

const darkTheme = createTheme({
	palette: {
		mode: "dark",
		common: {
			black: darkColors.black.full,
			white: darkColors.white.full,
		},
		primary: {
			main: darkColors.primary.blue,
			light: darkColors.white.full,
			dark: darkColors.black.full,
			contrastText: lightColors.black.full,
		},
		secondary: {
			main: lightColors.primary.blue,
		},
		background: {
			primary: darkColors.white.full,
			secondary: darkColors.black.light,
			light: darkColors.white.light40,
			shade: darkColors.white.light,
			popup:lightColors.black.full
		},
		borderColor: darkColors.black.light10,
		text: {
			primary: darkColors.black.full,
			secondary: darkColors.black.light40,
			colortext: lightColors.primary.blue,
			white: lightColors.white.full,
			black: lightColors.black.full,
			textviolet: darkColors.secondary.darkviolet,
		},
		button: {
			primary: lightColors.primary.blue100,
			disabled: lightColors.black.light60,
		},
		icon: {
			light: darkColors.white.full,
			dark: darkColors.black.full,
		},
	},
	typography: {
		fontFamily: fonts.families.inter,
		h1: {
			fontSize: fonts.sizes[64].regular.fontSize,
			fontWeight: fonts.sizes[64].regular.fontWeight,
			lineHeight: fonts.sizes[64].regular.lineHeight,
			textAlign: fonts.sizes[64].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		h2: {
			fontSize: fonts.sizes[48].semibold.fontSize,
			fontWeight: fonts.sizes[48].semibold.fontWeight,
			lineHeight: fonts.sizes[48].semibold.lineHeight,
			textAlign: fonts.sizes[48].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		h3: {
			fontSize: fonts.sizes[24].semibold.fontSize,
			fontWeight: fonts.sizes[24].semibold.fontWeight,
			lineHeight: fonts.sizes[24].semibold.lineHeight,
			textAlign: fonts.sizes[24].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		h4: {
			fontSize: fonts.sizes[18].semibold.fontSize,
			fontWeight: fonts.sizes[18].semibold.fontWeight,
			lineHeight: fonts.sizes[18].semibold.lineHeight,
			textAlign: fonts.sizes[18].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		h5: {
			fontSize: fonts.sizes[14].semibold.fontSize,
			fontWeight: fonts.sizes[14].semibold.fontWeight,
			lineHeight: fonts.sizes[14].semibold.lineHeight,
			textAlign: fonts.sizes[14].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		h6: {
			fontSize: fonts.sizes[12].semibold.fontSize,
			fontWeight: fonts.sizes[12].semibold.fontWeight,
			lineHeight: fonts.sizes[12].semibold.lineHeight,
			textAlign: fonts.sizes[12].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		body1: {
			fontSize: fonts.sizes[18].regular.fontSize,
			fontWeight: fonts.sizes[18].regular.fontWeight,
			lineHeight: fonts.sizes[18].regular.lineHeight,
			textAlign: fonts.sizes[18].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		body2: {
			fontSize: fonts.sizes[14].regular.fontSize,
			fontWeight: fonts.sizes[14].regular.fontWeight,
			lineHeight: fonts.sizes[14].regular.lineHeight,
			textAlign: fonts.sizes[14].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		body3: {
			fontSize: fonts.sizes[12].regular.fontSize,
			fontWeight: fonts.sizes[12].regular.fontWeight,
			lineHeight: fonts.sizes[12].regular.lineHeight,
			textAlign: fonts.sizes[12].regular.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		subtitle1: {
			fontSize: fonts.sizes[18].semibold.fontSize,
			fontWeight: fonts.sizes[18].semibold.fontWeight,
			lineHeight: fonts.sizes[18].semibold.lineHeight,
			textAlign: fonts.sizes[18].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
		subtitle2: {
			fontSize: fonts.sizes[14].semibold.fontSize,
			fontWeight: fonts.sizes[14].semibold.fontWeight,
			lineHeight: fonts.sizes[14].semibold.lineHeight,
			textAlign: fonts.sizes[14].semibold.textAlign,
			fontFamily: fonts.families.inter,
			color: darkColors.black.full,
		},
	},
	button: {
		primary: lightColors.primary.blue100,
	},
	
});

export default darkTheme;
