import React from "react";
import "./Loader.css"; // Assuming you save your CSS in a file named Loader.css

const AnimatedLoader = () => {
	return (
		<div className='loader'>
			<div className='bars'></div>
		</div>
	);
};

export default AnimatedLoader;
