import React from "react";
import { styled, useTheme } from "@mui/system";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import { PermittedItems } from "../../utils/RbacUtils";
import { useSelector } from "react-redux";

// Styled component for the floating container
export const FloatingContainer = styled("div")({
	width: "fitContent",
	height: "fitContent",
	padding: "20px 28px",
	borderRadius: "16px",
	border: "1px solid #1C1C1C1A",
	background: "#FFFFFFCC",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	gap: "4px",
	marginBottom: "2px", // Adjust as needed
});

// Styled component for the inner buttons
export const InnerButton = ({id, lighten = false, ...props}) => {
	const { sx, ...others } = props;
	const theme = useTheme();
	const permission = useSelector((state) => state?.permission)
	const hasAnyPermittedCard = PermittedItems({ data: [{ id: id }], component: "button", dataKey: "id", permission });
	return (
		hasAnyPermittedCard.length > 0 &&
		<Button
			{...others}
			sx={{
				textTransform: "none",
				width: "max-content",
				height: "28px", // Adjust as needed
				padding: "1rem",
				borderRadius: "8px",
				color: lighten ? 'black' : "#fff",
				backgroundColor: lighten ? theme.palette.background.secondary :  "rgba(87, 118, 231, 1)",
				'&:hover':{
					backgroundColor:lighten ? theme.palette.background.secondary :  "rgba(87, 118, 231, 1)"
				},
				...sx
			}}
		/>
	);
};

// Custom component to wrap children with FloatingContainer and apply InnerButton styles
export const FloatingButtonContainer = ({id, children, isVisible }) => {
	const permission = useSelector((state) => state?.permission)
	const hasAnyPermittedCard = PermittedItems({ data: [{ id: id }], component: "button", dataKey: "id", permission});
	return (
		hasAnyPermittedCard.length > 0 &&
		<React.Fragment>
			{/* Grid container for centering */}
			{isVisible && (
				<Grid
					container
					justifyContent='center'
					style={{
						position: "fixed",
						bottom: "20px",
						width: "max-content",
						right:'50%',
						left:'50%',
						// "translateX": "50%",
						zIndex: "999999",
					}}>
					<Slide direction='up' in={isVisible}>
						<Grid item>
							{children && <FloatingContainer>{children}</FloatingContainer>}
						</Grid>
					</Slide>
				</Grid>
			)}
		</React.Fragment>
	);
};

// const MyComponent = () => {
//     const [isVisible, setIsVisible] = useState(false);

//     // Function to handle click event of the button to toggle visibility
//     const handleToggleVisibility = () => {
//       setIsVisible((prev) => !prev);
//     };

//     return (
//       <div>
//         {/* Button to toggle visibility of the floating container */}
//         <Button onClick={handleToggleVisibility} variant="contained">
//           Toggle Floating Container
//         </Button>

//         {/* Usage of the FloatingButtonContainer */}
//         <FloatingButtonContainer isVisible={isVisible}>
//           <InnerButton variant='contained'>done</InnerButton>
//           <InnerButton variant='contained' color='info'>Cancel</InnerButton>
//         </FloatingButtonContainer>
//       </div>
//     );
//   };

//   export default MyComponent;
