// overviewSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES, apiGet } from "../../configure/apiConfig";

const initialState = {
	overviewData: null,
};

export const fetchOverviewData = createAsyncThunk(
	"overview/fetchOverviewData",
	async (projectId, thunkAPI) => {
		try {
			const response = await apiGet(API_ROUTES.GET_LEGAL_DEAL_REVIEW, {
				dealID: projectId,
			});

			if (response.status === 200) {
				return response.data.data[0] ? response.data.data[0] : [];
			} else {
				throw new Error("Failed to fetch overview data");
			}
		} catch (error) {
			throw new Error(error.message);
		}
	}
);

const overviewSlice = createSlice({
	name: "overview",
	initialState,
	reducers: {
		resetOverviewData: (state) => {
			state.overviewData = null;
		},
		updateOverviewData: (state, action) => {
			state.overviewData = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchOverviewData.fulfilled, (state, action) => {
			state.overviewData = action.payload;
		});
	},
});

export const { resetOverviewData, updateOverviewData } = overviewSlice.actions;

export const selectOverviewData = (state) => state.overview.overviewData;

export default overviewSlice.reducer;
