import React, { useEffect, useState } from "react";
import { PageWrapper } from "../../../globalStyles";
import OverviewTab from "./servicecomponents/Overview";
import { useDispatch } from "react-redux";
import { API_ROUTES, apiGet, apiPost } from "../../../configure/apiConfig";
import { useLocation, useParams } from "react-router-dom";
import { LoaderComponent } from "../../../auth/PrivateRoute";
import { DASHBOARD_PATH, getParentPath, PageConfig, RM_PATH } from "../../../configure/PathConfig";
import { setPath } from "../../../redux/slices/BreadCrumbsSlice";
import { actionNameConfig } from "../../../configure/menuAndSubmenuConfig";
import siteConfig from "../../../common/lang/locale/en-US";
import { formatString } from "../../../utils/dataformatter/Rawformatter";

export const saveData = async (saveDetails) => {
	try {
		const response = await apiPost(
			API_ROUTES.SAVE_ADDITIONAL_INFO,
			saveDetails,
			null,
			{ "Socket-Action" : actionNameConfig.SCOPED.DEAL_NOTES_UPDATED },
		);
		return response
	} catch (error) {
    console.error("Error saving data:", error);
    return error
	}
};

const PreliminaryStaffingOverview = () => {
	const { projectid } = useParams();
	const pathname = useLocation().pathname;
	const isRm = pathname.includes(RM_PATH);
	const [isLoading, setIsLoading] = useState(true);
	const [overviewDetails, setOverviewDetails] = useState([]);

	const dispatch = useDispatch();

	const projectName = overviewDetails?.[0]?.project_name || "Project Name";

  	const parentPath = getParentPath()

	const url = parentPath === DASHBOARD_PATH ? parentPath+"/"+formatString(siteConfig["tab.scopedprojects"]) : parentPath === RM_PATH? PageConfig["RM"].PROJECTHOME+"/"+formatString(siteConfig["rm.resourceallocation.completed"]) :  parentPath;

	const path = [
		{
			label: "Dashboard",
			url,
		},
		{ label: "Scoped", url },
		{ label: `${projectName}`, url: "" },
	];

	const getData = async (projectid) => {
		try {
			setIsLoading(true);
			const response = await apiGet(API_ROUTES.GET_NEW_DR_DETAILS_FROM_DB, {
				project_id: projectid,
			});

			if (response.status === 200) {
				setOverviewDetails(response?.data[0] || []);
			}

			setIsLoading(false);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getData(projectid);
	}, [projectid]);

	useEffect(() => {
		dispatch(setPath(path));
	}, [overviewDetails?.[0]?.project_name]);

	return (
		<>
			{isLoading ? (
				<LoaderComponent />
			) : (
				<PageWrapper>
					<OverviewTab overviewDetails={overviewDetails} isRm={isRm}/>
				</PageWrapper>
			)}
		</>
	);
};

export default PreliminaryStaffingOverview;
