import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  InputBase,
  IconButton,
  Container,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterList";
import FilterModal from "../modal/FilterModal";

// Styled components for customizing Material-UI components
const StyledContainer = styled(Container)(({ theme, withoutInput }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: withoutInput ? "flex-end" : "space-between",
  padding: 9,
  gap: 16,
  borderRadius: 8,
  marginBlock: 16,
  background: theme.palette.background.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "389px",
  height: "28px",
  padding: "4px 8px",
  borderRadius: 8,
  background: theme.palette.background.primary,
  border: `1px solid ${theme.palette.borderColor}`,
  fontSize: 14,
  // "&::placeholder": {
  // 	background: "#1C1C1C33", // Placeholder background color
  // },
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  width: "18.02px",
  height: "18.02px",
  top: "1.48px",
  left: "1.48px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.text.secondary,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "36px",
  height: "36px",
  color: theme.palette.primary.dark,
  "&:hover": {
    color: "#1C1C1C55",
  },
}));

// Main SearchBar component
const SearchBar = ({
  placeholder = "Search",
  hideSearchInput = false,
  options = false,
  showFilter = true,
  onChange,
  FilterComponent = <></>,
  ShowHideComponent = <></>
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    if (onChange) {
      onChange(newValue); // Pass the search query to the parent component
    }
  };

  const handleOpenFilterModal = (e) => {
    setIsModalOpen(true);
  };

  return (
    <StyledContainer withoutInput={hideSearchInput} maxWidth="100%">
      {!hideSearchInput && (
        <StyledInputBase
          id="search"
          placeholder={placeholder}
          startAdornment={<StyledSearchIcon />}
          value={searchValue}
          onChange={handleInputChange}
        />
      )}
      {options && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                id="showOnlyAvailableResources"
                sx={{
                  "&.Mui-checked": {
                    color: "common.black",
                  },
                }}
                defaultChecked
                size="small"
              />
            }
            label={
              <Typography variant="h5">
                Show only available resources
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                id="autoRecommend"
                sx={{
                  "&.Mui-checked": {
                    color: "common.black",
                  },
                }}
                defaultChecked
                size="small"
              />
            }
            label={<Typography variant="h5">Auto-Recommend</Typography>}
          />
        </>
      )}
      <Box sx={{ position: "relative",display:"flex",flexDirection:"row",alignItems:"center"}} gap={1}>
        {showFilter && (
          <StyledIconButton id="filter" onClick={handleOpenFilterModal}>
            <FilterIcon />
          </StyledIconButton>
        )}

        <FilterModal
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        >
          {FilterComponent}
        </FilterModal>
        {ShowHideComponent}
      </Box>
    </StyledContainer>
  );
};

export default SearchBar;
