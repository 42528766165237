// Formala For Calculations
export const config = {
	NetworkDays:
		"Math.round((optionalEndDate - optionalStartDate) / (24 * 60 * 60 * 1000) * 5 / 7)",
	CalculateDuration: "NetworkDays / 5",
	HoursOnShore: "CalculateDuration * 40",
	HoursOffShore: "CalculateDuration * 45",
	WavicleCostOnShore:
		"HoursOnShore * HourlyCost * CostUtilizationPercentageNumeric * NumberOfResources",
	WavicleCostOffShore:
		"HoursOffShore * HourlyCost * CostUtilizationPercentageNumeric * NumberOfResources",
	ClientStandardPriceOnShore:
		"StandardRate * HoursOnShore * ProjectUtilizationPercentageNumeric * NumberOfResources",
	ClientStandardPriceOffShore:
		"StandardRate * HoursOffShore * ProjectUtilizationPercentageNumeric * NumberOfResources",
	ProposedHoursOnShore:
		"HoursOnShore * ProjectUtilizationPercentageNumeric * NumberOfResources",
	ProposedHoursOffShore:
		"HoursOffShore * ProjectUtilizationPercentageNumeric * NumberOfResources",
	ActualRateOnShore: "FixedPriceRevenueOnShore/HoursOnShore",
	ActualRateOffShore: "FixedPriceRevenueOffShore/HoursOffShore",
	ActualRevenueOnShore:
		"ActualRateOnShore * HoursOnShore * ProjectUtilizationPercentageNumeric * NumberOfResources",
	ActualRevenueOffShore:
		"ActualRateOffShore * HoursOffShore * ProjectUtilizationPercentageNumeric * NumberOfResources",
	PLCostOnShore: "(ActualRevenueOnShore * PLUtilizationPercentageNumeric)",
	PLCostOffShore: "(ActualRevenueOffShore * PLUtilizationPercentageNumeric)",
	CLCostOnShore: "(ActualRevenueOnShore * CLUtilizationPercentageNumeric)",
	CLCostOffShore: "(ActualRevenueOffShore * CLUtilizationPercentageNumeric)",
	WavicleDiscountOnShore:
		"Math.round(WavicleDiscountPercentageNumeric * ActualRevenueOnShore)",
	WavicleDiscountOffShore:
		"(WavicleDiscountPercentageNumeric * ActualRevenueOffShore)",
	RevenueAfterDeductionsOnShore:
		"(FixedPriceRevenueOnshore - WavicleDiscountOnShore - PLCostOnShore - CLCostOnShore)",
	RevenueAfterDeductionsOffShore:
		"FixedPriceRevenueOffshore - WavicleDiscountOffShore - PLCostOffShore - CLCostOffShore",
	PracticeLeadAccountExecCost: "TotalPLCost",
	CompetencyLeadCost: "TotalCLCost",
	DealRevenueAndGMBeforeDeductionsCost: "TotalFixedPriceRevenue",
	DealRevenueAndGMBeforeDeductionsPercentage:
		"Math.round((DealRevenueAndGMBeforeDeductionsCost - TotalWavicleCost) / DealRevenueAndGMBeforeDeductionsCost * 100)",
	DealRevenueAndGMAfterDeductionsCost: "TotalRevenueAfterDeductions",
	DealRevenueAndGMAfterDeductionsPercentage:
		"((DealRevenueAndGMAfterDeductionsCost - TotalWavicleCost) / DealRevenueAndGMAfterDeductionsCost * 100)",
	FinalGrossMarginCost:
		"DealRevenueAndGMAfterDeductionsCost - TotalWavicleCost",
	FinalGrossMarginPercentage: "DealRevenueAndGMAfterDeductionsPercentage",
	StaffingRatiosAndMarginOnshorePercentage:
		"(NumberOfResourcesOnshore / TotalNumberOfResources)*100",
	StaffingRatiosAndMarginOffshorePercentage:
		"(NumberOfResourcesOffshore / TotalNumberOfResources)*100",
	StaffingRatiosAndMarginCurrentMarginPercentage: "FinalGrossMarginPercentage",
	AirfareAmount: "FinalGrossMarginCost * TravelPercentageApplied",
	HotelsAmount: "AirfareAmount * 0.2",
	GroundTransportationAmount: "AirfareAmount * 0.05",
	MealsAmount: "AirfareAmount * 0.2",
	OtherAmount: "AirfareAmount * 0.2",
	TotalTravelExpensesAmount:
		"AirfareAmount + HotelsAmount + GroundTransportationAmount + MealsAmount + OtherAmount",
	EM2InABoxUtilizationPerccentage: "EMTotalCostUtilizationPercentage",
	EM2InABoxCost: "EMTotalWavicleCost",
	DL2InABoxUtilizationPercentage: "DLTotalCostUtilizationPercentage",
	DL2InABoxCost: "DLTotalWavicleCost",
	TotalWavicleCost: "RoleTotalWavicleCost",
	TotalClientPrice: "RoleTotalClientPrice",
	TotalActualRevenue: "RoleTotalActualRevenue",
	TotalFixedPriceRevenue: "RoleTotalFixedPriceRevenue",
	TotalHoursProposed: "RoleTotalHoursProposed",
	MonthlyPayment:
		"FixedPriceRevenue/(((EstEndYear-EstStartYear)*12)+(EstEndMonth - EstStartMonth))",
};