import React from "react";
import { useSelector } from "react-redux";
import "./Loader.css";

const OverlaySpinner = () => {
  const isLoading =
    useSelector((state) => state?.loader?.isOverlayLoading) || false;

  return isLoading ? (
    <div className="overlay">
      <div className="overlay-spinner"></div>
    </div>
  ) : null;
};

export default OverlaySpinner;
