import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staffingData: {},
};

const staffingSlice = createSlice({
  name: "staffing",
  initialState,
  reducers: {
    storeStaffingData: (state, action) => {
      const { pageIndex, staffSheetId } = action.payload;
      state.staffingData[pageIndex] = staffSheetId;
    },
  },
});

export const { storeStaffingData } = staffingSlice.actions;
export default staffingSlice.reducer;
