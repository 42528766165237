import React, { forwardRef } from "react";
import {
  Typography,
  styled,
  TextareaAutosize,
  useTheme,
  Stack,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import PageContentWithEditor from "../TextEditer/TextEditer";

export const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  maxHeight: "400px",
  overflow: "auto",
  height: "100%",
  resize: "none",
  backgroundColor: theme.palette.background.primary,
  flexGrow:1,
  borderRadius: 16,
  padding: "1rem",
  color: theme.palette.text.primary,
  ...theme.typography.body2,
  '&:focus':{
    outline:'none'
  }
}));
const StyledDisabledTextArea = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeight: "400px",
  overflow: "auto",
  height: "100%",
  resize: "none",
  backgroundColor: theme.palette.background.primary,
  borderRadius: 16,
  padding: "1rem",
  color: theme.palette.text.primary,
  ...theme.typography.body2,
}));

const Title = styled(Typography)({
  marginBottom: "1rem",
});

// Styled component for the image container
export const ImageContainer = styled(Grid)(({ theme }) => ({
  width: 20,
  height: 20,
  // opacity: 0,
}));

// Styled component for the image tag
export const ImageTag = styled("img")(({ theme }) => ({
  width: 20.25,
  height: 20.25,
  top: 1.88,
  left: 1.88,
  gap: 0,
}));

const TextAreaCard = ({
  id,
  title,
  titleIcon = "",
  titleIconText = "",
  value,
  onChange,
  disabled = false,
  placeholder,
  borderLine = false,
  props,
  style,
  type = "",
  label = "",
  name,
},ref=null) => {
  const theme = useTheme();
  console.log(value)
  return (
    <>
      {title && (
        <Stack direction="row" gap={1.5}>
          <Title variant="h5">{title}</Title>
          {titleIcon && (
            <Tooltip title={titleIconText}>
            <ImageContainer sx={{cursor:'help'}}>
              <ImageTag src={titleIcon} alt="icon" />
            </ImageContainer>
            </Tooltip>
          )}
        </Stack>
      )}
      {type === "formatter" ? (
        <>
          {disabled ? (
            <StyledDisabledTextArea
              ref={ref}
              sx={{
                border: borderLine
                  ? `1px solid ${theme.palette.borderColor}`
                  : 0,
                ...style,

                textAlign: "left",
              }}
              id={id}
              props
              name={name}
              placeholder={placeholder}
              dangerouslySetInnerHTML={{ __html: value }}
              disabled={disabled}
            />
          ) : (
            <PageContentWithEditor
            id={id}
              disabled={disabled}
              value={value}
              onChange={onChange}
            />
          )}
        </>
      ) : (
        <StyledTextarea
          ref={ref}
          sx={{
            border: borderLine ? `1px solid ${theme.palette.borderColor}` : 0,
            ...style,
            textAlign: "left",
          }}
          props
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange} // Pass onChange handler here
          disabled={disabled}
        />
      )}
    </>
  );
};

export default forwardRef(TextAreaCard);
