import React from "react";
import { Divider, Grid, Stack, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import StatusBadge from "../statusbadges/StatusBadge";
import { useSelector } from "react-redux";
import { PermittedItems } from "../../utils/RbacUtils";

// Define a custom styled component using Material-UI's styled function
export const CustomBoxWrapper = styled(Stack)(({ theme, user }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(2),
  gap: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  background: theme.palette.background.secondary,
  minHeight: "8.5rem",
  alignItems: "flex-start",
}));

// Styled component for the project title typography
export const ProjectTitleTypography = styled(Typography)(({ theme, user }) => ({
  color: theme.palette.text.primary,
  fontSize: 18,
  fontWeight: 600,
  lineHeight: user ? "30px" : "24px",
  textAlign: "left",
}));

// Styled component for the project title typography
export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  textAlign: "left",
}));

// Styled component for the title toolbar
export const TitleToolBar = styled(Grid)(({ theme }) => ({
  display: "flex", // Flexbox layout
  alignItems: "center", // Center items vertically
  justifyContent: "space-between",
  gap: "2rem", // Gap between elements
  width: "inherit",
}));

// Styled component for the content toolbar
export const ContentToolBar = styled(Grid)(({ theme, statusbar}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: theme.spacing(3), // Corrected gap value
  justifyContent: statusbar ? "space-between" : "flex-start",
}));

// Styled component for the vertical divider
export const VerticalDivider = styled("div")(({ theme }) => ({
  width: "1px",
  backgroundColor: theme.palette.borderColor,
  height: "100%",
}));

// Styled component for the container of each item
export const ContentItemContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

// Styled component for the image container
export const ImageContainer = styled(Grid)(({ theme }) => ({
  width: 20,
  height: 20,
  // opacity: 0,
}));

// Styled component for the image tag
export const ImageTag = styled("img")(({ theme }) => ({
  width: 20.25,
  height: 20.25,
  top: 1.88,
  left: 1.88,
  gap: 0,
}));
export const ProfileImageContainer = styled(Grid)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: "50%",
  overflow: "hidden",
  // opacity: 0,
}));

// Styled component for the image tag
export const ProfileImageTag = styled("img")(({ theme }) => ({
  width: 40.25,
  height: 40.25,
  top: 1.88,
  left: 1.88,
  gap: 0,
	padding:"2px",
	objectFit: "contain"
}));

export const ImagePlaceholder = styled(Stack)(({ theme }) => ({
  width: 100.25,
  height: 100,
  // flex: 1,
  display: "flex",
  backgroundColor: "red",
}));

export const OverViewDetailCard = ({
  id="",
  statusType,
  title,
  data,
  icon,
  status,
  activeStatus,
  user = false,
  component: Component,
  statusbar= false,
  model,
  variant='',
  showBorderVariant=true
}) => {
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const permission = useSelector((state) => state?.permission)

  const hasAnyPermittedCard = PermittedItems({ data: [{ id: id }], component: "card", dataKey: "id", permission});
  return (
    hasAnyPermittedCard.length > 0 &&
    <div id={id} className={`main-card ${showBorderVariant && isDarkMode ? 'gradient-border' : ''}`}>
      <CustomBoxWrapper container user={user}>
        <TitleToolBar>
          <Stack direction="row" alignItems="center" gap="0.5rem">
            <ProjectTitleTypography user={user}>{title}</ProjectTitleTypography>
            {activeStatus && user && (
              <StatusBadge
                statusType={statusType}
                status={activeStatus}
                variant="contain"
              />
            )}
            {model && (
              <StatusBadge
                variant={""}
                statusType={"model"}
                status={model}
              />
            )}
            {status && (
              <StatusBadge
                variant={variant}
                statusType={statusType}
                status={status}
              />
            )}
          </Stack>
          {icon && (
            <ProfileImageContainer>
              <ProfileImageTag src={icon} alt="icon" />
            </ProfileImageContainer>
          )}
        </TitleToolBar>
        {Component && Component}
        <ContentToolBar sx={{justifyContent:'stretch'}}  statusbar={statusbar}>
          {data?.map((item, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <Divider
                  sx={{ borderColor: "#1C1C1C1A" }}
                  orientation="vertical"
                  flexItem
                />
              )}
              <ContentItemContainer key={index}>
                <Grid
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <TitleTypography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      textAlign: "left",
                    }}
                  >
                    {item?.title}
                  </TitleTypography>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "4px",
                      alignItems: "center",
                      lineHeight: "spacing(3)",
                    }}
                  >
                    {item?.icon && (
                      <ImageContainer>
                        <ImageTag src={item?.icon} alt="icon" />
                      </ImageContainer>
                    )}
                    {typeof item?.subtitle === "function" ? (
                      item?.subtitle()
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          lineHeight: "24px",
                          textAlign: "left",
                          textTransform: "capitalize",
                          "&::first-letter": {
                            textTransform: "uppercase",
                          },
                        }}
                      >
                        {item?.subtitle}
                      </Typography>
                    )}
                  </Grid>

                  {item?.date && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        textAlign: "left",
                      }}
                    >
                      {item?.date}
                    </Typography>
                  )}
                </Grid>
              </ContentItemContainer>
            </React.Fragment>
          ))}
          {statusbar && <Box width="5%"></Box>}
        </ContentToolBar>
      </CustomBoxWrapper>
    </div>
  );
};
