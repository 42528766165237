import { Chip, Stack, Typography } from "@mui/material";
import React from "react";

const Tags = ({ id, payload = [], onDelete, deleteItem, containerStyle = {}, style }) => {
  const handleOnDelete = (indexToRemove, event) => {
    event.stopPropagation(); // Prevents the event from bubbling up
    const updatedPayload = [
      ...payload.slice(0, indexToRemove),
      ...payload.slice(indexToRemove + 1),
    ];
    onDelete(updatedPayload); // Call the onDelete prop with updated payload
  };

  const isObject = (item) => {
    return typeof item === "object" && !Array.isArray(item) && item !== null;
  };

  return (
    <Stack
      style={containerStyle}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        flexWrap: "wrap",
        zIndex: 999,
        ...style
      }}
    >
      {Array.isArray(payload)
        ? payload.map((item, i) => {
            if (deleteItem) {
              if (isObject(item)) {
                return (
                  <Chip
                    key={i}
                    id={`${id}-${i}`}
                    label={
                      <Stack direction="row" alignItems="center">
                        <Typography variant="h5" color="text.textviolet">
                          {item.subtitle}
                        </Typography>
                        :
                        <Typography variant="h5" color="text.secondary">
                          {item.label}
                        </Typography>
                      </Stack>
                    }
                    onDelete={(e) => handleOnDelete(i, e)}
                  />
                );
              }
              return (
                <Chip
                  key={i}
                  id={`${id}-${i}`}
                  label={item}
                  onDelete={(e) => handleOnDelete(i, e)}
                />
              );
            } else {
              return <Chip key={i} id={`${id}-${i}`} label={item} />;
            }
          })
        : null}
    </Stack>
  );
};

export default Tags;
