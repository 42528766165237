// authSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  isAuthenticated: false,
  username: null,
  email: null,
  accessToken: null,
  userRoles: [],
  userGroupsAndRoles: [],
  userId: null,
  userActions: [],
  currentURLs:{},
  // Add other properties as needed
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.currentUser = action.payload;
      state.isAuthenticated = true;
      state.username = action.payload.name;
      state.email = action.payload.preferred_username;
      state.accessToken = action.payload.accessToken;
    },
    logoutUser: (state) => {
      state.currentUser = null;
      state.isAuthenticated = false;
      state.username = null;
      state.email = null;
      state.accessToken = null;
      state.userRoles = [];
      state.userGroupsAndRoles = [];
      state.userId = null;
      state.userActions = [];
      state.currentURLs = {}
    },
    deleteValues: (state) => {
      state.userRoles = [];
      state.error = null; // Reset the error state
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    setUserGroupsAndRoles: (state, action) => {
      state.userGroupsAndRoles = action.payload.assigned_groups;
      state.userId = action.payload.user_id;
    },
    setLoginedUserGroupAndRole: (state, action) => {
      state.currentUser.roleId = action.payload.roleId;
      state.currentUser.groupId = action.payload.groupId;
    },
    setUserActions: (state, action) => {
      state.userActions = action.payload;
    },
    setCurrentURLs: (state, action) => {
      // Ensure payload contains both URL and action properties
      console.log(action.payload)
      const { url, action: urlAction } = action.payload;
      console.log(url,urlAction)
      if (url && urlAction) {
        state.currentURLs[url] = urlAction;
      }
    },
    removeURL: (state, action) => {
      const { url } = action.payload;
      console.log(url)
      if (url && state.currentURLs[url]) {
        delete state.currentURLs[url];
      }
    },
  },
});

export const { loginUser, logoutUser, deleteValues, setUserRoles, setUserGroupsAndRoles, setLoginedUserGroupAndRole, setUserActions, setCurrentURLs, removeURL } = authSlice.actions;
export default authSlice.reducer;
