import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setCurrentPagePermission, setMenuPermission } from "../redux/slices/PermissionSlice";

const RouteChangeListener = () => {
  const location = useLocation(); 
  const dispatch = useDispatch();
  const { applicationPermission, menuPermission } = useSelector((state) => state.permission);

  // Memoize path to avoid redundant splitting
  const currentPath = location.pathname;
  
  // Memoized parsed path and last segment check
  const processedPath = useMemo(() => {
    const pathSegments = currentPath.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    // Check if the last segment is a project ID
    const isProjectId = /^(WAV\d+|inab-\d+)$/i.test(lastSegment);
    if (isProjectId) {
      pathSegments.pop(); // Remove last segment if it's a project ID
    }
    return pathSegments.join("/");
  }, [currentPath]);

  useEffect(() => {
    // Check for permissions in applicationPermission using currentPath
    const currentMenuPermissions = applicationPermission.find((menu) => menu.url === currentPath);
    console.log(currentMenuPermissions);
    
    if (currentMenuPermissions) {
      // Only dispatch if permissions have changed
      dispatch(setMenuPermission(currentMenuPermissions));
    } else if (menuPermission?.otherRoutes) {
      // Lookup from otherRoutes if currentMenuPermissions is empty
      const permission = menuPermission?.otherRoutes[processedPath] || {};
      
      if (Object.keys(permission).length > 0) {
        // Dispatch permissions only if there are permissions to set
        dispatch(setCurrentPagePermission(permission?.permissions || {}));
      }
    }
  }, [currentPath, processedPath, applicationPermission, menuPermission, dispatch]);

  return null; // Component does not render anything
};

export default RouteChangeListener;
