const fonts = {
	sizes: {
		64: {
			semibold: {
				fontSize: "64px",
				fontWeight: 600,
				lineHeight: "78px",
				textAlign: "left",
			},
			regular: {
				fontSize: "64px",
				fontWeight: 400,
				lineHeight: "78px",
				textAlign: "left",
			},
		},
		48: {
			semibold: {
				fontSize: "48px",
				fontWeight: 600,
				lineHeight: "58px",
				textAlign: "left",
			},
			regular: {
				fontSize: "48px",
				fontWeight: 400,
				lineHeight: "58px",
				textAlign: "left",
			},
		},
		24: {
			semibold: {
				fontSize: "24px",
				fontWeight: 600,
				lineHeight: "36px",
				textAlign: "left",
			},
			regular: {
				fontSize: "24px",
				fontWeight: 400,
				lineHeight: "36px",
				textAlign: "left",
			},
		},
		18: {
			semibold: {
				fontSize: "18px",
				fontWeight: 600,
				lineHeight: "24px",
				textAlign: "left",
			},
			regular: {
				fontSize: "18px",
				fontWeight: 400,
				lineHeight: "24px",
				textAlign: "left",
			},
		},
		14: {
			semibold: {
				fontSize: "14px",
				fontWeight: 600,
				lineHeight: "20px",
				textAlign: "left",
			},
			regular: {
				fontSize: "14px",
				fontWeight: 400,
				lineHeight: "20px",
				textAlign: "left",
			},
		},
		12: {
			semibold: {
				fontSize: "12px",
				fontWeight: 600,
				lineHeight: "18px",
				textAlign: "left",
			},
			regular: {
				fontSize: "12px",
				fontWeight: 400,
				lineHeight: "18px",
				textAlign: "left",
			},
		},
	},
	families: {
		inter: "Inter, sans-serif",
		roboto: "Roboto, sans-serif",
	},
	lineHeight: {
		64: "78px",
		48: "58px",
		24: "36px",
		18: "24px",
		14: "20px",
		12: "18px",
	},
	weight: {
		semibold: 600,
		regular: 400,
	},
	align: "left",
	size: {
		64: {
			semibold: "64px",
			regular: "64px",
		},
		48: {
			semibold: "48px",
			regular: "48px",
		},
		24: {
			semibold: "24px",
			regular: "24px",
		},
		18: {
			semibold: "18px",
			regular: "18px",
		},
		14: {
			semibold: "14px",
			regular: "14px",
		},
		12: {
			semibold: "12px",
			regular: "12px",
		},
	},
	families: {
		inter: "Inter, sans-serif",
		roboto: "Roboto, sans-serif",
	},
};

export default fonts;
