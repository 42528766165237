import { createSlice } from "@reduxjs/toolkit";
import {
	getAirfareAmount,
	// getCLCost,
	getClientStandardPrice,
	getFixedPriceRevenueAndActualRevenue,
	// getHotelsAmount,
	// getHoursOffShoreOROffShore,
	getInABoxCost,
	getPLCost,
	getProposedHours,
	getRateAndCostFromServer,
	getRevenueAfterDeductions,
	// getTotalTravelExpensesAmount,
	getWavicleCost,
	// getStaffingRatiosAndMarginsPercentage,
	// getRevBeforeDeductionsCost,
	// getRefAfterDeductionsCost,
	// getTotalWavicleCost,
	// getTotalClientPrice,
	// getTotalActualRevenue,
	// getTotalHoursProposed,
	getDuration,
	getRoleForStaffing,
	getDRDataForOverviewAndStaffing,
	updateStaffingAndOverview,
	getDrFilterDetailsFromDB,
	getNewDRDetailsFromDB,
	getWavicleDiscountCost,
	deleteRoleCardDB,
	// getfinalGrossMarginCost,
	// getRevBeforeDeductionsPercentage,
	// getDealRevenueAndGmAfterDeductionsPercentage,
	// getFinalGrossMarginPercentage,
	// getStaffingRatioAndMarginCurrentMarginPercentage,
	// getStaffingRatioAndExpectedMarginPercentage,
	// getMonthlyPayment,
	// getEM2AndDL2InBoxUtilizationPercentage,
	getDealsfromdrandhs,
	updatedStatus,
	updateNewDR,
	submitLegal,
	getEndDateByDuration,
	// getRevenueAndGMAfterDeductionsCost,
} from "./DealReviewCalcAPI'sCalls";
import IsEmptyArray from "../../utils/CheckArrayISEmpty";
import siteConfig from "../../common/lang/locale/en-US";

const initialState = {
	RoleCardDetails: [],
	commonInputs: {
		DealreviewID: null,
		ProjectId: null,
		ESTStartdate: null,
		ActualStartDate: null,
		ClientManaged: 'N',
		ActualEndDate: null,
		ESTEndDate: null,
		ESTClosedDate: null,
		CompanyName: null,
		ProjectName: null,
		Duration: null,
		PeekHeadcount: null,
		OnShore: null,
		OffShore: null,
		MSACompleted: "Yes",
		LifeCycle: [],
		PricingType: null,
		ServiceCategories: [],
		Technology: [],
		FeeExpenses: null,
		RiskAndAssumptions: null,
		Notes: null,
		DealOwner: null,

    DrStatus: null,
    CreatedBy: null,
    CreatedAt: null,
    LastUpdateBy: null,
    UpdatedAt: null,
    ParentDealReviewId: null,
    DrEffectiveTs: null,
    DrEndTs: null,
    SolutionsArchOverview: null,
    CapabilitiesLeadOverview: null,
    LegalOverview: null,
    StaffingOverview: null,

    TotalFixedPrice: null,
    TotalDiscount: null,
    TotalClCost: null,
    TotalPlCost: null,

    WavicleDiscount: null,
    DiscountInvestment: "%", // this for idendify wavicle discount is percentage or amount
	isPracticeLeadUtlization: "%",  // this for idendify practice lead is percentage or amount
	isCompetencyLeadUtilization:  "%", // this for idendify competency lead is percentage or amount
	isTravelExpenses: "%", // this for idendify travel expenses is percentage or amount
	isMiscExpenses: "%", // this for idendify misc expenses is percentage or amount
    CurrentMargin: null,
    ExpectedMargin: null,
    ExpensePercentage: null,
    MonthlyPayment: null,
    PLUtilization: null,
    CLUtilization: null,
    InABoxEM: null,
    InABoxDL: null,
    RevBefDeductions: null,
    RevAftDeductions: null,
    FinalGrossMargin: null,
    TotalWavicleCost: null,
    TotalClientPrice: null,
    TotalActRevenue: null,
    TotalRevenueAfterDeductions: null,
    TotalFixedPriceRevenue: null,
    TotalHoursProposed: null,

    Expenes: null,
    Airfare: null,
    Hotels: null,
    GroundTransport: null,
    Meals: null,
    Others: null,
    TotalTravelExpenses: null,

    DealRevenueAndGMBeforeDeductionsPercentage: null,
    DealRevenueAndGMAfterDeductionsPercentage: null,
    FinalGrossMarginPercentage: null,
    StaffingRatiosAndMarginOnshorePercentage: null,
    StaffingRatiosAndMarginOffshorePercentage: null,
    EM2InABoxUtilizationPercentage: null,
    DL2InABoxUtilizationPercentage: null,

    //deva create this state
    otherExpenses: null,
	TravelPercentage : null,
	Miscellaneous : null
  },
  isModalOpen: { IsOpen: false, ClickedIndex: 0 },
  isSavedDataIntoDB: false,
  isRequiredColumnFilled: true,
  Deals: {},
  FilterScreenData: [],
  NewDRSrceenData: [],
  SaveButtonLoading: false,
  ShowSnackbar: { isopen: false, message: "", type: "" },
  snackBar: { isopen: false, message: "", type: "" },
};

const Deals = createSlice({
	name: "Deals",
	initialState,
	reducers: {
		ChangeIsRequiredColumnFilledState: (state, action) => {
			return {
				...state,
				isRequiredColumnFilled: action.payload,
			};
		},
		commonInputOnChange: (state, action) => {
			const { name, value } = action.payload;
			state.commonInputs[name] = value;
		},
		OnChangeNewDRSrceenData: (state, action) => {
			const { name, value } = action.payload;
			state.NewDRSrceenData[0][name] = value;
		},
		addRoleCard: (state, action) => {
			state.RoleCardDetails.push({
				SheetID: action.payload === 'dr_insert' ? 0 : null,
				SWORoleName: null,
				RoleId: null,
				Role: null,
				Band: null,
				Location: null,
				Resources: null,
				ActualRate: null,
				FixedPriceRevenue: null,
				ProjectUtilization: null,
				CostUtilization: null,
				StartDate: state.commonInputs.ESTStartdate,
				EndDate: state.commonInputs.ESTEndDate,
				ProposedHours: null,
				Hours: null,
				HourlyCost: null,
				StandardRate: null,
				WavicleCost: null,
				ClientStandardPrice: null,
				ActualRevenue: null,
				RevenueAfterDeduction: null,
				PLCost: null,
				CLCost: null,
				WavicleDiscountCost: null,

				ClientManaged: "No",
				RequiredSkills: [],
				RoleRequirements: null,
				ResourceType: null,
				LaptopNeeded: "No",
				InterviewPanel: [],
				HaveClientInterview: "No",
				ClientInterviewInfo: null,
				...(action.payload === 'dr_insert' ? {
					isNewlyAdded: true
				}: {})
			});
		},
		updateRoleCard: (state, action) => {
			const { roleCard } = action.payload;
			state.RoleCardDetails = roleCard;
		},
		deleteRoleCard: (state, action) => {
			const { clickedIndex } = action.payload;
			const RoleDetailsLength = state.RoleCardDetails.length === 1;
			if (RoleDetailsLength) {
				state.RoleCardDetails = [];
			} else {
				state.RoleCardDetails = state.RoleCardDetails.filter(
				(role, index) => index !== clickedIndex
				);
			}
			state.isModalOpen = { IsOpen: false, ClickedIndex: 0 };
    	},
    openModal: (state, action) => {
      state.isModalOpen = { IsOpen: true, ClickedIndex: action.payload };
    },
    closeModal: (state) => {
      state.isModalOpen = { IsOpen: false, ClickedIndex: 0 };
    },
    updateStaffingIntakeProperty: (state, action) => {
      const { sheetId, property, value } = action.payload;
      const updatedRoleCardDetails = state.RoleCardDetails.map((RoleCard) => {
        if (RoleCard.SheetID === sheetId) {
          return { ...RoleCard, [property]: value };
        }
        return RoleCard;
      });

      return {
        ...state,
        RoleCardDetails: updatedRoleCardDetails,
      };
    },
    updateRoleCardProperty: (state, action) => {
      const { index, property, value } = action.payload;
	  if(state.RoleCardDetails && state.RoleCardDetails[index]) {
		state.RoleCardDetails[index][property] = value;
	  }
    },
    setSavedData: (state, action) => {
      state.isSavedDataIntoDB = true;
    },
    setChangeData: (state, action) => {
      state.isSavedDataIntoDB = false;
    },
    AddDeals: (state, action) => {
      state.Deals = action.payload;
    },
    addLifeCycle: (state, action) => {
      return {
        ...state,
        commonInputs: {
          ...state.commonInputs,
          LifeCycle: state.commonInputs.LifeCycle.concat(action.payload),
        },
      };
    },
    setSaveButtonLoadingTrue: (state, action) => {
      state.SaveButtonLoading = true;
    },
    setSaveButtonLoadingFalse: (state, action) => {
      state.SaveButtonLoading = false;
    },
    ShowSnackbar: (state, action) => {
      state.ShowSnackbar = { isopen: true, message: "", type: "" };
    },
    HideSnackbar: (state, action) => {
      state.ShowSnackbar = { isopen: false, message: "", type: "" };
    },
    visibleSnackBar: (state, action) => {
      state.snackBar = {
        isopen: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    },
    setCommonInputs: (state, action) => {
      if (action.payload) {
        const AP = action.payload;
        return {
          ...state,
          commonInputs: {
            ...state.commonInputs,
            DealreviewID: AP.deal_review_id,
            ProjectId: AP.project_id_var,
            ESTStartdate: AP.est_start_date
              ? new Date(AP.est_start_date).toISOString().split("T")[0]
              : null,
            ESTEndDate: AP.est_end_date
              ? new Date(AP.est_end_date).toISOString().split("T")[0]
              : null,
            ESTClosedDate: AP.est_close_date
              ? new Date(AP.est_close_date).toISOString().split("T")[0]
              : null,
            CompanyName: AP.company_name,
            ProjectName: AP.project_name,
            Duration: AP.duration_in_weeks,
            PeekHeadcount: AP.peak_headcount,
            OnShore: AP.onsite_staff_percent,
            OffShore: AP.offshore_staff_percent,
            MSACompleted: AP.msa_complete,
            LifeCycle: AP.life_cycle,
            PricingType: AP.pricing_type,
            ServiceCategories: AP.services_included,
            Technology: AP.technology,
            FeeExpenses: AP.fee_expenses,
            RiskAndAssumptions: AP.risk_and_assumptions,
            Notes: AP.notes,
            DealOwner: AP.deal_owner,

            TotalTravelExpenses: AP.total_travel_expenses,
            DrStatus: AP.dr_status,
            CreatedBy: AP.created_by,
            CreatedAt: AP.created_at,
            LastUpdateBy: AP.last_update_by,
            UpdatedAt: AP.updated_at,
            ParentDealReview_id: AP.parent_deal_review_id,
            DrEffectiveTs: AP.dr_effective_ts,
            DrEndTs: AP.dr_end_ts,
            SolutionsArchOverview: AP.solutions_arch_overview,
            CapabilitiesLeadOverview: AP.capabilities_lead_overview,
            LegalOverview: AP.legal_overview,
            StaffingOverview: AP.staffing_overview,
            ActualStartDate: AP.actual_start_date
              ? new Date(AP.actual_start_date).toISOString().split("T")[0]
              : null,
            ActualEndDate: AP.est_close_date
              ? new Date(AP.actual_end_date).toISOString().split("T")[0]
              : null,
            TotalFixedPrice: AP.total_fixed_price,
            TotalDiscount: AP.total_discount,
            DiscountInvestment: Number(AP.wavicle_discount) < 50 ? "%" : "$",
            TotalClCost: AP.total_cl_cost,
            TotalPlCost: AP.total_pl_cost,
            ClientManaged: AP.clientmanaged,
            CurrentMargin: AP.current_margin,
            ExpectedMargin: AP.expected_margin,
            ExpensePercentage: AP.travel_percentage,
            PLUtilization: AP.prac_lead_utilization,
            CLUtilization: AP.comp_lead_utilization,
            MonthlyPayment: AP.monthly_payment || 0,
            InABoxEM: AP["2-in-a-box-em"],
            InABoxDL: AP["2-in-a-box-dl"],
            WavicleDiscount: AP.wavicle_discount,
            RevBefDeductions: AP.revenue_before_deductions,
            RevAftDeductions: AP.revenue_after_deductions,
            FinalGrossMargin: AP.final_gross_revenue,
            TotalWavicleCost: AP.total_wavicle_cost,
            TotalClientPrice: AP.total_client_price,
            TotalActRevenue: AP.total_revenue,
            TotalRevenueAfterDeductions: AP.total_revenue_after_deductions,
            TotalFixedPriceRevenue: AP.total_revenue,
            TotalHoursProposed: AP.total_hours_proposed,

            Expenes: AP.total_deal_expenses,
            Airfare: AP.airfare,
            Hotels: AP.hotel_stay_cost,
            GroundTransport: AP.ground_transport,
            Meals: AP.meal_expense,
            Others: AP.other_expense,
			Miscellaneous : AP.miscellaneous,
						DealRevenueAndGMBeforeDeductionsPercentage: null,
						DealRevenueAndGMAfterDeductionsPercentage: null,
						FinalGrossMarginPercentage: null,
						StaffingRatiosAndMarginOnshorePercentage: null,
						StaffingRatiosAndMarginOffshorePercentage: null,
						EM2InABoxUtilizationPercentage: null,
						DL2InABoxUtilizationPercentage: null,
					},
				};
			}
		},
		//calculate Total Wavicle Investment
		caculateTotalWavicleInvestmentCost: (state, action) => {
			if (!state.RoleCardDetails?.length > 0)
				return {
					...state,
					commonInputs: { ...state.commonInputs, TotalWavicleDiscountCost: 0 },
				};
			const TotalWavicleDiscountCost = state.RoleCardDetails?.reduce(
				(total, value) => total + value.WavicleDiscountCost,
				0
			);
			return {
				...state,
				commonInputs: {
					...state.commonInputs,
					TotalDiscount: TotalWavicleDiscountCost,
				},
			};
		},
		caculateTotalPLCost: (state, action) => {
			if (!state.RoleCardDetails?.length > 0)
				return {
					...state,
					commonInputs: { ...state.commonInputs, TotalPlCost: 0 },
				};
			const TotalPlCost = state.RoleCardDetails?.reduce(
				(total, value) => total + value.PLCost,
				0
			);
			return {
				...state,
				commonInputs: {
					...state.commonInputs,
					TotalPlCost: TotalPlCost,
				},
			};
		},
		caculateTotalCLCost: (state, action) => {
			if (!state.RoleCardDetails?.length > 0)
				return {
					...state,
					commonInputs: { ...state.commonInputs, TotalClCost: 0 },
				};
			const TotalClCost = state.RoleCardDetails?.reduce(
				(total, value) => total + value.CLCost,
				0
			);
			return {
				...state,
				commonInputs: {
					...state.commonInputs,
					TotalClCost: TotalClCost,
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			// .addCase(getHoursOffShoreOROffShore.rejected, (state) => {
			// 	// console.log(" getRateAndCostFromServer rejected...");
			// })
			.addCase(getRateAndCostFromServer.fulfilled, (state, action) => {
				// Check if the payload and index are available
				if (action.payload && typeof action.payload.index === "number") {
					const index = action.payload.index;

          const rate = action.payload.data[0]?.rate;
          const cost = action.payload.data[0]?.cost;
          if (!IsEmptyArray(action.payload.data)) {
            // Update the state if the rate and cost is available
            if (rate !== undefined && cost !== undefined) {
              return {
                ...state,
                RoleCardDetails: state.RoleCardDetails.map((item, i) => {
                  if (i === index) {
                    // Update the values for the specified index
                    return {
                      ...item,
                      StandardRate: rate,
                      ActualRate: rate,
                      HourlyCost: cost,
                    };
                  }
                  return item;
                }),
              };
            }
          } else {
            return {
              ...state,
              RoleCardDetails: state.RoleCardDetails.map((item, i) => {
                if (i === index) {
                  // Update the values for the specified index
                  return {
                    ...item,
                    StandardRate: null,
                    ActualRate: null,
                    HourlyCost: null,
                  };
                }
                return item;
              }),
            };
          }
        }

				// If the payload or index are not available, return the current state
				// return state;
			})
			// .addCase(getHoursOffShoreOROffShore.fulfilled, (state, action) => {
			// 	if (action.payload && typeof action.payload.index === "number") {
			// 		const index = action.payload?.index;
			// 		const Hours = action.payload?.data;
			// 		console.log(Hours);
			// 		// Update the state if the rate and cost is available
			// 		if (Hours !== undefined) {
			// 			return {
			// 				...state,
			// 				RoleCardDetails: state.RoleCardDetails.map((item, i) => {
			// 					if (i === index) {
			// 						// Update the values for the specified index
			// 						return {
			// 							...item,
			// 							Hours: Hours,
			// 						};
			// 					}
			// 					return item;
			// 				}),
			// 			};
			// 		}
			// 		return state;
			// 	}

			// 	// If the payload or index are not available, return the current state
			// 	// return state;
			// })
			// .addCase(getWavicleCost.fulfilled, (state, action) => {
			// 	if (action.payload && typeof action.payload.index === "number") {
			// 		const index = action.payload?.index;
			// 		const WavicleCost = action.payload?.WavicleCost;
			// 		// console.log(WavicleCost);
			// 		// Update the state if the rate and cost is available
			// 		if (WavicleCost !== undefined) {
			// 			return {
			// 				...state,
			// 				RoleCardDetails: state.RoleCardDetails.map((item, i) => {
			// 					if (i === index) {
			// 						// Update the values for the specified index
			// 						return {
			// 							...item,
			// 							WavicleCost: WavicleCost,
			// 						};
			// 					}
			// 					return item;
			// 				}),
			// 			};
			// 		}
			// 		return state;
			// 	}

			// 	// If the payload or index are not available, return the current state
			// 	return state;
			// })
			.addCase(getWavicleDiscountCost.fulfilled, (state, action) => {
				// console.log("WavicleDiscountCost", action.payload);
				if (action.payload && typeof action.payload.index === "number") {
					const index = action.payload?.index;
					const WavicleDiscountCost = action.payload?.WavicleDiscountCost;
					// console.log(WavicleDiscountCost);
					// Update the state if the rate and cost is available
					if (WavicleDiscountCost !== undefined) {
						return {
							...state,
							RoleCardDetails: state.RoleCardDetails.map((item, i) => {
								if (i === index) {
									// Update the values for the specified index
									return {
										...item,
										WavicleDiscountCost: WavicleDiscountCost,
									};
								}
								return item;
							}),
						};
					}
					return state;
				}

        // If the payload or index are not available, return the current state
        return state;
      })
      .addCase(
        getFixedPriceRevenueAndActualRevenue.fulfilled,
        (state, action) => {
          // console.log("getWavicleCost", action.payload);
          if (action.payload && typeof action.payload.index === "number") {
            const index = action.payload?.index;
            const FixedPriceRevenue = action.payload?.FixedPriceRevenue;
            const ActualRevenue = action.payload?.ActualRevenue;
            // Update the state if the rate and cost is available
            if (
              FixedPriceRevenue !== undefined &&
              ActualRevenue !== undefined
            ) {
              return {
                ...state,
                RoleCardDetails: state.RoleCardDetails.map((item, i) => {
                  if (i === index) {
                    // Update the values for the specified index
                    return {
                      ...item,
                      FixedPriceRevenue: FixedPriceRevenue,
                      ActualRevenue: ActualRevenue,
                    };
                  }
                  return item;
                }),
              };
            }
            return state;
          }

					// If the payload or index are not available, return the current state
					return state;
				}
			)
			.addCase(getFixedPriceRevenueAndActualRevenue.rejected, (state) => {
				// console.log("getFixedPriceRevenueAndActualRevenue rejected...");
			})
			// .addCase(getProposedHours.fulfilled, (state, action) => {
			// 	console.log(action.payload);
			// 	// console.log(action.payload);
			// 	if (action.payload && typeof action.payload.index === "number") {
			// 		const index = action.payload?.index;
			// 		const ProposedHours = action.payload?.ProposedHours;

			// 		// Update the state if the rate and cost is available
			// 		if (ProposedHours !== undefined) {
			// 			return {
			// 				...state,
			// 				RoleCardDetails: state.RoleCardDetails.map((item, i) => {
			// 					if (i === index) {
			// 						// Update the values for the specified index
			// 						return {
			// 							...item,
			// 							ProposedHours: ProposedHours,
			// 						};
			// 					}
			// 					return item;
			// 				}),
			// 			};
			// 		}
			// 		return state;
			// 	}

			// 	// If the payload or index are not available, return the current state
			// 	return state;
			// })
			// .addCase(getProposedHours.rejected, (state) => {
			// 	console.log("getProposedHours rejected...");
			// })
			// .addCase(getClientStandardPrice.fulfilled, (state, action) => {
			// 	// console.log(action.payload);
			// 	if (action.payload && typeof action.payload.index === "number") {
			// 		const index = action.payload?.index;
			// 		const ClientStandardPrice = action.payload?.ClientStandardPrice;

			// 		// Update the state if the rate and cost is available
			// 		if (ClientStandardPrice !== undefined) {
			// 			return {
			// 				...state,
			// 				RoleCardDetails: state.RoleCardDetails.map((item, i) => {
			// 					if (i === index) {
			// 						// Update the values for the specified index
			// 						return {
			// 							...item,
			// 							ClientStandardPrice: ClientStandardPrice,
			// 						};
			// 					}
			// 					return item;
			// 				}),
			// 			};
			// 		}
			// 		return state;
			// 	}

			// 	// If the payload or index are not available, return the current state
			// 	return state;
			// })
			// .addCase(getClientStandardPrice.rejected, (state) => {
			// 	// console.log("getClientStandardPrice rejected...");
			// })
			.addCase(getRevenueAfterDeductions.fulfilled, (state, action) => {
				if (!IsEmptyArray(action.payload)) {
					const updatedRoleCardDetails = state.RoleCardDetails.map(
						(item, i) => {
							const matchingValue = action.payload?.find(
								(val) => val.index === i
							); // Find the corresponding value for the index

              if (matchingValue) {
                // If a matching value is found, update the RevenueAfterDeduction for the current item
                return {
                  ...item,
                  RevenueAfterDeduction: matchingValue.RevenueAfterDeduction,
                };
              } else {
                // If no matching value is found, return the original item
                return item;
              }
            }
          );

          return {
            ...state,
            RoleCardDetails: updatedRoleCardDetails,
          };
        }

				// If the payload or index are not available, return the current state
				return state;
			})
			.addCase(getRevenueAfterDeductions.rejected, (state) => {
				// console.log("getRevenueAfterDeductions rejected...");
			})
			// .addCase(getPLCost.fulfilled, (state, action) => {
			// 	if (!IsEmptyArray(action.payload)) {
			// 		const updatedRoleCardDetails = state.RoleCardDetails.map(
			// 			(item, i) => {
			// 				const matchingValue = action.payload?.find(
			// 					(val) => val.index === i
			// 				); // Find the corresponding value for the index

			// 				if (matchingValue) {
			// 					// If a matching value is found, update the PLCost for the current item
			// 					return {
			// 						...item,
			// 						PLCost: matchingValue.PLCost,
			// 					};
			// 				} else {
			// 					// If no matching value is found, return the original item
			// 					return item;
			// 				}
			// 			}
			// 		);
			// 		// console.log(updatedRoleCardDetails);
			// 		return {
			// 			...state,
			// 			RoleCardDetails: updatedRoleCardDetails,
			// 		};
			// 	}

			// 	// If the payload or index are not available, return the current state
			// 	return state;
			// })
			// .addCase(getPLCost.rejected, (state) => {
			// 	// console.log("getPLCost rejected...");
			// })
			// .addCase(getCLCost.fulfilled, (state, action) => {
			// 	if (!IsEmptyArray(action.payload)) {
			// 		const updatedRoleCardDetails = state.RoleCardDetails.map(
			// 			(item, i) => {
			// 				const matchingValue = action.payload?.find(
			// 					(val) => val.index === i
			// 				); // Find the corresponding value for the index

			// 				if (matchingValue) {
			// 					// If a matching value is found, update the CLCost for the current item
			// 					return {
			// 						...item,
			// 						CLCost: matchingValue.CLCost,
			// 					};
			// 				} else {
			// 					// If no matching value is found, return the original item
			// 					return item;
			// 				}
			// 			}
			// 		);
			// 		// console.log(updatedRoleCardDetails);
			// 		return {
			// 			...state,
			// 			RoleCardDetails: updatedRoleCardDetails,
			// 		};
			// 	}

			// 	// If the payload or index are not available, return the current state
			// 	return state;
			// })
			// .addCase(getCLCost.rejected, (state) => {
			// 	// console.log("getCLCost rejected...");
			// })
			// .addCase(getAirfareAmount.fulfilled, (state, action) => {
			// 	if (action.payload) {
			// 		const AirfareAmount = action.payload?.AirfareAmount;

			// 		// Update the state if the rate and cost is available
			// 		if (AirfareAmount !== undefined) {
			// 			return {
			// 				...state,
			// 				commonInputs: {
			// 					...state.commonInputs,
			// 					Airfare: AirfareAmount,
			// 				},
			// 			};
			// 		}
			// 	}
			// 	// If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 	return state;
			// })
			// .addCase(getAirfareAmount.rejected, (state) => {
			// 	// console.log("getAirfareAmount rejected...");
			// })
			// .addCase(getHotelsAmount.fulfilled, (state, action) => {
			// 	try {
			// 		// console.log("koakdo", action.payload);
			// 		// console.log(state);
			// 		if (action.payload) {
			// 			// console.log("koakdooko", action.payload);
			// 		}
			// 		if (action.payload) {
			// 			const Hotels = action.payload?.Hotels;
			// 			const GroundTransport = action.payload?.GroundTransport;
			// 			const Meals = action.payload?.Meals;
			// 			const Others = action.payload?.Other;
			// 			// console.log(action.payload);
			// 			// Update the state if the rate and cost is available
			// 			if (
			// 				Hotels !== undefined &&
			// 				GroundTransport !== undefined &&
			// 				Meals !== undefined &&
			// 				Others !== undefined
			// 			) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						Hotels: Hotels,
			// 						GroundTransport: GroundTransport,
			// 						Meals: Meals,
			// 						Others: Others,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getHotelsAmount.rejected, (state) => {
			// 	// console.log("getHotelsAmount rejected...");
			// })
			// .addCase(getTotalTravelExpensesAmount.fulfilled, (state, action) => {
			// 	try {
			// 		// console.log("koakdo", action.payload);
			// 		// console.log(state);
			// 		if (action.payload) {
			// 			// console.log("koakdooko", action.payload);
			// 		}
			// 		if (action.payload) {
			// 			const TotalTravelExpensesAmount =
			// 				action.payload?.TotalTravelExpensesAmount;
			// 			// Update the state if the rate and cost is available
			// 			if (TotalTravelExpensesAmount !== undefined) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						TotalTravelExpenses: TotalTravelExpensesAmount,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getTotalTravelExpensesAmount.rejected, (state) => {
			// 	// console.log("getTotalTravelExpensesAmount rejected...");
			// })
			.addCase(getInABoxCost.fulfilled, (state, action) => {
				try {
					console.log(action);
					if (action.payload) {
						const EM2InABoxCost = action.payload?.EM2InABoxCost;
						const DL2InABoxCost = action.payload?.DL2InABoxCost;
						// Update the state if the rate and cost is available
						if (EM2InABoxCost !== undefined && DL2InABoxCost !== undefined) {
							return {
								...state,
								commonInputs: {
									...state.commonInputs,
									InABoxEM: EM2InABoxCost,
									InABoxDL: DL2InABoxCost,
								},
							};
						}
					}
					// // If the payload or index are not available or AirfareAmount is undefined, return the current state
					return state;
				} catch (error) {
					// console.log(error);
				}
			})
			.addCase(getInABoxCost.rejected, (state) => {
				console.log("getInABoxCost rejected...");
			})
			// .addCase(
			// 	getEM2AndDL2InBoxUtilizationPercentage.fulfilled,
			// 	(state, action) => {
			// 		try {
			// 			const {
			// 				EM2InABoxUtilizationPercentage,
			// 				DL2InABoxUtilizationPercentage,
			// 			} = action.payload;
			// 			if (
			// 				EM2InABoxUtilizationPercentage &&
			// 				DL2InABoxUtilizationPercentage
			// 			) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						EM2InABoxUtilizationPercentage:
			// 							EM2InABoxUtilizationPercentage,
			// 						DL2InABoxUtilizationPercentage:
			// 							DL2InABoxUtilizationPercentage,
			// 					},
			// 				};
			// 			}
			// 			// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 			return state;
			// 		} catch (error) {
			// 			// console.log(error);
			// 		}
			// 	}
			// )
			// .addCase(
			// 	getStaffingRatiosAndMarginsPercentage.fulfilled,
			// 	(state, action) => {
			// 		try {
			// 			if (action.payload) {
			// 				const StaffingRatiosAndMarginOnShorePercentage =
			// 					action.payload?.StaffingRatiosAndMarginOnShorePercentage;
			// 				const StaffingRatiosAndMarginOffShorePercentage =
			// 					action.payload?.StaffingRatiosAndMarginOffShorePercentage;
			// 				// Update the state if the rate and cost is available
			// 				if (
			// 					StaffingRatiosAndMarginOnShorePercentage !== undefined &&
			// 					StaffingRatiosAndMarginOffShorePercentage !== undefined
			// 				) {
			// 					return {
			// 						...state,
			// 						commonInputs: {
			// 							...state.commonInputs,
			// 							OnShore: StaffingRatiosAndMarginOnShorePercentage,
			// 							OffShore: StaffingRatiosAndMarginOffShorePercentage,
			// 						},
			// 					};
			// 				}
			// 			}
			// 			// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 			return state;
			// 		} catch (error) {
			// 			// console.log(error);
			// 		}
			// 	}
			// )
			// .addCase(getStaffingRatiosAndMarginsPercentage.rejected, (state) => {
			// 	// console.log("getStaffingRatiosAndMarginsPercentage rejected...");
			// })
			// .addCase(getRevBeforeDeductionsCost.fulfilled, (state, action) => {
			// 	try {
			// 		if (action.payload) {
			// 			const DealRevenueAndGMBeforeDeductionsCost =
			// 				action.payload?.DealRevenueAndGMBeforeDeductionsCost;
			// 			// Update the state if the rate and cost is available
			// 			if (DealRevenueAndGMBeforeDeductionsCost !== undefined) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						RevBefDeductions: DealRevenueAndGMBeforeDeductionsCost,
			// 						TotalFixedPriceRevenue: DealRevenueAndGMBeforeDeductionsCost,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getRevBeforeDeductionsCost.rejected, (state) => {
			// 	// console.log("getRevBeforeDeductionsCost rejected...");
			// })
			// .addCase(getRefAfterDeductionsCost.fulfilled, (state, action) => {
			// 	try {
			// 		// console.log("koakdo", action.payload);
			// 		// console.log(state);
			// 		if (action.payload) {
			// 			console.log("koakdooko", action.payload);
			// 		}
			// 		if (action.payload) {
			// 			const DealRevenueAndGMAfterDeductionsCost =
			// 				action.payload?.DealRevenueAndGMAfterDeductionsCost;
			// 			// Update the state if the rate and cost is available
			// 			if (DealRevenueAndGMAfterDeductionsCost !== undefined) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						RevAftDeductions: DealRevenueAndGMAfterDeductionsCost,
			// 						DealRevenueAndGMAfterDeductionsCost:
			// 							DealRevenueAndGMAfterDeductionsCost,
			// 						TotalRevenueAfterDeductions:
			// 							DealRevenueAndGMAfterDeductionsCost,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getRefAfterDeductionsCost.rejected, (state) => {
			// 	// console.log("getRevAfterDeductionsCost rejected...");
			// })
			// .addCase(getTotalWavicleCost.fulfilled, (state, action) => {
			// 	try {
			// 		if (action.payload) {
			// 			// console.log("koakdooko", action.payload);
			// 		}
			// 		if (action.payload) {
			// 			const TotalWavicleCost = action.payload?.TotalWavicleCost;
			// 			// Update the state if the rate and cost is available
			// 			if (TotalWavicleCost !== undefined) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						TotalWavicleCost: TotalWavicleCost,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getTotalWavicleCost.rejected, (state) => {
			// 	// console.log("getTotalWavicleCost rejected...");
			// })
			// .addCase(getTotalClientPrice.fulfilled, (state, action) => {
			// 	try {
			// 		if (action.payload) {
			// 			// console.log("koakdooko", action.payload);
			// 		}
			// 		if (action.payload) {
			// 			const TotalClientPrice = action.payload?.TotalClientPrice;
			// 			// Update the state if the rate and cost is available
			// 			if (TotalClientPrice !== undefined) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						TotalClientPrice: TotalClientPrice,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getTotalClientPrice.rejected, (state) => {
			// 	// console.log("getTotalClientPrice rejected...");
			// })
			// .addCase(getTotalActualRevenue.fulfilled, (state, action) => {
			// 	try {
			// 		if (action.payload) {
			// 			// console.log("koakdooko", action.payload);
			// 		}
			// 		if (action.payload) {
			// 			const TotalActualRevenue = action.payload?.TotalActualRevenue;
			// 			// Update the state if the rate and cost is available
			// 			if (TotalActualRevenue !== undefined) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						TotalActRevenue: TotalActualRevenue,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getTotalActualRevenue.rejected, (state) => {
			// 	// console.log("getTotalActualRevenue rejected...");
			// })
			// .addCase(getTotalHoursProposed.fulfilled, (state, action) => {
			// 	try {
			// 		// console.log("koakdo", action.payload);
			// 		// console.log(state);
			// 		if (action.payload) {
			// 			// console.log("koakdooko", action.payload);
			// 		}
			// 		if (action.payload) {
			// 			const TotalHoursProposed = action.payload?.TotalHoursProposed;
			// 			// Update the state if the rate and cost is available
			// 			if (TotalHoursProposed !== undefined) {
			// 				return {
			// 					...state,
			// 					commonInputs: {
			// 						...state.commonInputs,
			// 						TotalHoursProposed: TotalHoursProposed,
			// 					},
			// 				};
			// 			}
			// 		}
			// 		// // If the payload or index are not available or AirfareAmount is undefined, return the current state
			// 		return state;
			// 	} catch (error) {
			// 		// console.log(error);
			// 	}
			// })
			// .addCase(getTotalHoursProposed.rejected, (state) => {
			// 	// console.log("getTotalHoursProposed rejected...");
			// })
			.addCase(getDealsfromdrandhs.fulfilled, (state, action) => {
				return {
					...state,
					Deals: action.payload,
				};
			})
			.addCase(getEndDateByDuration.fulfilled, (state, action) => {
				return {
					...state,
					commonInputs: {
						...state.commonInputs,
						ESTEndDate: action.payload,
					}
				}
			})
			.addCase(getDuration.fulfilled, (state, action) => {
				return {
					...state,
					commonInputs: {
						...state.commonInputs,
						Duration: action.payload,
					},
				};
			})
			// .addCase(getfinalGrossMarginCost.fulfilled, (state, action) => {
			// 	return {
			// 		...state,
			// 		commonInputs: {
			// 			...state.commonInputs,
			// 			FinalGrossMargin: action.payload,
			// 		},
			// 	};
			// })
			// .addCase(getFinalGrossMarginPercentage.fulfilled, (state, action) => {
			// 	return {
			// 		...state,
			// 		commonInputs: {
			// 			...state.commonInputs,
			// 			FinalGrossMarginPercentage: action.payload,
			// 		},
			// 	};
			// })
			// .addCase(
			// 	getDealRevenueAndGmAfterDeductionsPercentage.fulfilled,
			// 	(state, action) => {
			// 		console.log(action);
			// 		return {
			// 			...state,
			// 			commonInputs: {
			// 				...state.commonInputs,
			// 				DealRevenueAndGMAfterDeductionsPercentage: action.payload,
			// 			},
			// 		};
			// 	}
			// )
			// .addCase(getRevBeforeDeductionsPercentage.fulfilled, (state, action) => {
			//   return {
			//     ...state,
			//     commonInputs: {
			//       ...state.commonInputs,
			//       FinalGrossMargin: action.payload,
			//     },
			//   };
			// })
			// .addCase(
			// 	getStaffingRatioAndExpectedMarginPercentage.fulfilled,
			// 	(state, action) => {
			// 		return {
			// 			...state,
			// 			commonInputs: {
			// 				...state.commonInputs,
			// 				ExpectedMargin: action.payload,
			// 			},
			// 		};
			// 	}
			// )
			// .addCase(
			// 	getStaffingRatioAndMarginCurrentMarginPercentage.fulfilled,
			// 	(state, action) => {
			// 		return {
			// 			...state,
			// 			commonInputs: {
			// 				...state.commonInputs,
			// 				CurrentMargin: action.payload,
			// 			},
			// 		};
			// 	}
			// )
			// .addCase(getMonthlyPayment.fulfilled, (state, action) => {
			// 	return {
			// 		...state,
			// 		commonInputs: {
			// 			...state.commonInputs,
			// 			MonthlyPayment: action.payload,
			// 		},
			// 	};
			// })
			.addCase(getRoleForStaffing.fulfilled, (state, action) => {
				if (action.payload) {
					console.log(action.payload);
					return {
						...state,
						RoleCardDetails: action.payload.map((item) => ({
							SheetID: item.staff_sheet_id,
							SWORoleName: item.sow_role_name,
							RoleId: item.wavicle_role_id,
							Role: null, // You need to define how you want to populate this field
							Band: item.band,
							Location:
								item?.is_offshore && item?.is_offshore?.trim() === "true"
									? "OffShore"
									: "OnShore",
							Resources: item.no_of_resources,
							ActualRate: item.actual_rate,
							FixedPriceRevenue: item.indv_actual_revenue,
							ProjectUtilization: item.utilization_percent,
							CostUtilization: item.cost_utilization_percent,
							StartDate: new Date(item.start_date).toISOString().split("T")[0],
							EndDate: new Date(item.end_date).toISOString().split("T")[0],
							ProposedHours: item.proposed_hours,
							WavicleCost: item.indv_wavicle_cost,
							ClientStandardPrice: item.indv_client_std_price,
							ActualRevenue: item.indv_actual_revenue,
							Hours: item.hours || 0,
							HourlyCost: item.hourly_cost,
							WavicleDiscountCost: item.indv_discount_cost,
							RevenueAfterDeduction: item.indv_revenue_after_deductions,
							PLCost: item.indv_pl_cost,
							CLCost: item.indv_cl_cost,

              ResourceType: item.resource_type,
              RequiredSkills: item.required_skills || [],
              LaptopNeeded: item.laptop_needed,
              InterviewPanel: item.interview_panel || [],
              RoleRequirements: item.role_requirements,
              HaveClientInterview: item.have_client_interview,
              ClientInterviewInfo: item.client_interview_info,
              ClientManaged: item.client_managed,
            })),
          };
        }
      })
      .addCase(getRoleForStaffing.rejected, (state, action) => {
        return { ...state, RoleCardDetails: [] };
      })
      .addCase(getDRDataForOverviewAndStaffing.fulfilled, (state, action) => {
        if (action.payload) {
          const AP = action.payload[0];
          console.log(action.payload[0]);
          return {
            ...state,
            commonInputs: {
              ...state.commonInputs,
              DealreviewID: AP.deal_review_id,
              ProjectId: AP.project_id_var,
              ESTStartdate: AP.est_start_date
                ? new Date(AP.est_start_date).toISOString().split("T")[0]
                : null,
              ESTEndDate: AP.est_end_date
                ? new Date(AP.est_end_date).toISOString().split("T")[0]
                : null,
              ESTClosedDate: AP.est_close_date
                ? new Date(AP.est_close_date).toISOString().split("T")[0]
                : null,
              CompanyName: AP.company_name,
              ProjectName: AP.project_name,
              Duration: AP.duration_in_weeks,
              PeekHeadcount: AP.peak_headcount,
              OnShore: AP.onsite_staff_percent,
              OffShore: AP.offshore_staff_percent,
              MSACompleted: AP.msa_complete,
              LifeCycle: AP.life_cycle,
              PricingType: AP.pricing_type,
              ServiceCategories: AP.services_included,
              Technology: AP.technology,
              FeeExpenses: AP.fee_expenses,
              RiskAndAssumptions: AP.risk_and_assumptions,
              Notes: AP.notes,
              DealOwner: AP.deal_owner,
              ClientManaged: AP.clientmanaged,
              TotalTravelExpenses: AP.total_travel_expenses,
              DrStatus: AP.dr_status,
              CreatedBy: AP.created_by,
              CreatedAt: AP.created_at,
              LastUpdateBy: AP.last_update_by,
              UpdatedAt: AP.updated_at,
              ParentDealReview_id: AP.parent_deal_review_id,
              DrEffectiveTs: AP.dr_effective_ts,
              DrEndTs: AP.dr_end_ts,
              SolutionsArchOverview: AP.solutions_arch_overview,
              CapabilitiesLeadOverview: AP.capabilities_lead_overview,
              LegalOverview: AP.legal_overview,
              StaffingOverview: AP.staffing_overview,
              ActualStartDate: AP.actual_start_date
                ? new Date(AP.actual_start_date).toISOString().split("T")[0]
                : null,
              ActualEndDate: AP.est_close_date
                ? new Date(AP.actual_end_date).toISOString().split("T")[0]
                : null,
              TotalFixedPrice: AP.total_fixed_price,
              TotalDiscount: AP.total_discount,
              DiscountInvestment: AP.wavicle_discount ? "%" : "$",
              TotalClCost: AP.total_cl_cost,
              TotalPlCost: AP.total_pl_cost,

              CurrentMargin: AP.current_margin,
              ExpectedMargin: AP.expected_margin,
              ExpensePercentage: AP.travel_percentage,
              PLUtilization: AP.prac_lead_utilization,
              CLUtilization: AP.comp_lead_utilization,
              MonthlyPayment: AP.monthly_payment || 0,
              InABoxEM: AP["2-in-a-box-em"],
              InABoxDL: AP["2-in-a-box-dl"],
              WavicleDiscount: AP.wavicle_discount,
              RevBefDeductions: AP.revenue_before_deductions,
              RevAftDeductions: AP.revenue_after_deductions,
              FinalGrossMargin: AP.final_gross_revenue,
              TotalWavicleCost: AP.total_wavicle_cost,
              TotalClientPrice: AP.total_client_price,
              TotalActRevenue: AP.total_revenue,
              TotalRevenueAfterDeductions: AP.total_revenue_after_deductions,
              TotalFixedPriceRevenue: AP.total_revenue,
              TotalHoursProposed: AP.total_hours_proposed,

              Expenes: AP.total_deal_expenses,
              Airfare: AP.airfare,
              Hotels: AP.hotel_stay_cost,
              GroundTransport: AP.ground_transport,
              Meals: AP.meal_expense,
              Others: AP.other_expense,
              TotalTravelExpenses: AP.total_travel_expenses,
			  Miscellaneous : AP.miscellaneous,
			  
              DealRevenueAndGMBeforeDeductionsPercentage: null,
              DealRevenueAndGMAfterDeductionsPercentage: null,
              FinalGrossMarginPercentage: null,
              StaffingRatiosAndMarginOnshorePercentage: null,
              StaffingRatiosAndMarginOffshorePercentage: null,
              EM2InABoxUtilizationPercentage: null,
              DL2InABoxUtilizationPercentage: null,
            },
          };
        }
      })
      .addCase(getDRDataForOverviewAndStaffing.rejected, (state, action) => {
        return {
          ...state,
          commonInputs: {
            DealreviewID: null,
            ProjectId: null,
            ESTStartdate: null,
            ActualStartDate: null,
            ActualEndDate: null,
            ESTEndDate: null,
            ESTClosedDate: null,
            CompanyName: null,
            ProjectName: null,
            Duration: null,
            PeekHeadcount: null,
            OnShore: null,
            OffShore: null,
            MSACompleted: "Yes",
            LifeCycle: [],
            PricingType: null,
            ServiceCategories: [],
            Technology: [],
            FeeExpenses: null,
            RiskAndAssumptions: null,
            Notes: null,

            DrStatus: null,
            CreatedBy: "J7Dev",
            CreatedAt: null,
            LastUpdateBy: "J7Dev",
            UpdatedAt: null,
            ParentDealReviewId: null,
            DrEffectiveTs: null,
            DrEndTs: null,
            SolutionsArchOverview: null,
            CapabilitiesLeadOverview: null,
            LegalOverview: null,
            StaffingOverview: null,

            TotalFixedPrice: null,
            TotalDiscount: null,
            TotalClCost: null,
            TotalPlCost: null,

            WavicleDiscount: null,
            ExpensePercentage: null,
            MonthlyPayment: null,
            PLUtilization: null,
            CLUtilization: null,
            InABoxEM: null,
            InABoxDL: null,
            RevBefDeductions: null,
            RevAftDeductions: null,
            FinalGrossMargin: null,
            TotalWavicleCost: null,
            TotalClientPrice: null,
            TotalActRevenue: null,
            TotalRevenueAfterDeductions: null,
            TotalFixedPriceRevenue: null,
            TotalHoursProposed: null,

            Expenes: null,
            Airfare: null,
            Hotels: null,
            GroundTransport: null,
            Meals: null,
            Others: null,
            TotalTravelExpenses: null,

            DealRevenueAndGMBeforeDeductionsPercentage: null,
            DealRevenueAndGMAfterDeductionsPercentage: null,
            FinalGrossMarginPercentage: null,
            StaffingRatiosAndMarginOnshorePercentage: null,
            StaffingRatiosAndMarginOffshorePercentage: null,
            EM2InABoxUtilizationPercentage: null,
            DL2InABoxUtilizationPercentage: null,
          },
        };
      })
      .addCase(updateStaffingAndOverview.fulfilled, (state, action) => {
        const { staffing, overview } = action.payload;

        if (Array.isArray(staffing)) {
          const updatedRoleCardDetails = state.RoleCardDetails?.map(
            (item, index) => {
              const matchingIndex = staffing.find(
                (entry) => entry.cardIndex === index
              );
              if (matchingIndex && matchingIndex.save_staffing_sheet_id_data) {
                // Update SheetID for the matching cardIndex
                return {
                  ...item,
                  SheetID: matchingIndex.save_staffing_sheet_id_data,
                };
              }
              return item;
            }
          );
          return {
            ...state,
            commonInputs: {
              ...state.commonInputs,
              DealreviewID: overview.insert_dr_deal_review_master,
            },
            RoleCardDetails: updatedRoleCardDetails,
            isSavedDataIntoDB: false,
            ShowSnackbar: {
              isopen: true,
              message: siteConfig["savedeal.success"],
              type: "success",
            },
            SaveButtonLoading: false,
          };
        }
      })
      .addCase(updateStaffingAndOverview.rejected, (state, action) => {
        return {
          ...state,
          ShowSnackbar: {
            isopen: true,
            message: action.payload.error,
            type: "error",
          },
          SaveButtonLoading: false,
          isRequiredColumnFilled: false,
        };
      })
      .addCase(getDrFilterDetailsFromDB.fulfilled, (state, action) => {
        return { ...state, FilterScreenData: action.payload };
      })
      .addCase(getNewDRDetailsFromDB.fulfilled, (state, action) => {
        if (action.payload) {
          return { ...state, NewDRSrceenData: action.payload };
        }
      })
      .addCase(deleteRoleCardDB.fulfilled, (state, action) => {
        const clickedIndex = action.payload;
        console.log(clickedIndex, "deleteRoleCardDB fulfilled");
        const RoleDetailsLength = state.RoleCardDetails.length === 1;

        if (RoleDetailsLength) {
          state.RoleCardDetails = [];
        } else {
          const RoleCardDetails = state.RoleCardDetails.filter(
            (role, index) => index !== clickedIndex
          );
          console.log(RoleCardDetails);
          state.RoleCardDetails = RoleCardDetails;
        }
        state.isModalOpen = { IsOpen: false, ClickedIndex: 0 };
      })
      .addCase(deleteRoleCardDB.rejected, (state, action) => {
        // const { clickedIndex } = action.payload;
        // const RoleDetailsLength = state.RoleCardDetails.length === 1;
        // if (RoleDetailsLength) {
        // 	state.RoleCardDetails = [];
        // } else {
        // 	state.RoleCardDetails = state.RoleCardDetails.filter(
        // 		(role, index) => index !== clickedIndex
        // 	);
        // }
        // state.isModalOpen = { IsOpen: false, ClickedIndex: 0 };
      })
      .addCase(updatedStatus.fulfilled, (state, action) => {
        return {
          ...state,
          commonInputs: {
            ...state.commonInputs,
            DrStatus: action.payload.status,
          },
          ShowSnackbar: {
            isopen: true,
            message: action.payload.message,
            type: "success",
          },
        };
      })
      .addCase(updateNewDR.fulfilled, (state, action) => {
        // console.log(action.payload);
      })
      .addCase(submitLegal.fulfilled, (state, action) => {
        console.log(action);

        return {
          ...state,
          ShowSnackbar: {
            isopen: true,
            message: siteConfig["savelegal.success"],
            type: "success",
          },
        };
      });
  },
});

export const {
	ChangeIsRequiredColumnFilledState,
	commonInputOnChange,
	addRoleCard,
	deleteRoleCard,
	updateRoleCard,
	openModal,
	closeModal,
	updateRoleCardProperty,
	setChangeData,
	setSavedData,
	addCommonInputValuesFromDatabase,
	setSaveButtonLoadingFalse,
	setSaveButtonLoadingTrue,
	ShowSnackbar,
	HideSnackbar,
	OnChangeNewDRSrceenData,
	updateStaffingIntakeProperty,
	visibleSnackBar,
	caculateTotalWavicleInvestmentCost,
	caculateTotalPLCost,
	caculateTotalCLCost,
	setCommonInputs,
} = Deals.actions;

export default Deals.reducer;
