import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Button } from "antd/es/radio";
import React, { useEffect, useState } from "react";
import { CustomButton } from "../Buttons/PopoverButton";
import { isEqual } from "lodash-es";
import { InnerButton } from "../Buttons/FloatingButtons";

function TableFilterModalChild({
  title = "No Title",
  FilterDatas = [],
  setFilterDatas = () => {},
}) {
  const [check, setCheck] = useState(FilterDatas || []);
  const handleCheckboxChange = (event, key) => {
    const label = event.target.name;
    const checked = event.target.checked;

    setCheck((prev) => {
      // Create a new array with the updated values
      const updatedFilterDatas = prev.map((section) => {
        if (section.key === key) {
          // For the section with the matching key, update the checkBoxs array
          return {
            ...section,
            checkBoxs: section.checkBoxs.map((checkBox) => {
              if (checkBox.label == label) {
                // For the checkBox with the matching label, update the checked property
                return {
                  ...checkBox,
                  checked: checked,
                };
              }
              return checkBox; // Return unchanged checkBox if label doesn't match
            }),
          };
        }
        return section; // Return unchanged section if key doesn't match
      });

      return updatedFilterDatas; // Return the new array
    });
  };

  const handleSave = () => {
    setFilterDatas(check);
  };
  const areArraysEqual = (arr1, arr2) => {
    return isEqual(arr1, arr2);
  };

  const isDisabled = areArraysEqual(FilterDatas, check);
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        spacing={5}
        alignContent={"center"}
        position={"sticky"}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h2"
          fontWeight={400}
          paddingBottom="16px"
          lineHeight="24px"
        >
          {title}
        </Typography>

        <InnerButton id='apply-changes-button' disabled={isDisabled} onClick={handleSave} 
                  sx={{
                      backgroundColor: "rgba(28, 28, 28, 0.05)",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "rgba(87, 118, 231, 1)", // Change to desired hover background color
                        color: "white", // Change to desired hover text color
                      },
                    }}
                  >Apply Changes</InnerButton>
      </Stack>
      <hr />
      {check?.map((value, index) => {
        return (
          <>
            <Typography
              id="modal-modal-description"
              variant="h5"
              color="text.secondary"
              key={index}
              sx={{ mt: 2 }}
            >
              {value.subtitle}
            </Typography>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {value.checkBoxs.map((checkBox, index) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "rgba(87, 118, 231, 1)",
                          },
                        }}
                        checked={checkBox.checked}
                        name={checkBox.label}
                        indeterminate={false}
                        onChange={(e) => handleCheckboxChange(e, value.key)}
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="h6">{checkBox.label}</Typography>
                    }
                    key={index}
                  />
                </>
              ))}
            </FormGroup>
          </>
        );
      })}
    </>
  );
}

export default TableFilterModalChild;
