import moment from "moment";
import useIntl from "../../common/lang/locale/en-US";
// This function convert the UTC timestamp to date format
//input date format -> "2024-02-13T00:00:00.000Z"
//output date format -> "2024-02-13"
export const convertDateFormat = (inputDate, format) => {
  try {
    if (inputDate === undefined || inputDate === null) {
      return "NA";
    }
    const dateFormat = format || useIntl["date.format"];
    // date converted into formatted using moment
    const formattedDate = moment(inputDate).format(dateFormat);
    return formattedDate;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "NA";
  }
};
