import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material";

// Custom Backdrop with blur effect
export const BlurredBackdrop = styled(Backdrop)(({ theme }) => ({
  backdropFilter: "blur(10px)",
  background:
    "linear-gradient(180deg, rgba(215, 208, 255, 0.2) 0%, rgba(203, 221, 255, 0.5) 100%)",
}));

// Custom Header with blur background
export const BlurredHeader = styled("div")(({ theme }) => ({
  // padding: theme.spacing(2),
  // background:'transparent'
}));

const ModalPopUp = ({ open, setOpen, header=<></>, children, isPreliminary = false, width='50%' , overflowY='auto',height="90%",modalOverFlowY=false, style, disableBackdropClick=false }) => {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={(event, reason) =>{
          if (disableBackdropClick && reason === "backdropClick") return;
          handleClose()
        }}
        closeAfterTransition
        slots={{
          backdrop: BlurredBackdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{
          '::-webkit-scrollbar': {
      width: '0px', // Remove scrollbar for WebKit browsers
    },
    '-ms-overflow-style': 'none', // Remove scrollbar for IE and Edge
    'scrollbar-width': 'none', // Remove scrollbar for Firefox
        }}
      >
        {/* <Fade in={open}> */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isPreliminary ? "min(90%,1200px)" : width,
            height: isPreliminary ? "unset" : height,
            maxHeight: isPreliminary ? "80%" : "unset",
            background:"transparent",
            borderRadius: 4,
            border: "none",
            overflowY: modalOverFlowY ? "visble" : "hidden" ,
            "&:focus": {
              outline: "none",
            },
            ...style
          }}
        >
          <BlurredHeader>
            {header}
          </BlurredHeader>
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 4,
              borderRadius: 4,
              p: 4,
              width:'100%',
              height:'100%',
              ...(overflowY && { overflowY: overflowY })
            }}
          >
            {children}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalPopUp;
