const RequiredFields = [
	"SWORoleName",
	"RoleId",
	"Band",
	"Location",
	"ActualRate",
	"ProjectUtilization",
	"CostUtilization",
	"Resources",
	"StartDate",
	"EndDate",
	"ProposedHours",
	"ClientStandardPrice",
	"ActualRevenue",
	"RevenueAfterDeduction",
];

const FilledRoles = (RoleCard = [], requiredFields = RequiredFields) => {
	const filledCards = RoleCard.filter((card) => {
		return requiredFields.every((field) => card[field]);
	});
	return filledCards;
};

export const IsAllCardsFilled = (
	RoleCard = [],
	requiredFields = RequiredFields
) => {
	const filledCards = RoleCard.filter((card) => {
		return requiredFields.every((field) => card[field] !== null);
	});
	const allCardsFilled = filledCards.length === RoleCard.length;
	return allCardsFilled;
};

export default FilledRoles;
