/**
 * Formats a value as either a dollar amount or a percentage.
 *
 * @param {number} value - The value to be formatted.
 * @param {string} [withDollarOrPercent="$"] - The format specifier for the output.
 * @return {string} The formatted value.
 */
export function formatValue(value, withDollarOrPercent = "$") {
  let formattedValue;
  
  if (withDollarOrPercent === "$") {
    const dollarSign = withDollarOrPercent ? "$ " : "";
    formattedValue = dollarSign + Math.round(Number(value)).toLocaleString();
  } else {
    formattedValue = `${Math.round(value)} %`;
  }
  
  return formattedValue;
}