// Global Styles
import { AppBar, Box, Stack, styled, Typography, useTheme } from "@mui/material";
import { PermittedItems } from "./utils/RbacUtils";
import { useSelector } from "react-redux";

export const PageWrapper = styled("div")({
	padding: "0 25px 0px 25px",
	display: "flex",
	overflowY: "auto",
	flexDirection: "column",
	alignItems: "flex-start",
	gap: "12px",
	flex: 1,
});

export const NoRecordsText = styled(Typography)(({ theme }) => ({
	fontSize: "14px",
	color: '#999999',
	textAlign: "center",
	fontStyle: 'normal'
}));

export const TableWrapper = styled("div")(({ theme }) => ({
    "& .chipContainer": {
      display: "flex",
      flexWrap: "wrap",
      gap: 5,
      maxWidth: "100%", // Ensure chips don't overflow the container
      overflow: "hidden",
    },
    "& .MuiChip-root": {
      height: "22px",
    },
    "& .subChipText": {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "18px",
    },
    "& .MuiBox-root": {
      boxShadow: "none",
    },
    "& .MuiIconButton-root": {
      padding: "0px",
      fontSize: "1.25rem",
      color: "#999999",
    },
	"& .MuiTableRow-root": {
		boxShadow: "none",
	},
    "& .MuiTableRow-root:hover td:after": {
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiInputBase-input": {
      fontSize: "13px",
      fontWeight: 400,
	  padding: '4px 10px !important',
    },
    "& .MuiCollapse-root": {
      width: "100%",
    },
    "& .Mui-TableBodyCell-DetailPanel": {
      padding: "1px",
    },
  }));

export const ContentWrapper = styled("div")({
	display: "flex",
	padding: "24px 0px 64px 0px",
	flexDirection: "column",
	alignItems: "flex-start",
	gap: "12px",
	alignSelf: "stretch",
});

export const CardWrapper = styled("div")(({ theme }) => ({
	width: "100%",
	position: "relative",
	display: "flex",
	flexDirection: "row",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	boxSizing: "border-box",
	gap: "var(--gap-xs)",
	textAlign: "left",
	color: theme.palette.text.main,
	fontFamily: "var(--default-font)",
	flexWrap: "wrap",
}));

export const TopNavWrapper = styled(AppBar)(({ theme }) => ({
	width: "100%",
	display: "flex",
	justifyContent: "space-between",
	backgroundColor: theme.palette.background.primary,
	boxShadow: "none",
	paddingTop: "1rem",
	flexDirection: "column",
	zIndex: 9,
	top: 0,
	position: "sticky",
	padding: "24px 34px",
	backgroundImage: "none",
	paddingBlock: 20,
	borderBottom: `0.2px solid ${theme.palette.borderColor}`,
}));

export const StyledTitle = ({id,...props}) => {
	const { sx, ...others } = props;
	const theme = useTheme();
	const permission = useSelector((state) => state?.permission);
	const hasAnyPermittedCard = PermittedItems({ data: [{ id : id }], component: "card", dataKey: "id", permission});
	return (
		hasAnyPermittedCard.length > 0 &&
		<Box
			sx={{
				backgroundColor: theme.palette.background.secondary,
				paddingBlock: theme.spacing(1.25),
				paddingInline: theme.spacing(3),
				borderRadius: theme.spacing(1.5),
				width: "100%",
				marginBottom: '10px',
				...sx
			}}
			{...others}
		/>
	)
}

export const ContainerWrapper = ({ id, isGap = true, sx, ...props }) => {
	const theme = useTheme();
	const permission = useSelector((state) => state?.permission)
	const hasAnyPermittedCard = PermittedItems({ data: [{ id: id }], component: "card", dataKey: "id", permission });
	console.log(hasAnyPermittedCard);
	
	return (
		hasAnyPermittedCard.length > 0 &&
		<Stack
			sx={{
				backgroundColor: theme.palette.background.secondary,
				padding: "24px 24px 16px 24px",
				borderRadius: "16px",
				gap: isGap ? "20px" : 0,
				...sx,
			}}
			{...props}
		/>
	);
};
export const BackgroundWrapper = ({id,...props}) => {
	const { sx, ...others } = props;
	const theme = useTheme();
	const permission = useSelector((state) => state?.permission);
	const hasAnyPermittedCard = PermittedItems({ data: [{ id : id }], component: "card", dataKey: "id", permission});
	return (
		hasAnyPermittedCard.length > 0 &&
		<Box 
		sx={{
			backgroundColor: theme.palette.background.secondary,
			...sx
		}}
		{...others}
		/>
	)
}
export const WhiteBackgroundTitledInput =({id,...props}) => {
	const { sx,...others } = props
	const theme = useTheme();
	const permission = useSelector((state) => state?.permission);
	const hasAnyPermittedCard = PermittedItems({ data: [{ id: id }], component: "card", dataKey: "id", permission });
	return (
		hasAnyPermittedCard.length > 0 &&
		<Box
			sx={{
				backgroundColor: theme.palette.background.primary,
				paddingBlock: '16px',
				paddingInline: '20px',
				borderRadius: '16px',
				gap: theme.spacing(1),
				flexGrow: 1,
				...sx
			}}
			{...others}
		/>
	);
};
